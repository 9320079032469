import { ErrorOption, FieldName } from "react-hook-form";

function setFormErrors<T>(setError: (name: FieldName<T>, error: ErrorOption) => void, errors: object, prefix: string) {
  const prefixWithDot = prefix.length > 0 ? `${prefix}.` : '';
  Object.entries(errors).forEach(([key, value]) => {
    if (typeof value === 'string') {
      setError(`${prefixWithDot}${key}` as FieldName<T>, { type: 'manual', message: value, shouldFocus: true });
    } else if (typeof value === 'object') {
      setFormErrors(setError, value, `${prefixWithDot}${key}`);
    }
  })
}

export default setFormErrors;
