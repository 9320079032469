type Ferro = {
  id?: number,
  bitola?: number,
  kgPorMetro: number,
  precoUnitario: string,
};

export type FerroExibicao = {
  id: number,
  bitola: number,
  kgPorMetro: number,
  precoUnitario: string,
};

export type FerroComposicao = {
  id: number,
  quantidade: number,
  comprimento: number,
  bitola?: number,
};

export const ferroFormToModel = (ferro: Ferro): Ferro => {
  return {
    ...ferro,
    bitola: Number(ferro.bitola),
    kgPorMetro: Number(ferro.kgPorMetro),
  };
}

export default Ferro;
