import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import NotFoundPage from '../NotFoundPage';
import ProjetoPage from './single/ProjetoPage';
import ProjetosPage from './ProjetosPage';
import { OrcamentosPage } from './single/orcamentos';

export const ProjetosRouter = (_props: any) => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={`${path}/:idProjeto/orcamentos`} component={OrcamentosPage} />
      <Route exact path={`${path}/:id`} component={ProjetoPage} />
      <Route exact path={path} component={ProjetosPage} />
      <Route path="*" component={NotFoundPage}/>
    </Switch>
  );
}
