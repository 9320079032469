import React from 'react';
import { useHistory } from 'react-router-dom';
import { Table } from 'rsuite';
import ActionCell from '../../components/ActionCell';
import BaseTabela from '../../components/BaseTabela';
import { AdicaoModalFunction, BaseTabelaProps, RemocaoModalFunction } from '../../components/BaseTabela/BaseTabela';
import DateCell from '../../components/DateCell';
import MoneyCell from '../../components/MoneyCell';
import PageLayout from '../../components/PageLayout';
import RemocaoModal from '../../components/RemocaoModal';
import { AppContextProps } from '../../context';
import { OrcamentoRepository } from '../../repositories/interfaces/orcamento-repository';
import { OrcamentoExibicao } from '../../types/orcamento';
import { Pagina } from '../../types/pagina';
import RequisicaoPagina from '../../types/requisicao-pagina';

class TabelaOrcamentos extends BaseTabela<OrcamentoExibicao> {
  private orcamentoRepository: OrcamentoRepository;

  constructor(props: BaseTabelaProps, context: AppContextProps) {
    super(props);
    this.orcamentoRepository = context.repositoryFactory.getOrcamentoRepository(context);
  }

  buscarParaExibicao(requisicao: RequisicaoPagina) : Promise<Pagina<OrcamentoExibicao>> {
    return this.orcamentoRepository.buscarParaExibicao(requisicao);
  }

  exibirAdicaoModal: AdicaoModalFunction = () => null;

  exibirRemocaoModal: RemocaoModalFunction = (show, selecionados, onClose) => (
    <RemocaoModal
      show={show}
      selecionados={selecionados}
      onClose={onClose}
      deleteMany={this.orcamentoRepository.deleteMany}
      nome={["orçamento", "orçamentos"]}
    />
  );
}

const SimNaoCell = ({ rowData, dataKey, ...props }: any) => {
  let display;
  if (rowData[dataKey] === 'S') {
    display = 'Sim';
  } else if (rowData[dataKey] === 'N') {
    display = 'Não';
  } else {
    display = rowData[dataKey];
  }

  return (
    <Table.Cell {...props}>
      {display}
    </Table.Cell>
  );
}

const OrcamentosPage = () => {
  const history = useHistory();

  return (
    <PageLayout title="Orçamentos">
      <TabelaOrcamentos
        mostrarBotaoAdicao={false}
        ordenarPorPadrao="dataCadastro"
        tipoOrdenacaoPadrao="desc"
        opcoesFiltro={[{ value: 'nomeCliente', label: 'Cliente' }]}
      >
        <Table.Column width={50} sortable>
          <Table.HeaderCell>#</Table.HeaderCell>
          <Table.Cell dataKey="id" />
        </Table.Column>
        <Table.Column flexGrow={1} minWidth={200} sortable>
          <Table.HeaderCell>Cliente</Table.HeaderCell>
          <Table.Cell dataKey="nomeCliente" />
        </Table.Column>
        <Table.Column>
          <Table.HeaderCell>Venda</Table.HeaderCell>
          <SimNaoCell dataKey="venda" />
        </Table.Column>
        <Table.Column sortable>
          <Table.HeaderCell>Data</Table.HeaderCell>
          <DateCell dataKey="dataCadastro" />
        </Table.Column>
        <Table.Column sortable>
          <Table.HeaderCell>Valor Final</Table.HeaderCell>
          <MoneyCell dataKey="valorFinal" />
        </Table.Column>
        <Table.Column width={60}>
          <Table.HeaderCell>Ver</Table.HeaderCell>
          <ActionCell icon="eye" dataKey="id" onClick={(id: number) => history.push(`/orcamentos/${id}`)} />
        </Table.Column>
      </TabelaOrcamentos>
    </PageLayout>
  );
}

export default OrcamentosPage;
