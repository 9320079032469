import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from 'rsuite';
import AdicaoModal from '../../../components/AdicaoModal';
import { AppContext } from '../../../context';
import { orcamentoFormToModel } from '../../../types/orcamento';
import GeracaoOrcamentoForm from './GeracaoOrcamentoForm';

const GeracaoOrcamentoButton = ({ idProjeto }: { idProjeto: number }) => {
  const [show, setShow] = useState(false);
  const context = useContext(AppContext);
  const repository = context.repositoryFactory.getOrcamentoRepository(context);
  const history = useHistory();

  const handleClose = (response: boolean | number = false) => {
    setShow(false);
    if (typeof response === 'number') {
      history.push(`/orcamentos/${response}`);
    }
  }

  return (
    <>
      <Button appearance="subtle" onClick={() => setShow(true)}>Gerar Orçamento</Button>
      <AdicaoModal
        nome="orçamento"
        show={show}
        onClose={handleClose}
        element={GeracaoOrcamentoForm}
        create={repository.create}
        formToModel={orcamentoFormToModel}
        formOptions={{ defaultValues: { idProjeto } }}
      />
    </>
  );
}

export default GeracaoOrcamentoButton;
