import Axios from "axios";
import Usuario from "../../types/usuario";
import { UsuarioRepository } from "../interfaces/usuario-repository";

class ApiUsuarioRepository implements UsuarioRepository {
  async findAll(): Promise<Usuario[]> {
    const resposta = await Axios.get('/usuarios');

    if (resposta.status === 200) {
      return resposta.data;
    } else {
      return [];
    }
  }

  async create(usuario: Usuario): Promise<string | true | object> {
    const resposta = await Axios.post('/usuarios', usuario);

    if (resposta.status === 200) {
      return true;
    } else if (resposta.status === 400) {
      return resposta.data;
    } else if (resposta.status === 401) {
      return 'Não autorizado';
    } else if (resposta.status === 500) {
      return resposta.data.message;
    }

    return 'Erro inesperado';
  }

  async read(username: string): Promise<Usuario | null> {
    const resposta = await Axios.get(`/usuarios/${username}`);

    if (resposta.status === 200) {
      return resposta.data;
    }

    return null;
  }

  async update(usuario: Usuario): Promise<string | true | object> {
    const resposta = await Axios.put('/usuarios', usuario);

    if (resposta.status === 200) {
      return true;
    } else if (resposta.status === 400) {
      return resposta.data;
    } else if (resposta.status === 401) {
      return 'Não autorizado';
    } else if (resposta.status === 500) {
      return resposta.data.message;
    }

    return 'Erro inesperado';
  }

  async delete(username: string): Promise<boolean> {
    const resposta = await Axios.delete(`/usuarios/${username}`);

    if (resposta.status === 200) {
      return true;
    }

    return false;
  }
}

export default ApiUsuarioRepository;
