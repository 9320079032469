import React, { useContext, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Alert, Button, ControlLabel, DatePicker, Form, FormControl, FormGroup, Input, Modal, Radio, RadioGroup } from 'rsuite';
import UncontrolledFormInput from '../../../components/UncontrolledFormInput';
import { AppContext } from '../../../context';
import abrirPDF from '../../../utils/abrirPDF';

const todayPlusDays = (days: number): Date => {
  const date = new Date();
  date.setDate(date.getDate() + days);
  return date;
}

type Form = {
  tipo: "R" | "P",
  data?: Date,
  informacoes?: string,
};

const GerarPDFButton = ({ idProjeto }: { idProjeto: number }) => {
  const [showModal, setShowModal] = useState(false);
  const [gerando, setGerando] = useState(false);
  const context = useContext(AppContext);
  const repository = context.repositoryFactory.getProjetoRepository(context);

  const { control, register, errors, handleSubmit, watch } = useForm<Form>();
  const watchTipo = watch('tipo', 'P');

  const handleHide = () => setShowModal(false);

  const onSubmit = async (values: Form) => {
    const targetWindow = window.open();

    setGerando(true);

    try {
      const resposta = await repository.gerarPDF(idProjeto, values.tipo, values.data?.toLocaleDateString('pt-br'), values.informacoes);
      abrirPDF(resposta, targetWindow ? targetWindow : undefined);
    } catch (e) {
      Alert.error(e.message, 5000);
      targetWindow?.close();
    }

    setShowModal(false);
    setGerando(false);
  }

  return (
    <>
      <Button
        appearance="subtle"
        loading={gerando}
        onClick={() => setShowModal(true)}
      >Gerar PDF</Button>
      <Modal size="xs" show={showModal} backdrop={true} onHide={handleHide} className="modal-fluid-xs">
        <Form onSubmit={(_c, v) => handleSubmit(onSubmit)(v)}>
          <Modal.Header>
            <Modal.Title>Gerar PDF do projeto</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <FormGroup>
              <ControlLabel>Tipo</ControlLabel>
              <Controller
                defaultValue="P"
                control={control}
                name="tipo"
                rules={{ required: 'Este campo é necessário' }}
                render={({ onChange, value, name }) => (
                  <RadioGroup onChange={onChange} value={value} name={name} inline>
                    <Radio value="P">Produção</Radio>
                    <Radio value="R">Romaneio</Radio>
                  </RadioGroup>
                )}
              />
            </FormGroup>
            {watchTipo === "P" && (
              <FormGroup>
                <ControlLabel>Data de previsão</ControlLabel>
                <Controller
                  name="data"
                  control={control}
                  defaultValue={null}
                  rules={{ required: 'Este campo é necessário' }}
                  render={({ name, onChange, value }) => (
                    <FormControl
                      name={name}
                      accepter={DatePicker}
                      oneTap
                      format="DD/MM/YYYY"
                      placeholder="dd/mm/aaaa"
                      ranges={[
                        {
                          label: 'Hoje',
                          value: new Date(),
                        },
                        {
                          label: 'Daqui 15 dias',
                          value: todayPlusDays(15),
                        },
                        {
                          label: 'Daqui 30 dias',
                          value: todayPlusDays(30),
                        },
                      ]}
                      onChange={onChange}
                      value={value}
                      errorMessage={errors.data?.message}
                    />
                  )}
                />
              </FormGroup>
            )}
            {watchTipo === "R" && (
              <FormGroup>
                <ControlLabel>Informações</ControlLabel>
                <UncontrolledFormInput errorMessage={errors.informacoes?.message}>
                  <Input
                    name="informacoes"
                    componentClass="textarea"
                    rows={3}
                    style={{ minWidth: '260px', resize: 'vertical' }}
                    placeholder="ex: endereço de entrega, etc."
                    inputRef={register}
                  />
                </UncontrolledFormInput>
              </FormGroup>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button appearance="primary" type="submit" loading={gerando}>Confirmar</Button>
            <Button appearance="subtle" onClick={handleHide}>Cancelar</Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
}

export default GerarPDFButton;
