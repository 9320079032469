import React from 'react';
import { Table } from 'rsuite';
import { formatCnpj, formatCpf } from '../utils/formatters';

const CpfCnpjCell = ({ rowData, dataKey, typeKey, ...props }: any) => (
  <Table.Cell {...props}>
    {rowData[dataKey] && (rowData[typeKey] === 'pessoa' ? formatCpf(rowData[dataKey]) : formatCnpj(rowData[dataKey])) }
  </Table.Cell>
);

export default CpfCnpjCell;
