import React, { useContext, useRef, useState } from 'react';
import { Table } from 'rsuite';
import AdicaoModal from '../../../components/AdicaoModal';
import BaseTabela from '../../../components/BaseTabela';
import { AdicaoModalFunction, BaseTabelaProps, RemocaoModalFunction } from '../../../components/BaseTabela/BaseTabela';
import EdicaoDrawer from '../../../components/EdicaoDrawer';
import MoneyCell from '../../../components/MoneyCell';
import NumberCell from '../../../components/NumberCell';
import PageLayout from '../../../components/PageLayout';
import RemocaoModal from '../../../components/RemocaoModal';
import { AppContext, AppContextProps } from '../../../context';
import { ArmacaoRepository } from '../../../repositories/interfaces/armacao-repository';
import { ArmacaoExibicao } from '../../../types/armacao';
import { Pagina } from '../../../types/pagina';
import RequisicaoPagina from '../../../types/requisicao-pagina';
import EdicaoArmacaoForm from './EdicaoArmacaoForm';
import InsercaoArmacaoForm from './InsercaoArmacaoForm';

class TabelaArmacoes extends BaseTabela<ArmacaoExibicao> {
  private armacaoRepository: ArmacaoRepository;

  constructor(props: BaseTabelaProps, context: AppContextProps) {
    super(props);
    this.armacaoRepository = context.repositoryFactory.getArmacaoRepository(context);
  }

  buscarParaExibicao(requisicao: RequisicaoPagina) : Promise<Pagina<ArmacaoExibicao>> {
    return this.armacaoRepository.buscarParaExibicao(requisicao);
  }

  exibirAdicaoModal: AdicaoModalFunction = (show, onClose) => (
    <AdicaoModal
      stringNovo="Nova"
      nome="armação"
      show={show}
      onClose={onClose}
      element={InsercaoArmacaoForm}
      create={this.armacaoRepository.create}
      formToModel={f => f}
    />
  );

  exibirRemocaoModal: RemocaoModalFunction = (show, selecionados, onClose) => (
    <RemocaoModal
      show={show}
      selecionados={selecionados}
      onClose={onClose}
      deleteMany={this.armacaoRepository.deleteMany}
      nome={["armação", "armações"]}
    />
  );
}

const ArmacoesPage = () => {
  const [idEdicao, setIdEdicao] = useState<number | undefined>(undefined);
  const [editando, setEditando] = useState(false);

  const context = useContext(AppContext);
  const repository = context.repositoryFactory.getArmacaoRepository(context);

  const ref = useRef<TabelaArmacoes>(null);

  return (
    <PageLayout title="Materiais - Armações">
      <TabelaArmacoes
        ref={ref}
        ordenarPorPadrao="nome"
        tipoOrdenacaoPadrao="asc"
        opcoesFiltro={[{ value: 'nome', label: 'Nome' }]}
        handleEdit={id => { setIdEdicao(id); setEditando(true); }}
      >
        <Table.Column flexGrow={1} minWidth={150} sortable>
          <Table.HeaderCell>Nome</Table.HeaderCell>
          <Table.Cell dataKey="nome" />
        </Table.Column>
        <Table.Column>
          <Table.HeaderCell>Superior</Table.HeaderCell>
          <NumberCell dataKey="superior" />
        </Table.Column>
        <Table.Column>
          <Table.HeaderCell>Sinusoide</Table.HeaderCell>
          <NumberCell dataKey="sinusoide" />
        </Table.Column>
        <Table.Column>
          <Table.HeaderCell>Inferior</Table.HeaderCell>
          <NumberCell dataKey="inferior" />
        </Table.Column>
        <Table.Column sortable width={120}>
          <Table.HeaderCell>Preço/metro</Table.HeaderCell>
          <MoneyCell dataKey="precoPorMetro" />
        </Table.Column>
      </TabelaArmacoes>

      <EdicaoDrawer
        show={editando}
        id={idEdicao}
        onClose={(refresh: boolean = false) => {
          setEditando(false);
          setIdEdicao(undefined);
          if (refresh) {
            ref.current?.refresh();
          }
        }}
        update={repository.update}
        read={repository.read}
        delete={repository.deleteMany}
        formToModel={a => a}
        modelToForm={a => a}
        formElement={EdicaoArmacaoForm}
      />
    </PageLayout>
  );
}

export default ArmacoesPage;
