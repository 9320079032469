import React, { useEffect, useState } from 'react';
import { Controller, DeepMap, FieldError, UseFormMethods } from 'react-hook-form';
import { ControlLabel, FormControl, Grid, Row, Col, InputPicker, FormGroup, HelpBlock } from 'rsuite';
import Endereco from '../types/endereco';
import useDebounce from '../utils/useDebounce';
import InputMask from './InputMask';

type Props = {
  form: UseFormMethods<any>,
  errors?: DeepMap<Endereco, FieldError>,
  prefixo: string,
  errorMessage?: string,
  obrigatorio?: boolean,
};

const estados = [
  { value: "AC", label: "Acre" },
  { value: "AL", label: "Alagoas" },
  { value: "AP", label: "Amapá" },
  { value: "AM", label: "Amazonas" },
  { value: "BA", label: "Bahia" },
  { value: "CE", label: "Ceará" },
  { value: "DF", label: "Distrito Federal" },
  { value: "ES", label: "Espírito Santo" },
  { value: "GO", label: "Goiás" },
  { value: "MA", label: "Maranhão" },
  { value: "MT", label: "Mato Grosso" },
  { value: "MS", label: "Mato Grosso do Sul" },
  { value: "MG", label: "Minas Gerais" },
  { value: "PA", label: "Pará" },
  { value: "PB", label: "Paraíba" },
  { value: "PR", label: "Paraná" },
  { value: "PE", label: "Pernambuco" },
  { value: "PI", label: "Piauí" },
  { value: "RJ", label: "Rio de Janeiro" },
  { value: "RN", label: "Rio Grande do Norte" },
  { value: "RS", label: "Rio Grande do Sul" },
  { value: "RO", label: "Rondônia" },
  { value: "RR", label: "Roraima" },
  { value: "SC", label: "Santa Catarina" },
  { value: "SP", label: "São Paulo" },
  { value: "SE", label: "Sergipe" },
  { value: "TO", label: "Tocantins" },
];

function InputCep({ form, prefixo, errorMessage, obrigatorio }: Props) {
  const [cep, setCep] = useState("");
  const { setValue } = form;
  const cepDebounced: string = useDebounce(cep, 500);

  useEffect(() => {
    if (cepDebounced && cepDebounced.length >= 8) {
      const cepBusca = cepDebounced.replace(/[.-]/g, '');
      if (cepBusca.length !== 8 || isNaN(cepBusca as any))
        return;
      fetch(`https://viacep.com.br/ws/${cepBusca}/json/`)
        .then(response =>  {
          if (response.ok) {
            response.json()
              .then(dados => {
                if (!dados.erro) {
                  setValue(`${prefixo}logradouro`, dados.logradouro);
                  setValue(`${prefixo}cidade`, dados.localidade);
                  setValue(`${prefixo}uf`, dados.uf);
                  setValue(`${prefixo}bairro`, dados.bairro);
                }
              });
          }
        })
        .catch(_reason => {})
    }
  }, [cepDebounced, prefixo, setValue]);

  return (
    <Controller
      name={`${prefixo}cep`}
      control={form.control}
      // rules={obrigatorio ? { required: 'Este campo é obrigatório' } : undefined}
      defaultValue=""
      render={({ name, value, onChange, onBlur }) => (
        <FormControl
          accepter={InputMask}
          mask={[
            /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/
          ]}
          name={name}
          value={value}
          onChange={(value) => { setCep(value); onChange(value); }}
          onBlur={onBlur}
          placeholder="ex: 00.000-000"
          errorMessage={errorMessage}
        />
      )}
    />
  );
}

function EnderecoForm({ form, errors, prefixo, obrigatorio = true }: Props) {
  return (
    <>
      <FormGroup>
        <ControlLabel>CEP</ControlLabel>
        <InputCep form={form} prefixo={prefixo} errorMessage={errors?.cep && errors.cep.message} obrigatorio={obrigatorio} />
        <HelpBlock>
          <a target="_blank" rel="noopener noreferrer" href="http://www.buscacep.correios.com.br/sistemas/buscacep/">
            Não sabe o CEP?
          </a>
        </HelpBlock>
      </FormGroup>
      <FormGroup>
        <Grid fluid style={{ padding: '0' }}>
          <Row>
            <Col xs={18}>
            <FormGroup>
              <ControlLabel>Logradouro</ControlLabel>
              <FormControl
                name={`${prefixo}logradouro`}
                placeholder="ex: Rua das Palmeiras"
                inputRef={form.register({ required: obrigatorio ? 'Este campo é obrigatório' : undefined })}
                errorMessage={errors?.logradouro && errors.logradouro.message}
              />
            </FormGroup>
            </Col>
            <Col xs={6}>
            <FormGroup>
              <ControlLabel>Número</ControlLabel>
              <FormControl
                name={`${prefixo}numero`}
                placeholder="ex: 300"
                inputRef={form.register}
                errorMessage={errors?.numero && errors.numero.message}
              />
            </FormGroup>
            </Col>
          </Row>
        </Grid>
      </FormGroup>
      <FormGroup>
        <ControlLabel>Complemento</ControlLabel>
        <FormControl
          name={`${prefixo}complemento`}
          placeholder="ex: Apartamento 304"
          inputRef={form.register}
        />
      </FormGroup>
      <FormGroup>
        <ControlLabel>Bairro</ControlLabel>
        <FormControl
          name={`${prefixo}bairro`}
          placeholder="ex: Santa Fé"
          inputRef={form.register}
          errorMessage={errors?.bairro && errors.bairro.message}
        />
      </FormGroup>
      <Grid fluid style={{ padding: '0' }}>
        <Row>
          <Col xs={12}>
            <ControlLabel>Cidade</ControlLabel>
            <FormControl
              name={`${prefixo}cidade`}
              placeholder="ex: Cambé"
              inputRef={form.register}
              errorMessage={errors?.cidade && errors.cidade.message}
            />
          </Col>
          <Col xs={12}>
            <ControlLabel>UF</ControlLabel>
            <Controller
              name={`${prefixo}uf`}
              control={form.control}
              defaultValue={null}
              render={({ name, value, onChange, onBlur }) => (
                <FormControl
                  name={name}
                  accepter={InputPicker}
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  data={estados}
                  placeholder="ex: PR"
                  placement="topStart"
                  errorMessage={errors?.uf && errors.uf.message}
                />
              )}
            />
          </Col>
        </Row>
      </Grid>
    </>
  );
};

export default EnderecoForm;
