import Axios from "axios";
import Despesa from "../../types/despesa";
import { Pagina } from "../../types/pagina";
import RequisicaoPagina from "../../types/requisicao-pagina";
import { DespesaRepository } from "../interfaces/despesa-repository";

class ApiDespesaRepository implements DespesaRepository {
  async findAll(): Promise<Despesa[]> {
    const resposta = await Axios.get('/financeiro/despesas');

    if (resposta.status === 200) {
      return resposta.data;
    } else {
      return [];
    }
  }

  async buscarParaExibicao(requisicao: RequisicaoPagina): Promise<Pagina<Despesa>> {
    const resposta = await Axios.get(
      '/financeiro/despesas/exibicao',
      { params: requisicao },
    );

    if (resposta.status === 200) {
      return resposta.data;
    } else if (resposta.status === 204) {
      return {
        total: 0,
        pagina: [],
      }
    }

    throw new Error(`Falha ao buscar! O servidor respondeu com ${resposta.status}: ${resposta.data.message}`);
  }

  async create(despesa: Despesa): Promise<true | string | object> {
    const resposta = await Axios.post('/financeiro/despesas', despesa);

    if (resposta.status === 200) {
      return true;
    } else if (resposta.status === 500) {
      return resposta.data.message;
    } else if (resposta.status === 400) {
      return resposta.data;
    }

    return 'Erro inesperado!';
  }

  async read(id: number): Promise<Despesa | null> {
    const resposta = await Axios.get<Despesa>(`/financeiro/despesas/${id}`);

    if (resposta.status === 200) {
      return resposta.data;
    }

    return null;
  }

  async update(despesa: Despesa): Promise<true | object | string> {
    const resposta = await Axios.put('/financeiro/despesas', despesa);

    if (resposta.status === 200) {
      return true;
    } else if (resposta.status === 500) {
      return resposta.data.message;
    } else if (resposta.status === 400) {
      return resposta.data;
    }

    return 'Um erro inesperado ocorreu!';
  }

  delete(id: number): Promise<boolean> {
    throw new Error("Method not implemented.");
  }

  async deleteMany(ids: number[]): Promise<boolean> {
    const resposta = await Axios.delete('/financeiro/despesas', {
      params: { ids: ids.join(',') }
    });

    return resposta.status === 200;
  }
}

export default ApiDespesaRepository;
