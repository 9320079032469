import React, { useState } from 'react';
import { Button } from 'rsuite';
import Orcamento from '../../../types/orcamento';
import EdicaoOrcamentoDrawer from './EdicaoOrcamentoDrawer';

type Props = {
  orcamento: Orcamento,
  refresh(): void,
}

const EdicaoOrcamentoButton = ({ orcamento, refresh }: Props) => {
  const [showDrawer, setShowDrawer] = useState(false);

  return (
    <>
      <Button onClick={() => setShowDrawer(true)} appearance="subtle">Editar</Button>
      <EdicaoOrcamentoDrawer
        show={showDrawer}
        onClose={doRefresh => { setShowDrawer(false); if (doRefresh) refresh(); }}
        orcamento={orcamento}
      />
    </>
  );
};

export default EdicaoOrcamentoButton;
