import React, { useState } from 'react';

import 'rsuite/dist/styles/rsuite-default.css';
import { Container, Content, Icon, IntlProvider } from 'rsuite';
import AppSidebar from './components/AppSidebar';
import { MainRouter } from './pages';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import ptBr from 'rsuite/lib/IntlProvider/locales/pt_BR';
import ProtectedRoute from './components/ProtectedRoute';
import LoginPage from './pages/LoginPage';

const App = () => {
  const [sidebarClosed, setSidebarClosed] = useState(window.innerWidth < 768);

  return (
    <IntlProvider locale={ptBr}>
      <Router>
        <Switch>
          <Route exact path="/login" component={LoginPage} />
          <ProtectedRoute>
            <Container style={{ backgroundColor: '#f7f7fa' }}>
              <AppSidebar closed={sidebarClosed} setSidebarClosed={setSidebarClosed} />
              <Content className="app-body">
                <Icon
                  icon="bars"
                  size="2x"
                  className="app-hamburger-button"
                  onClick={() => setSidebarClosed(false)}
                />
                <MainRouter />
              </Content>
            </Container>
          </ProtectedRoute>
        </Switch>
      </Router>
    </IntlProvider>
  );
}

export default App;
