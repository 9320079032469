import React from 'react';
import { Container, Loader } from 'rsuite';
import Usuario from './types/usuario';
import { FakeApiRepositoryFactory } from './repositories/fake-api/repository-factory';
import { RepositoryFactory } from './repositories/interfaces/repository-factory';

export type AuthState = {
    loading: boolean,
    authenticated: boolean,
    usuario: Usuario | null,
};

export type AppContextProps = {
    authState: AuthState;
    repositoryFactory: RepositoryFactory;
};

export type AppContextState = AppContextProps & {
    setAuthState(a: AuthState): void,
}

export const AppContext = React.createContext<AppContextState>({
    authState: { loading: false, authenticated: true, usuario: { username: 'teste', nome: 'Vanderlei', role: 'G' } },
    repositoryFactory: new FakeApiRepositoryFactory(),
    setAuthState: () => {},
});


export class AppContextProvider extends React.Component<{ repositoryFactory: RepositoryFactory }, AppContextState> {
    constructor(props: { repositoryFactory: RepositoryFactory }) {
        super(props);

        this.setAuthState = this.setAuthState.bind(this);

        this.state = {
            authState: {
                loading: true,
                authenticated: false,
                usuario: null,
            },
            setAuthState: this.setAuthState,
            repositoryFactory: props.repositoryFactory,
        }
    }

    async componentDidMount() {
        const authRepository = this.state.repositoryFactory.getAuthRepository();
        const authenticated = await authRepository.isAuthenticated();
        if (authenticated === false) {
            this.setState({
                authState: {
                    authenticated: false,
                    loading: false,
                    usuario: null,
                }
            });
        } else {
            this.setState({
                authState: {
                    authenticated: true,
                    loading: false,
                    usuario: authenticated,
                }
            });
        }
    }

    setAuthState(authState: AuthState) {
        this.setState({ authState });
    }

    render() {
        const { loading } = this.state.authState;

        if (loading) {
            return (
                <Container>
                    <Loader center size="lg" />
                </Container>
            );
        }

        return (
            <AppContext.Provider value={this.state}>
                {this.props.children}
            </AppContext.Provider>
        );
    }
}
