import React from 'react';
import { Table, Tooltip, Whisper } from 'rsuite';

const NameCell = ({ rowData, dataKey, ...props }: any) => (
  <Table.Cell {...props}>
    <Whisper placement="topStart" speaker={<Tooltip>{rowData[dataKey]}</Tooltip>} trigger="hover">
      <span>{rowData[dataKey]}</span>
    </Whisper>
  </Table.Cell>
);

export default NameCell;
