import React, { useContext, useEffect, useState } from 'react';
import { Input, Table } from 'rsuite';
import { AppContext } from '../context';
import MoneyCell from './MoneyCell';
import NumberCell from './NumberCell';
import SelecaoModal from './SelecaoModal';

type Props = {
  value: number | null,
  onChange: (id: number) => void,
};

const InputFerro = ({ value, onChange }: Props) => {
  const [showModal, setShowModal] = useState(false);
  const [nome, setNome] = useState('Selecionar ferro...');
  const context = useContext(AppContext);
  const ferroRepository = context.repositoryFactory.getFerroRepository(context);

  useEffect(() => {
    if (value) {
      setNome('Carregando...');
      ferroRepository.read(value).then(ferro => {
        if (ferro) {
          setNome(`${ferro.bitola} mm`);
        }
      });
    } else {
      setNome('Selecionar ferro...');
    }
  }, [value, ferroRepository]);

  return (
    <>  
      <Input onClick={() => setShowModal(true)} readOnly value={nome} style={{ cursor: 'pointer' }} />
      <SelecaoModal
        show={showModal}
        buscarParaSelecao={ferroRepository.buscarParaSelecao}
        onClose={selected => {
          if (selected?.id) {
            onChange(selected.id);
          }
          setShowModal(false);
        }}
      >
        <Table.Column>
          <Table.HeaderCell>Bitola</Table.HeaderCell>
          <NumberCell dataKey="bitola" />
        </Table.Column>
        <Table.Column>
          <Table.HeaderCell>kg/m</Table.HeaderCell>
          <NumberCell dataKey="kgPorMetro" />
        </Table.Column>
        <Table.Column>
          <Table.HeaderCell>Preço Un.</Table.HeaderCell>
          <MoneyCell dataKey="precoUnitario" />
        </Table.Column>
      </SelecaoModal>
    </>
  );
};

export default InputFerro;
