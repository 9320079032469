import React, { useContext, useState } from 'react';
import { Checkbox, Col, FlexboxGrid, Icon, IconButton } from 'rsuite';
import EdicaoDrawer from '../../../components/EdicaoDrawer';
import { AppContext } from '../../../context';
import ComodoProjeto, { comodoModelToForm } from "../../../types/comodo-projeto";
import EdicaoComodoForm from './EdicaoComodoForm';

type Props = {
  idProjeto: number,
  comodos: Array<ComodoProjeto>,
  comodosSelecionados: Set<number>,
  setComodosSelecionados: React.Dispatch<React.SetStateAction<Set<number>>>,
  refresh(): void,
};

const ListaComodos = ({ idProjeto, comodos, comodosSelecionados, setComodosSelecionados, refresh }: Props ) => {
  const [editando, setEditando] = useState(false);
  const [idEdicao, setIdEdicao] = useState<number | undefined>(undefined);
  
  const context = useContext(AppContext);
  const repository = context.repositoryFactory.getComodoProjetoRepository(context);
  
  return (
    <>
      <FlexboxGrid style={{ alignItems: 'stretch' }} className="comodos">
        {comodos.map(comodo => (
          <FlexboxGrid.Item key={comodo.id} componentClass={Col} colspan={24} md={12} className="comodo">
            <div className="header-comodo">
              <Checkbox
                value={comodo.id}
                checked={comodo.id ? comodosSelecionados.has(comodo.id) : false}
                onChange={(id, value) => {
                  if (value) {
                    setComodosSelecionados(cs => {
                      const newSet = new Set(cs);
                      newSet.add(id);
                      return newSet;
                    });
                  } else {
                    setComodosSelecionados(cs => {
                      const newSet = new Set(cs);
                      newSet.delete(id);
                      return newSet;
                    });
                  }
                }}
              >{comodo.nome}</Checkbox>
            </div>
              
            <div className="corpo-comodo">
              <div className="topo-corpo-comodo">
                <span className="laje-comodo">{comodo.nomeLaje}</span>
                <IconButton
                  appearance="default"
                  onClick={() => { setIdEdicao(comodo.id); setEditando(true); }}
                  icon={<Icon icon="edit2" />}
                />
              </div>

              <p className="titulo-comodo">Informações da Laje</p>
              {comodo.tipoLaje === 'T' ? 'Treliçada' : 'Convencional'} - {comodo.forroPisoLaje === 'P' ? 'Piso' : 'Forro'}<br/>
              {comodo.nomeLajota}<br/>
              {comodo.sobrecargaLaje} kg/m²<br/>
              {comodo.tipoLaje === 'T' && (<span>Armação {comodo.nomeArmacao}</span>)}<br/>

              <div className="tabelas-comodo">
                <aside>
                <p className="titulo-comodo">Dimensões</p>
                  <table className="informacoes-comodo">
                    <tbody>
                      <tr>
                        <td className="nome-valor">Vão</td>
                        <td>{(+comodo.vao).toFixed(2)} m</td>
                      </tr>
                      <tr>
                        <td className="nome-valor">Comprimento</td>
                        <td>{(+comodo.comprimento).toFixed(2)} m</td>
                      </tr>
                      {comodo.apoio && (
                        <tr>
                          <td className="nome-valor">Apoio</td>
                          <td>{(+comodo.apoio).toFixed(2)} m</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </aside>
                <aside>
                  {comodo.tipoLaje === 'T' && comodo.ferrosPrincipais && (
                    <>
                      <p className="titulo-comodo">Ferros</p>
                      <table className="ferros-comodo">
                        <thead>
                          <tr>
                            <td className="nome-valor">Qtd.</td>
                            <td className="nome-valor">Bitola</td>
                            <td>Comp.</td>
                          </tr>
                        </thead>
                        <tbody>
                          {comodo.ferrosPrincipais.map(ferro => (
                            <tr key={ferro.bitola}>
                              <td className="nome-valor">{ferro.quantidade}</td>
                              <td className="nome-valor">{ferro.bitola?.toFixed(2)} mm</td>
                              <td>{(+ferro.comprimento).toFixed(2)} m</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </>
                  )}
                </aside>
              </div>
            </div>
          </FlexboxGrid.Item>
        ))}
      </FlexboxGrid>
      <EdicaoDrawer<ComodoProjeto>
        show={editando}
        id={idEdicao}
        onClose={(doRefresh: boolean = false) => {
          setEditando(false);
          setIdEdicao(undefined);
          if (doRefresh) {
            refresh();
          }
        }}
        update={comodo => { comodo.idProjeto = idProjeto; return repository.update(comodo); }}
        read={id => { return repository.read(idProjeto, id) }}
        delete={(ids) => repository.deleteMany(ids, idProjeto)}
        formToModel={p => p}
        modelToForm={comodoModelToForm}
        formElement={EdicaoComodoForm}
      />
    </>
  );
}

export default ListaComodos;
