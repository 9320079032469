export const formatPhone = (phone: string) => {
  if (phone.length === 8) {
    return `${phone.slice(0,4)}-${phone.slice(4,8)}`;
  } else if (phone.length === 9) {
    return `${phone.slice(0,5)}-${phone.slice(5,9)}`;
  } else if (phone.length === 10) {
    return `(${phone.slice(0,2)}) ${phone.slice(2,6)}-${phone.slice(6,10)}`
  } else if (phone.length === 11) {
    return `(${phone.slice(0,2)}) ${phone.slice(2,7)}-${phone.slice(7,11)}`
  } else {
    return phone;
  }
}

export const formatCep = (cep: string) => {
  if (cep.length === 8) {
    return `${cep.slice(0, 2)}.${cep.slice(2,5)}-${cep.slice(5,8)}`;
  } else {
    return cep;
  }
}

export const formatCpf = (cpf: string) => {
  if (cpf.length === 11) {
    return `${cpf.slice(0, 3)}.${cpf.slice(3, 6)}.${cpf.slice(6, 9)}-${cpf.slice(9, 11)}`;
  } else {
    return cpf;
  }
}

export const formatCnpj = (cnpj: string) => {
  if (cnpj.length === 14) {
    return `${cnpj.slice(0, 2)}.${cnpj.slice(2, 5)}.${cnpj.slice(5, 8)}/${cnpj.slice(8, 12)}-${cnpj.slice(12, 14)}`;
  } else {
    return cnpj;
  }
}
