import React from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { ControlLabel, FormControl, FormGroup, HelpBlock, Input, InputGroup, Radio, RadioGroup } from 'rsuite';
import UncontrolledFormInput from '../../../components/UncontrolledFormInput';
import Lajota from '../../../types/lajota';

const InsercaoLajotaForm = () => {
  const form = useFormContext<Lajota>();
  const watchTipo = useWatch<string>({ name: 'tipo', control: form.control });
  const watchTipoMaterial = useWatch<string>({ name: 'tipoMaterial', control: form.control });

  return (
    <>
      <FormGroup>
        <ControlLabel>Nome</ControlLabel>
        <FormControl
          autoFocus
          name="nome"
          placeholder="ex: EPS H8"
          inputRef={form.register({ required: 'Este campo é necessário' })}
          errorMessage={form.errors.nome && form.errors.nome.message}
        />
      </FormGroup>
      <FormGroup>
        <ControlLabel>Tipo</ControlLabel>
        <Controller
          defaultValue={null}
          control={form.control}
          name="tipo"
          rules={{ required: 'Este campo é necessário!' }}
          render={({ onChange, value, name }) => (
            <RadioGroup onChange={onChange} value={value} name={name} inline>
              <Radio value="C">Convencional</Radio>
              <Radio value="T">Treliçada</Radio>
            </RadioGroup>
          )}
        />
        {form.errors.tipo && <HelpBlock style={{ color: 'red' }}>{form.errors.tipo.message}</HelpBlock>}
      </FormGroup>
      <FormGroup>
        <ControlLabel>Material</ControlLabel>
        <Controller
          defaultValue={null}
          control={form.control}
          name="tipoMaterial"
          rules={{ required: 'Este campo é necessário!' }}
          render={({ onChange, value, name }) => (
            <RadioGroup onChange={onChange} value={value} name={name} inline>
              <Radio value="C">Cerâmica</Radio>
              <Radio value="E">EPS</Radio>
            </RadioGroup>
          )}
        />
        {form.errors.tipoMaterial && <HelpBlock style={{ color: 'red' }}>{form.errors.tipoMaterial.message}</HelpBlock>}
      </FormGroup>
      <FormGroup>
        <ControlLabel>Unidade de medida</ControlLabel>
        <FormControl
          name="unidadeMedida"
          placeholder="ex: un"
          inputRef={form.register({ required: 'Este campo é necessário' })}
          errorMessage={form.errors.unidadeMedida && form.errors.unidadeMedida.message}
        />
      </FormGroup>
      <FormGroup>
        <ControlLabel>Valor por unidade</ControlLabel>
        <InputGroup style={{ width: '120px' }}>
          <InputGroup.Addon>R$</InputGroup.Addon>
          <FormControl
            name="valor"
            placeholder="ex: 12.75"
            inputRef={form.register({ required: 'Este campo é necessário' })}
            errorMessage={form.errors.valor && form.errors.valor.message}
          />
        </InputGroup>
      </FormGroup>
      {(watchTipo === 'T' || watchTipoMaterial === 'E') && (
        <>
          <FormGroup>
            <ControlLabel>Tamanho</ControlLabel>
            <FormControl
              name="tamanho"
              placeholder="ex: 1.2"
              inputRef={form.register({ required: 'Este campo é necessário' })}
              errorMessage={form.errors.tamanho && form.errors.tamanho.message}
            />
          </FormGroup>
          <FormGroup>
            <ControlLabel>Intereixo</ControlLabel>
            <FormControl
              name="intereixo"
              placeholder="ex: 0.42"
              inputRef={form.register({ required: 'Este campo é necessário' })}
              errorMessage={form.errors.intereixo && form.errors.intereixo.message}
            />
          </FormGroup>
        </>
      )}
      {(watchTipo === 'T' && watchTipoMaterial !== 'E') && (
        <>
          <FormGroup>
            <ControlLabel>Altura</ControlLabel>
            <FormControl
              name="altura"
              placeholder="ex: 0.1"
              inputRef={form.register({ required: 'Este campo é necessário' })}
              errorMessage={form.errors.altura && form.errors.altura.message}
            />
          </FormGroup>
          <FormGroup>
            <ControlLabel>Valor por metro cúbico</ControlLabel>
            <InputGroup style={{ width: '120px' }}>
              <InputGroup.Addon>R$</InputGroup.Addon>
              <UncontrolledFormInput errorMessage={form.errors.precoMetroCubico?.message}>
                <Input
                  name="precoMetroCubico"
                  placeholder="ex: 15.72"
                  inputRef={form.register({
                    required: 'Este campo é necessário',
                    validate: value => !isNaN(value) || 'Deve ser um número',
                  })}
                />
              </UncontrolledFormInput>
            </InputGroup>
          </FormGroup>
        </>
      )}
    </>
  );
};

export default InsercaoLajotaForm;
