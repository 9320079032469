import React from 'react';
import { Table } from 'rsuite';

const NumberCell = ({ precision = 2, rowData, dataKey, ...props }: any) => (
  <Table.Cell {...props}>
    {rowData[dataKey] && (+rowData[dataKey]).toFixed(2)}
  </Table.Cell>
);

export default NumberCell;
