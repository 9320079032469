import Endereco, { enderecoFormToModel } from "./endereco";

type Orcamento = {
  id: number,
  idProjeto: number,
  frete?: number,
  desconto?: number,
  jurosParcela?: number,
  endereco: Endereco,
  observacao?: string,
  art?: string,
  parcelas: Array<ParcelaOrcamento>,
  produtos: Array<ProdutoOrcamento>,
  valorProducao: number,
  valorFinal: number,
  valorPagamento: number,
  nomeCliente: string,
  telefoneCliente?: string,
  dataCadastro: string,
  venda: "S" | "N",
};

export type OrcamentoProjeto = {
  id: number,
  idProjeto: number,
  dataCadastro: string,
  valorFinal: number,
  venda: "S" | "N",
};

export type OrcamentoInsercao = {
  id?: number,
  idProjeto?: number,
  endereco?: Endereco,
  observacao?: string,
  art?: string,
};

type ProdutoOrcamento = {
  id: number,
  nome: string,
  quantidade: number,
  valor: number,
  valorTotal: number,
  tipo: "laje" | "convencional",
};

export type ParcelaOrcamento = {
  id: number,
  data?: string,
  paga?: "S" | "N",
  valor?: number,
};

export type OrcamentoForm = {
  id?: number,
  idProjeto?: number,
  endereco?: Endereco,
  observacao: string,
  art: string,
};

export const orcamentoFormToModel = (form: OrcamentoForm): OrcamentoInsercao => {
  return {
    ...form,
    art: form.art.length > 0 ? form.art : undefined,
    endereco: form.endereco ? enderecoFormToModel(form.endereco) : undefined,
  };
};

export type FinalizacaoOrcamento = {
  numeroParcelas: number,
  data: string,
  desconto?: number,
  frete?: number,
  jurosParcela?: number,
};

export type FinalizacaoOrcamentoForm = {
  numeroParcelas: string,
  data: Date,
  desconto: string,
  frete: string,
  jurosParcela: string,
};

export const finalizacaoOrcamentoFormToModel = (form: FinalizacaoOrcamentoForm): FinalizacaoOrcamento => {
  return {
    numeroParcelas: Number(form.numeroParcelas),
    data: form.data.toLocaleDateString('pt-BR'),
    desconto: form.desconto.length > 0 ? Number(form.desconto) : undefined,
    frete: form.frete.length > 0 ? Number(form.frete) : undefined,
    jurosParcela: form.jurosParcela.length > 0 ? Number(form.jurosParcela) / 100 : undefined,
  };
}

export type OrcamentoExibicao = {
  id: number,
  idProjeto: number,
  nomeCliente: string,
  art: string,
  venda: "S" | "N",
  valorFinal: number,
  dataCadastro: string,
};

export default Orcamento;
