import React from 'react';

import { Checkbox, Icon, IconButton, Table } from 'rsuite';
import { AppContext } from '../../context';
import { Pagina } from '../../types/pagina';
import RequisicaoPagina from '../../types/requisicao-pagina';
import AdicaoButton from './AdicaoButton';
import FiltroForm from './FiltroForm';
import RemocaoButton from './RemocaoButton';

const CheckCell = ({ rowData, onChange, checkedKeys, dataKey, ...props }: any) => (
  <Table.Cell {...props} style={{ padding: 0 }}>
    <div style={{ lineHeight: '46px' }}>
      <Checkbox
        value={rowData[dataKey]}
        inline
        onChange={onChange}
        checked={checkedKeys.some((item: any) => item === rowData[dataKey])}
      />
    </div>
  </Table.Cell>
);

const ActionCell = ({ rowData, onEdit, dataKey, ...props }: any) => {
  return (
    <Table.Cell {...props} style={{ padding: 0, lineHeight: '46px' }}>
      <IconButton
        appearance="default"
        onClick={() => onEdit(rowData[dataKey])}
        icon={<Icon icon="edit2" />}
      />
    </Table.Cell>
  );
}

interface TipoDado {
  id: number;
};

export interface BaseTabelaProps {
  ordenarPorPadrao: string | undefined,
  tipoOrdenacaoPadrao: "desc" | "asc" | undefined,
  opcoesFiltro?: Array<Record<string, any>>;
  handleEdit?: (id: number) => void;
  mostrarBotaoAdicao?: boolean,
}

interface State<T extends TipoDado> {
  dados: Array<T>;
  carregando: boolean,
  pagina: number,
  total: number,
  porPagina: number,
  filtro: string,
  filtrarPor: string,
  ordenarPor: string | undefined,
  tipoOrdenacao: "desc" | "asc",
  selecionados: Array<number>,
}

type OnCloseCallback = (doRefresh: boolean) => void;
export type AdicaoModalFunction = (show: boolean, onClose: OnCloseCallback) => React.ReactNode;
export type RemocaoModalFunction = (show: boolean, selecionados: Array<number>, onClose: OnCloseCallback) => React.ReactNode;

abstract class BaseTabela<T extends TipoDado> extends React.Component<BaseTabelaProps, State<T>> {
  static contextType = AppContext;

  constructor(props: BaseTabelaProps) {
    super(props);
    this.state = {
      dados: [],
      carregando: true,
      pagina: 1,
      total: 0,
      porPagina: 10,
      filtro: '',
      filtrarPor: this.props.opcoesFiltro ? this.props.opcoesFiltro[0].value : '',
      ordenarPor: this.props.ordenarPorPadrao,
      tipoOrdenacao: this.props.tipoOrdenacaoPadrao ? this.props.tipoOrdenacaoPadrao : "asc",
      selecionados: [],
    };
    this.buscarParaExibicao = this.buscarParaExibicao.bind(this);
    this.handleDefinirFiltro = this.handleDefinirFiltro.bind(this);
    this.handleSortColumn = this.handleSortColumn.bind(this);
    this.handleSelecao = this.handleSelecao.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.refresh = this.refresh.bind(this);
  }

  abstract exibirAdicaoModal: AdicaoModalFunction;
  abstract exibirRemocaoModal: RemocaoModalFunction;
  abstract buscarParaExibicao(requisicao: RequisicaoPagina): Promise<Pagina<T>>;

  async componentDidMount() {
    const resposta = await this.buscarParaExibicao({
      pagina: this.state.pagina,
      qtdItens: this.state.porPagina,
      ordenarPor: this.state.ordenarPor ? this.state.ordenarPor : undefined,
      tipoOrdenacao: this.state.ordenarPor ? this.state.tipoOrdenacao : undefined,
    });
    this.setState({dados: resposta.pagina, total: resposta.total, carregando: false});
  }

  async handleChangeLength(length: number) {
    this.setState({ carregando: true });

    const resposta = await this.buscarParaExibicao({
      pagina: 1,
      qtdItens: length,
      filtro: this.state.filtro !== '' ? this.state.filtro : undefined,
      filtrarPor: this.state.filtro !== '' ? this.state.filtrarPor : undefined,
      ordenarPor: this.state.ordenarPor,
      tipoOrdenacao: this.state.ordenarPor ? this.state.tipoOrdenacao : undefined,
    });

    this.setState({
      dados: resposta.pagina,
      total: resposta.total,
      carregando: false,
      porPagina: length,
      pagina: 1,
    });
  }

  async handleChangePage(page: number) {
    this.setState({
      carregando: true,
    });

    const resposta = await this.buscarParaExibicao({
      pagina: page,
      qtdItens: this.state.porPagina,
      filtro: this.state.filtro !== '' ? this.state.filtro : undefined,
      filtrarPor: this.state.filtro !== '' ? this.state.filtrarPor : undefined,
      ordenarPor: this.state.ordenarPor,
      tipoOrdenacao: this.state.ordenarPor ? this.state.tipoOrdenacao : undefined,
    });

    this.setState({
      dados: resposta.pagina,
      total: resposta.total,
      carregando: false,
      pagina: page,
    });
  }

  async handleDefinirFiltro(filtro: string, filtrarPor: string) {
    if (this.state.filtro === '' && filtro === '') {
      return;
    }

    this.setState({
      carregando: true,
      filtro,
      filtrarPor,
    });

    const resposta = await this.buscarParaExibicao({
      pagina: 1,
      qtdItens: this.state.porPagina,
      filtro,
      filtrarPor,
      ordenarPor: this.state.ordenarPor,
      tipoOrdenacao: this.state.ordenarPor ? this.state.tipoOrdenacao : undefined,
    });

    this.setState({
      dados: resposta.pagina,
      total: resposta.total,
      carregando: false,
      pagina: 1,
    });
  }

  async handleSortColumn(dataKey: string, sortType: "desc" | "asc") {
    this.setState({
      carregando: true,
      ordenarPor: dataKey,
      tipoOrdenacao: sortType,
    });

    const resposta = await this.buscarParaExibicao({
      pagina: this.state.pagina,
      qtdItens: this.state.porPagina,
      filtro: this.state.filtro !== '' ? this.state.filtro : undefined,
      filtrarPor: this.state.filtro !== '' ? this.state.filtrarPor : undefined,
      ordenarPor: dataKey,
      tipoOrdenacao: sortType,
    });

    this.setState({
      dados: resposta.pagina,
      total: resposta.total,
      carregando: false,
    });
  }

  async refresh() {
    this.setState({
      carregando: true,
    });

    const resposta = await this.buscarParaExibicao({
      pagina: this.state.pagina,
      qtdItens: this.state.porPagina,
      filtro: this.state.filtro !== '' ? this.state.filtro : undefined,
      filtrarPor: this.state.filtro !== '' ? this.state.filtrarPor : undefined,
      ordenarPor: this.state.ordenarPor,
      tipoOrdenacao: this.state.ordenarPor ? this.state.tipoOrdenacao : undefined,
    });

    this.setState({
      dados: resposta.pagina,
      total: resposta.total,
      carregando: false,
    });
  }

  handleSelecao(value: number, checked: boolean) {
    if (checked) {
      this.setState((state) => ({ selecionados: [ ...state.selecionados, value ] }));
    } else {
      this.setState((state) => ({ selecionados: state.selecionados.filter(item => item !== value) }));
    }
  }

  async handleDelete() {
    this.setState({ selecionados: [] });
    this.refresh();
  }

  render() {
    const { dados, selecionados } = this.state;

    const { mostrarBotaoAdicao = true } = this.props;

    let todosSelecionados = false;
    let indeterminado = false;

    if (selecionados.length === 0) {
      todosSelecionados = false;
    } else if (dados.length === selecionados.length) {
      todosSelecionados = true;
    } else {
      indeterminado = true;
    }

    return (
      <>
        {mostrarBotaoAdicao && (
          <AdicaoButton refresh={this.refresh}>
            {this.exibirAdicaoModal}
          </AdicaoButton>
        )}
        <div className="painel-tabela">
          {this.props.opcoesFiltro && (
            <FiltroForm
              definirFiltro={this.handleDefinirFiltro}
              opcoes={this.props.opcoesFiltro}
            />
          )}
          <RemocaoButton selecionados={this.state.selecionados} onDelete={this.handleDelete}>
            {this.exibirRemocaoModal}
          </RemocaoButton>
        </div>
        <Table
          data={dados}
          loading={this.state.carregando}
          onSortColumn={this.handleSortColumn}
          sortColumn={this.state.ordenarPor}
          sortType={this.state.tipoOrdenacao}
          autoHeight={true}
        >
          <Table.Column width={50} align="center" fixed>
            <Table.HeaderCell style={{ padding: 0 }}>
              <div style={{ lineHeight: '40px' }}>
                <Checkbox
                  inline
                  checked={todosSelecionados}
                  indeterminate={indeterminado}
                  onChange={() => {
                    this.setState((({ selecionados, dados }) => {
                      if (selecionados.length === 0) {
                        return { selecionados: dados.map(c => c.id) };
                      } else {
                        return { selecionados: [] };
                      }
                    }));
                  }}
                />
              </div>
            </Table.HeaderCell>
            <CheckCell
              dataKey="id"
              checkedKeys={this.state.selecionados}
              onChange={this.handleSelecao}
            />
          </Table.Column>
          {this.props.children}
          {this.props.handleEdit && (
            <Table.Column>
              <Table.HeaderCell>Editar</Table.HeaderCell>
              <ActionCell onEdit={this.props.handleEdit} dataKey="id" />
            </Table.Column>
          )}
        </Table>
        <Table.Pagination
          size="md"
          lengthMenu={[
            { value: 1, label: 1 },
            { value: 10, label: 10 },
            { value: 20, label: 20 },
            { value: 50, label: 50 },
          ]}
          activePage={this.state.pagina}
          displayLength={this.state.porPagina}
          total={this.state.total}
          onChangeLength={length => this.handleChangeLength(length)}
          onChangePage={page => this.handleChangePage(page)}
        />
      </>
    );
  }
}

export default BaseTabela;
