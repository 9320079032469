import React, { useContext, useRef, useState } from 'react';

import PageLayout from '../../components/PageLayout';
import { ClienteExibicao, clienteFormToModel, clienteModelToForm } from '../../types/cliente';
import { ClienteRepository } from '../../repositories/interfaces/cliente-repository';
import BaseTabela from '../../components/BaseTabela';
import { AdicaoModalFunction, BaseTabelaProps, RemocaoModalFunction } from '../../components/BaseTabela/BaseTabela';
import RemocaoModal from '../../components/RemocaoModal';
import RequisicaoPagina from '../../types/requisicao-pagina';
import { Pagina } from '../../types/pagina';
import { Table } from 'rsuite';
import { AppContext, AppContextProps } from '../../context';
import NameCell from '../../components/NameCell';
import AdicaoModal from '../../components/AdicaoModal';
import InsercaoClienteForm from './InsercaoClienteForm';
import EdicaoDrawer from '../../components/EdicaoDrawer';
import EdicaoClienteForm from './EdicaoClienteForm';
import CpfCnpjCell from '../../components/CpfCnpjCell';
import PhoneCell from '../../components/PhoneCell';

class TabelaClientes extends BaseTabela<ClienteExibicao> {
  private clienteRepository: ClienteRepository;

  constructor(props: BaseTabelaProps, context: AppContextProps) {
    super(props);
    this.clienteRepository = context.repositoryFactory.getClienteRepository(context);
  }

  buscarParaExibicao(requisicao: RequisicaoPagina) : Promise<Pagina<ClienteExibicao>> {
    return this.clienteRepository.buscarParaExibicao(requisicao);
  }

  exibirAdicaoModal: AdicaoModalFunction = (show, onClose) => (
    <AdicaoModal
      nome="cliente"
      show={show}
      onClose={onClose}
      element={InsercaoClienteForm}
      create={this.clienteRepository.create}
      formToModel={clienteFormToModel}
    />
  );

  exibirRemocaoModal: RemocaoModalFunction = (show, selecionados, onClose) => (
    <RemocaoModal
      show={show}
      selecionados={selecionados}
      onClose={onClose}
      deleteMany={this.clienteRepository.deleteMany}
      nome={["cliente", "clientes"]}
    />
  );
}

const ClientesPage = () => {
  const [idEdicao, setIdEdicao] = useState<number | undefined>(undefined);
  const [editando, setEditando] = useState(false);

  const context = useContext(AppContext);
  const repository = context.repositoryFactory.getClienteRepository(context);

  const ref = useRef<TabelaClientes>(null);

  return (
    <PageLayout title="Clientes">
      <TabelaClientes
        ref={ref}
        ordenarPorPadrao="dtModificacao"
        tipoOrdenacaoPadrao="desc"
        opcoesFiltro={[{ value: 'nome', label: 'Nome' }, { value: 'cpfCnpj', label: 'CPF/CNPJ' }]}
        handleEdit={id => { setEditando(true); setIdEdicao(id); }}
      >
        <Table.Column flexGrow={1} minWidth={150} sortable>
          <Table.HeaderCell>Nome</Table.HeaderCell>
          <NameCell dataKey="nome" />
        </Table.Column>
        <Table.Column>
          <Table.HeaderCell>Cidade</Table.HeaderCell>
          <Table.Cell dataKey="cidade" />
        </Table.Column>
        <Table.Column width={120} sortable>
          <Table.HeaderCell>Num. projetos</Table.HeaderCell>
          <Table.Cell dataKey="numProjetos" />
        </Table.Column>
        <Table.Column width={160}>
          <Table.HeaderCell>CPF/CNPJ</Table.HeaderCell>
          <CpfCnpjCell dataKey="cpfCnpj" typeKey="tipo" />
        </Table.Column>
        <Table.Column width={140}>
          <Table.HeaderCell>Telefone</Table.HeaderCell>
          <PhoneCell dataKey="telefone" />
        </Table.Column>
      </TabelaClientes>

      <EdicaoDrawer
        show={editando}
        id={idEdicao}
        onClose={(refresh: boolean = false) => {
          setEditando(false);
          setIdEdicao(undefined);
          if (refresh) {
            ref.current?.refresh();
          }
        }}
        update={repository.update}
        read={repository.read}
        delete={repository.deleteMany}
        formToModel={clienteFormToModel}
        modelToForm={clienteModelToForm}
        formElement={EdicaoClienteForm}
      />
    </PageLayout>
  );
}

export default ClientesPage;
