import React, { useContext, useRef, useState } from 'react';
import { Icon, IconButton, Table } from 'rsuite';
import AdicaoModal from '../../../components/AdicaoModal';
import BaseTabela from '../../../components/BaseTabela';
import { AdicaoModalFunction, BaseTabelaProps, RemocaoModalFunction } from '../../../components/BaseTabela/BaseTabela';
import EdicaoDrawer from '../../../components/EdicaoDrawer';
import NameCell from '../../../components/NameCell';
import PageLayout from '../../../components/PageLayout';
import RemocaoModal from '../../../components/RemocaoModal';
import { AppContext, AppContextProps } from '../../../context';
import { Pagina } from '../../../types/pagina';
import RequisicaoPagina from '../../../types/requisicao-pagina';
import { LajeExibicao } from '../../../types/laje';
import { LajeRepository } from '../../../repositories/interfaces/laje-repository';
import InsercaoLajeForm from './InsercaoLajeForm';
import EdicaoLajeForm from './EdicaoLajeForm';
import { useHistory } from 'react-router-dom';

class TabelaLajes extends BaseTabela<LajeExibicao> {
  private lajeRepository: LajeRepository;

  constructor(props: BaseTabelaProps, context: AppContextProps) {
    super(props);
    this.lajeRepository = context.repositoryFactory.getLajeRepository(context);
  }

  buscarParaExibicao(requisicao: RequisicaoPagina) : Promise<Pagina<LajeExibicao>> {
    return this.lajeRepository.buscarParaExibicao(requisicao);
  }

  exibirAdicaoModal: AdicaoModalFunction = (show, onClose) => (
    <AdicaoModal
      stringNovo="Nova"
      nome="laje"
      show={show}
      onClose={onClose}
      element={InsercaoLajeForm}
      create={this.lajeRepository.create}
      formToModel={f => f}
    />
  );

  exibirRemocaoModal: RemocaoModalFunction = (show, selecionados, onClose) => (
    <RemocaoModal
      show={show}
      selecionados={selecionados}
      onClose={onClose}
      deleteMany={this.lajeRepository.deleteMany}
      nome={["laje", "Lajes"]}
    />
  );
}

const ComposicaoCell = ({ rowData, onClick, dataKey, ...props }: any) => {
  return (
    <Table.Cell {...props} style={{ padding: 0, lineHeight: '46px' }}>
      { rowData.tipo === 'C' && (
        <IconButton
          appearance="default"
          onClick={() => onClick(rowData[dataKey])}
          icon={<Icon icon="cubes" />}
        />
      )}
    </Table.Cell>
  );
}

const LajesPage = () => {
  const [idEdicao, setIdEdicao] = useState<number | undefined>(undefined);
  const [editando, setEditando] = useState(false);
  
  const history = useHistory();

  const context = useContext(AppContext);
  const repository = context.repositoryFactory.getLajeRepository(context);

  const ref = useRef<TabelaLajes>(null);

  return (
    <PageLayout title="Lajes">
      <TabelaLajes
        ref={ref}
        ordenarPorPadrao="nome"
        tipoOrdenacaoPadrao="asc"
        opcoesFiltro={[
          { value: 'nome', label: 'Nome' },
          { value: 'lajota', label: 'Lajota' },
          { value: 'armacao', label: 'Armação' },
          { value: 'nomenclatura', label: 'Nomenclatura' },
        ]}
        handleEdit={id => { setIdEdicao(id); setEditando(true); }}
      >
        <Table.Column flexGrow={1} minWidth={150} sortable>
          <Table.HeaderCell>Nome</Table.HeaderCell>
          <NameCell dataKey="nome" />
        </Table.Column>
        <Table.Column sortable width={150}>
          <Table.HeaderCell>Sobrecarga (kg/m²)</Table.HeaderCell>
          <Table.Cell dataKey="sobrecarga" />
        </Table.Column>
        <Table.Column sortable>
          <Table.HeaderCell>Tipo</Table.HeaderCell>
          <Table.Cell dataKey="tipo" />
        </Table.Column>
        <Table.Column sortable>
          <Table.HeaderCell>Forro/Piso</Table.HeaderCell>
          <Table.Cell dataKey="forroPiso" />
        </Table.Column>
        <Table.Column>
          <Table.HeaderCell>Nomenclatura</Table.HeaderCell>
          <Table.Cell dataKey="nomenclatura" />
        </Table.Column>
        <Table.Column sortable>
          <Table.HeaderCell>Lajota</Table.HeaderCell>
          <NameCell dataKey="lajota" />
        </Table.Column>
        <Table.Column sortable>
          <Table.HeaderCell>Armação</Table.HeaderCell>
          <Table.Cell dataKey="armacao" />
        </Table.Column>
        <Table.Column>
          <Table.HeaderCell>Composição</Table.HeaderCell>
          <ComposicaoCell dataKey="id" onClick={(id: number) => history.push(`/produtos/lajes/${id}`)} />
        </Table.Column>
      </TabelaLajes>

      <EdicaoDrawer
        show={editando}
        id={idEdicao}
        onClose={(refresh: boolean = false) => {
          setEditando(false);
          setIdEdicao(undefined);
          if (refresh) {
            ref.current?.refresh();
          }
        }}
        update={repository.update}
        read={repository.read}
        delete={repository.deleteMany}
        formToModel={a => a}
        modelToForm={a => a}
        formElement={EdicaoLajeForm}
      />
    </PageLayout>
  );
}

export default LajesPage;
