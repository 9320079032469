import Axios from "axios";
import ComodoProjeto from "../../types/comodo-projeto";
import { ComodoProjetoRepository } from "../interfaces/comodo-projeto-repository";

class ApiComodoProjetoRepository implements ComodoProjetoRepository {
  buscarComodosDoProjeto(idProjeto: number): Promise<ComodoProjeto[]> {
    throw new Error("Method not implemented.");
  }

  async create(comodoProjeto: ComodoProjeto): Promise<any> {
    const resposta = await Axios.post('/comodos', comodoProjeto);

    if (resposta.status === 200) {
      return true;
    } else if (resposta.status === 500) {
      return resposta.data.message;
    } else if (resposta.status === 400) {
      return resposta.data;
    }
  }

  async alterarLajes(idProjeto: number, idsComodos: number[], idLaje: number): Promise<true | string> {
    const resposta = await Axios.put('/comodos/lajes', {
      idProjeto, idLaje, ids: idsComodos
    });

    if (resposta.status === 200) {
      return true;
    } else if (resposta.status === 500) {
      return resposta.data.message;
    } else {
      return "Um erro inesperado ocorreu!";
    }
  }
  
  async read(idProjeto: number, id: number): Promise<ComodoProjeto | null> {
    const resposta = await Axios.get<ComodoProjeto>(`/comodos/${idProjeto}/${id}`);

    if (resposta.status === 200) {
      return resposta.data;
    }

    return null;
  }
  async update(comodoProjeto: ComodoProjeto): Promise<true | string | object> {
    const resposta = await Axios.put('/comodos', comodoProjeto);

    if (resposta.status === 200) {
      return true;
    } else if (resposta.status === 500) {
      return resposta.data.message;
    } else if (resposta.status === 400) {
      return resposta.data;
    }

    return 'Um erro inesperado ocorreu!';
  }
  delete(id: number): Promise<boolean> {
    throw new Error("Method not implemented.");
  }

  async deleteMany(ids: number[], idProjeto: number): Promise<boolean> {
    const resposta = await Axios.delete(`/comodos/${idProjeto}`, {
      params: { ids: ids.join(',') }
    });

    return resposta.status === 200;
  }
}

export default ApiComodoProjetoRepository;
