import React from 'react';
import { Table } from 'rsuite';
import { formatPhone } from '../utils/formatters';

const PhoneCell = ({ rowData, dataKey, ...props }: any) => (
  <Table.Cell {...props}>
    {rowData[dataKey] && formatPhone(rowData[dataKey]) }
  </Table.Cell>
);

export default PhoneCell;
