import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import NotFoundPage from '../NotFoundPage';
import ProdutosPage from './geral/ProdutosPage';
import ComposicoesPage from './lajes/composicao/ComposicoesPage';
import LajesPage from './lajes/LajesPage';

export const ProdutosRouter = (_props: any) => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={`${path}/lajes/:id`} component={ComposicoesPage} />
      <Route exact path={`${path}/lajes`} component={LajesPage} />
      <Route exact path={`${path}/geral`} component={ProdutosPage} />
      <Route path="*" component={NotFoundPage}/>
    </Switch>
  );
}
