import { AppContextProps } from "../../context";
import { ClienteRepository } from "../interfaces/cliente-repository";
import { RepositoryFactory } from "../interfaces/repository-factory";
import { FakeApiClienteRepository } from "./cliente-repository";
import { AuthRepository } from "../interfaces/auth-repository";
import { FakeApiAuthRepository } from "./auth-repository";
import { FerroRepository } from "../interfaces/ferro-repository";
import { ProjetoRepository } from "../interfaces/projeto-repository";
import { ComodoProjetoRepository } from "../interfaces/comodo-projeto-repository";
import { AgregadoRepository } from "../interfaces/agregado-repository";
import { LajotaRepository } from "../interfaces/lajota-repository";
import { ArmacaoRepository } from "../interfaces/armacao-repository";
import { ProdutoRepository } from "../interfaces/produto-repository";
import { OrcamentoRepository } from "../interfaces/orcamento-repository";
import { DespesaRepository } from "../interfaces/despesa-repository";
import { ProducaoRepository } from "../interfaces/producao-repository";
import { UsuarioRepository } from "../interfaces/usuario-repository";
import { LajeRepository } from "../interfaces/laje-repository";
import { HomeRepository } from "../interfaces/home-repository";

export class FakeApiRepositoryFactory implements RepositoryFactory {
    private authRepository: AuthRepository | null;
    private clienteRepository: ClienteRepository | null;

    constructor() {
        this.authRepository = null;
        this.clienteRepository = null;
    }
    getHomeRepository(context: AppContextProps): HomeRepository {
        throw new Error("Method not implemented.");
    }
    getUsuarioRepository(context: AppContextProps): UsuarioRepository {
        throw new Error("Method not implemented.");
    }
    getProducaoRepository(context: AppContextProps): ProducaoRepository {
        throw new Error("Method not implemented.");
    }
    getDespesaRepository(context: AppContextProps): DespesaRepository {
        throw new Error("Method not implemented.");
    }
    getOrcamentoRepository(context: AppContextProps): OrcamentoRepository {
        throw new Error("Method not implemented.");
    }
    getProdutoRepository(context: AppContextProps): ProdutoRepository {
        throw new Error("Method not implemented.");
    }
    getArmacaoRepository(context: AppContextProps): ArmacaoRepository {
        throw new Error("Method not implemented.");
    }
    getLajotaRepository(context: AppContextProps): LajotaRepository {
        throw new Error("Method not implemented.");
    }
    getAgregadoRepository(context: AppContextProps): AgregadoRepository {
        throw new Error("Method not implemented.");
    }

    getLajeRepository(context: AppContextProps): LajeRepository {
        throw new Error("Method not implemented.");
    }
    getProjetoRepository(context: AppContextProps): ProjetoRepository {
        throw new Error("Method not implemented.");
    }

    getAuthRepository(): AuthRepository {
        if (this.authRepository === null) {
            this.authRepository = new FakeApiAuthRepository();
        }
        return this.authRepository;
    }

    getClienteRepository(_context: AppContextProps): ClienteRepository {
        if (this.clienteRepository === null) {
            this.clienteRepository = new FakeApiClienteRepository();
        }
        return this.clienteRepository;
    }

    getFerroRepository(context: AppContextProps): FerroRepository {
        throw new Error("Method not implemented.");
    }

    getComodoProjetoRepository(context: AppContextProps): ComodoProjetoRepository {
        throw new Error("Method not implemented.");
    }
}
