import React from 'react';
import { useFormContext } from 'react-hook-form';
import { ControlLabel, FormControl, FormGroup, Input, InputGroup } from 'rsuite';
import UncontrolledFormInput from '../../../components/UncontrolledFormInput';
import Ferro from '../../../types/ferro';

const InsercaoFerroForm = () => {
  const form = useFormContext<Ferro>();

  return (
    <>
      <FormGroup>
        <ControlLabel>Bitola</ControlLabel>
        <FormControl
          autoFocus
          name="bitola"
          placeholder="ex: 8.0"
          inputRef={form.register({ required: 'Este campo é necessário' })}
          errorMessage={form.errors.bitola && form.errors.bitola.message}
        />
      </FormGroup>
      <FormGroup>
        <ControlLabel>Quilograma/Metro</ControlLabel>
        <FormControl
          name="kgPorMetro"
          placeholder="ex: 1.55"
          inputRef={form.register({ required: 'Este campo é necessário' })}
          errorMessage={form.errors.kgPorMetro && form.errors.kgPorMetro.message}
        />
      </FormGroup>
      <FormGroup>
        <ControlLabel>Preço Unitário</ControlLabel>
        <InputGroup style={{ width: '120px' }}>
          <InputGroup.Addon>R$</InputGroup.Addon>
          <UncontrolledFormInput errorMessage={form.errors.precoUnitario?.message}>
            <Input
              name="precoUnitario"
              placeholder="ex: 4.80"
              inputRef={form.register({
                required: 'Este campo é necessário',
                validate: value => !isNaN(value) || 'Deve ser um número',
              })}
            />
          </UncontrolledFormInput>
        </InputGroup>
      </FormGroup>
    </>
  );
};

export default InsercaoFerroForm;
