import React, { useContext, useState } from 'react';
import { Button } from 'rsuite';
import AdicaoModal from '../../../components/AdicaoModal';
import { AppContext } from '../../../context';
import ComodoProjeto from '../../../types/comodo-projeto';
import InsercaoComodoForm from './InsercaoComodoForm';

type Props = {
  idProjeto: number,
  refresh(): void,
};

export const LajePadraoContext = React.createContext<number | null>(null);

const AdicaoComodoButton = ({ idProjeto, refresh }: Props) => {
  const [show, setShow] = useState(false);
  const [idLajePadrao, setIdLajePadrao] = useState<number | null>(null);
  const context = useContext(AppContext);
  const repository = context.repositoryFactory.getComodoProjetoRepository(context);

  const handleClose = (doRefresh: boolean = false) => {
    setShow(false);
    if (doRefresh) {
      refresh();
    }
  }

  return (
    <>
      <Button appearance="primary" onClick={() => setShow(true)}>Adicionar Cômodo</Button>
      <LajePadraoContext.Provider value={idLajePadrao}>
        <AdicaoModal
          nome="cômodo"
          show={show}
          onClose={handleClose}
          element={InsercaoComodoForm}
          create={(comodo: ComodoProjeto) => {
            setIdLajePadrao(comodo.idLaje ? comodo.idLaje : null);
            return repository.create(comodo);
          }}
          formToModel={c => c}
          formOptions={{ defaultValues: { idProjeto } }}
        />
      </LajePadraoContext.Provider>
    </>
  );
}

export default AdicaoComodoButton;
