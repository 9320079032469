import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import Despesa from '../../../types/despesa';
import DespesaForm from './DespesaForm';

const EdicaoDespesaForm = () => {
  const { control } = useFormContext<Despesa>();

  return (
    <>
      <Controller name="id" control={control} render={() => <></>} />
      <DespesaForm />
    </>
  );
};

export default EdicaoDespesaForm;
