import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { ControlLabel, FormGroup, Input } from 'rsuite';
import InputProduto from '../../../components/InputProduto';
import UncontrolledFormInput from '../../../components/UncontrolledFormInput';
import { ProdutoOrcamentoForm } from '../../../types/produto';

const AdicaoProdutoForm = () => {
  const form = useFormContext<ProdutoOrcamentoForm>();

  return (
    <>
      <FormGroup>
        <ControlLabel>Produto</ControlLabel>
        <Controller
          name="idProduto"
          defaultValue={null}
          rules={{ required: 'Este campo é necessário' }}
          render={({ value, onChange }) => (
            <UncontrolledFormInput errorMessage={form.errors.idProduto?.message}>
              <InputProduto value={value} onChange={onChange} />
            </UncontrolledFormInput>
          )}
        />
      </FormGroup>
      <FormGroup>
        <ControlLabel>Quantidade</ControlLabel>
        <UncontrolledFormInput errorMessage={form.errors.quantidade?.message}>
          <Input
            autoFocus
            name="quantidade"
            placeholder="ex: 3"
            inputRef={form.register({ required: 'Este campo é necessário' })}
          />
        </UncontrolledFormInput>
      </FormGroup>
    </>
  );
};

export default AdicaoProdutoForm;
