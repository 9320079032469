import React, { useContext, useState } from 'react';
import { Button, Checkbox } from 'rsuite';
import RemocaoModal from '../../../components/RemocaoModal';
import { AppContext } from '../../../context';
import ComodoProjeto from '../../../types/comodo-projeto';
import AlteracaoLajesButton from './AlteracaoLajesButton';

type Props = {
  comodosSelecionados: Set<number>,
  idProjeto: number,
  comodos?: ComodoProjeto[],
  setComodosSelecionados: React.Dispatch<React.SetStateAction<Set<number>>>,
  refresh(): void,
}

const ProjetoToolbar = ({ idProjeto, comodos, comodosSelecionados, setComodosSelecionados, refresh }: Props) => {
  const [showRemocao, setShowRemocao] = useState(false);
  const context = useContext(AppContext);
  const repository = context.repositoryFactory.getComodoProjetoRepository(context);
  
  const size = comodosSelecionados.size;

  let todosSelecionados = false;
  let indeterminado = false;

  if (size === 0) {
    todosSelecionados = false;
  } else if (comodos?.length === size) {
    todosSelecionados = true;
  } else {
    indeterminado = true;
  }

  return (
    <>
      <div className="projeto-toolbar">
        <Checkbox
          checked={todosSelecionados}
          indeterminate={indeterminado}
          onChange={() => {
            if (comodos) {
              if (comodosSelecionados.size === 0) {
                setComodosSelecionados(new Set(comodos.map(c => c.id || 0)))
              } else {
                setComodosSelecionados(new Set([]));
              }
            }
          }}
        >
          ({size}<span className="legenda-projeto-toolbar">{size === 1 ? ' cômodo' : ' cômodos'}</span>)
        </Checkbox>
        <AlteracaoLajesButton idProjeto={idProjeto} comodosSelecionados={comodosSelecionados} refresh={refresh} />
        <Button
          disabled={comodosSelecionados.size === 0}
          appearance="ghost"
          onClick={() => setShowRemocao(true)}
          color="red"
        >Remover</Button>
      </div>
      <RemocaoModal
        show={showRemocao}
        selecionados={Array.from(comodosSelecionados)}
        onClose={doRefresh => {
          setShowRemocao(false);
          if (doRefresh) {
            setComodosSelecionados(new Set([]));
            refresh();
          }
        }}
        deleteMany={repository.deleteMany}
        param={idProjeto}
        nome={["cômodo", "cômodos"]}
      />
    </>
  );
};

export default ProjetoToolbar;
