import React, { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Alert, Button, ControlLabel, Form, FormControl, FormGroup, InputGroup } from 'rsuite';
import { AppContext } from '../../../context';
import Indices from '../../../types/indices';

type Props = {
  indices: Indices,
  refresh(): void,
};

const ProducaoForm = ({ indices, refresh }: Props) => {
  const { register, errors, handleSubmit } = useForm<Indices>({ defaultValues: indices });
  const [submitting, setSubmitting] = useState(false);

  const context = useContext(AppContext);
  const repository = context.repositoryFactory.getProducaoRepository(context);

  const onSubmit = async (values: Indices) => {
    setSubmitting(true);
    const resposta = await repository.atualizar(values);
    if (typeof resposta === 'string') {
      Alert.error(resposta);
    } else if (typeof resposta === 'object') {
      Alert.error('Erro inesperado!');
    } else {
      Alert.success('Valores atualizados!');
      refresh();
    }
    setSubmitting(false);
  };

  return (
    <Form onSubmit={(_c, v) => handleSubmit(onSubmit)(v)}>
      <FormGroup>
        <ControlLabel>Índice de reajuste</ControlLabel>
        <FormControl
          name="reajuste"
          readOnly={submitting}
          placeholder="ex: 0.7"
          inputRef={register({
            required: 'Este campo é necessário',
            validate: value => !isNaN(value) || 'Deve ser um número',
          })}
          errorMessage={errors.reajuste?.message}
        />
      </FormGroup>
      <FormGroup>
        <ControlLabel>Produção mensal</ControlLabel>
        <InputGroup style={{ width: '120px' }}>
          <FormControl
            name="producaoMensal"
            readOnly={submitting}
            placeholder="ex: 5.02"
            inputRef={register({
              required: 'Este campo é necessário',
              validate: value => !isNaN(value) || 'Deve ser um número',
            })}
            errorMessage={errors.producaoMensal?.message}
          />
          <InputGroup.Addon>m²</InputGroup.Addon>
        </InputGroup>
      </FormGroup>
      <Button loading={submitting} appearance="primary" type="submit">Atualizar</Button>
    </Form>
  );
};

export default ProducaoForm;
