import React, { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { ControlLabel, Divider, FormGroup, Input, Radio, RadioGroup } from 'rsuite';
import EnderecoForm from '../../../components/EnderecoForm';
import UncontrolledFormInput from '../../../components/UncontrolledFormInput';
import { OrcamentoForm } from '../../../types/orcamento';

type Props = {
  mostrarEnderecoDireto?: boolean,
};

const GeracaoOrcamentoForm = ({ mostrarEnderecoDireto = false }: Props) => {
  const form = useFormContext<OrcamentoForm>();
  const [enderecoCliente, setEnderecoCliente] = useState(true);

  return (
    <>
      <Controller name="idProjeto" control={form.control} render={() => <></>} />
      <FormGroup>
        <ControlLabel>Observação</ControlLabel>
        <UncontrolledFormInput errorMessage={form.errors.observacao?.message}>
          <Input
            autoFocus
            name="observacao"
            componentClass="textarea"
            rows={3}
            style={{ minWidth: '260px' }}
            placeholder="Observações do orçamento"
            inputRef={form.register}
          />
        </UncontrolledFormInput>
      </FormGroup>
      <FormGroup>
        <ControlLabel>Número da ART</ControlLabel>
        <UncontrolledFormInput errorMessage={form.errors.art?.message}>
          <Input
            name="art"
            placeholder="ex: 999221312353506"
            inputRef={form.register}
          />
        </UncontrolledFormInput>
      </FormGroup>
      <Divider>Endereço de entrega</Divider>
      {!mostrarEnderecoDireto && (
        <FormGroup>
          <RadioGroup onChange={setEnderecoCliente} value={enderecoCliente} inline>
            <Radio value={true}>Usar do cliente</Radio>
            <Radio value={false}>Especificar</Radio>
          </RadioGroup>
        </FormGroup>
      )}
      {(mostrarEnderecoDireto || !enderecoCliente) && (
        <EnderecoForm form={form} prefixo="endereco." errors={form.errors.endereco} />
      )}
    </>
  );
};

export default GeracaoOrcamentoForm;
