import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import Ferro from '../../../types/ferro';
import InsercaoFerroForm from "./InsercaoFerroForm"

const EdicaoFerroForm = () => {
  const { control } = useFormContext<Ferro>();

  return (
    <>
      <Controller name="id" control={control} render={() => <></>} />
      <InsercaoFerroForm />
    </>
  );
};

export default EdicaoFerroForm;
