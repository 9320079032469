import React, { useEffect, useState } from 'react';
import { Button, Checkbox, DatePicker, Icon, Modal } from 'rsuite';
import { ParcelaOrcamento } from '../../../types/orcamento';

type Props = {
  parcelas: Array<ParcelaOrcamento>,
  onChange: (parcela: ParcelaOrcamento) => Promise<void>,
};

const Parcelas = ({ parcelas: parcelasIniciais, onChange }: Props) => {
  const [parcelas, setParcelas] = useState<ParcelaOrcamento[]>(parcelasIniciais);
  const [parcelaEdicao, setParcelaEdicao] = useState<ParcelaOrcamento | null>(null);
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => setParcelas(parcelasIniciais), [parcelasIniciais]);

  return (
    <>
      <table className="parcelas">
        <thead>
          <tr>
            <th>Data</th>
            <th>Valor</th>
            <th>Paga</th>
          </tr>
        </thead>
        <tbody>
          {parcelas.map((parcela, index) => (
            <tr key={index}>
              <td>
                {parcela.data && new Date(parcela.data).toLocaleDateString('pt-BR')}
                <Icon
                  style={{ marginLeft: 8, cursor: 'pointer' }}
                  icon="edit2"
                  onClick={() => setParcelaEdicao(parcela)}
                />
              </td>
              <td>
                R$ {parcela.valor?.toFixed(2)}
              </td>
              <td>
                <Checkbox
                  checked={parcela.paga === 'S'}
                  onChange={(_, checked) => {
                    const novaParcela: ParcelaOrcamento = { ...parcela, paga: checked ? 'S' : 'N' };
                    
                    setParcelas(parcelas => [
                      ...parcelas.slice(0, index),
                      novaParcela,
                      ...parcelas.slice(index + 1, parcelas.length)
                    ]);

                    onChange({ id: novaParcela.id, paga: novaParcela.paga });
                  }}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <Modal overflow={false} size="xs" show={!!parcelaEdicao} backdrop={true} onHide={() => setParcelaEdicao(null)} className="modal-fluid-xs">
        <Modal.Header>
          <Modal.Title>Editar data da parcela</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <DatePicker
            oneTap
            cleanable={false}
            format="DD/MM/YYYY"
            placeholder="dd/mm/aaaa"
            onChange={(date) => {
              if (parcelaEdicao) {
                setParcelaEdicao({ ...parcelaEdicao, data: date.toISOString() });
              }
            }}
            value={parcelaEdicao?.data ? new Date(parcelaEdicao?.data) : undefined}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button appearance="primary" loading={submitting} onClick={async () => {
            if (parcelaEdicao) {
              setSubmitting(true);
              await onChange({ id: parcelaEdicao.id, data: parcelaEdicao.data });
              setSubmitting(false);
              setParcelaEdicao(null);
            }
          }}>Confirmar</Button>
          <Button appearance="subtle" onClick={() => setParcelaEdicao(null)}>Cancelar</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Parcelas;
