import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { Icon, Placeholder } from 'rsuite';
import EdicaoDrawer from '../../components/EdicaoDrawer';
import PageLayout from '../../components/PageLayout';
import { AppContext } from '../../context';
import Usuario, { usuarioFormToModel } from '../../types/usuario';
import AdicaoUsuarioButton from './AdicaoUsuarioButton';
import FormUsuario from './FormUsuario';

const getRoleName = (role: string) => {
  switch (role) {
    case 'A': return 'Administrador';
    case 'G': return 'Gerente';
    default: return 'Usuário';
  }
}

const UsuariosPage = () => {
  const context = useContext(AppContext);
  const repository = context.repositoryFactory.getUsuarioRepository(context);

  const [usuarios, setUsuarios] = useState<Usuario[] | null>(null);
  const [usernameEdicao, setUsernameEdicao] = useState<string | undefined>(undefined);

  const refresh = useCallback(async () => {
    const resposta = await repository.findAll();
    setUsuarios(resposta);
  }, [repository]);

  useEffect(() => {
    refresh();
  }, [refresh]);

  let body;
  if (context.authState.usuario?.role === 'U') {
    body = <Redirect to="/" />;
  } else if (usuarios === null) {
    body = <Placeholder.Paragraph rows={20} active />;
  } else {
    body = (
      <>
        <AdicaoUsuarioButton refresh={refresh} />
        <div className="lista-usuarios">
          {usuarios.map(usuario => (
            <div className="lista-usuarios-item" onClick={() => setUsernameEdicao(usuario.username)}>
              <Icon icon="user" size="4x" style={{ float: 'left', marginRight: '16px', color: 'rgb(66, 135, 245)' }} />
              <aside>
                <p><b>{usuario.username}</b></p>
                <p>{usuario.nome} - {getRoleName(usuario.role)}</p>
              </aside>
            </div>
          ))}
        </div>
        <EdicaoDrawer
          show={!!usernameEdicao}
          id={usernameEdicao}
          onClose={(doRefresh: boolean = false) => {
            setUsernameEdicao(undefined);
            if (doRefresh) {
              refresh();
            }
          }}
          update={repository.update}
          read={repository.read}
          delete={([id]) => repository.delete(id)}
          formToModel={usuarioFormToModel}
          modelToForm={c => c}
          formElement={FormUsuario}
        />
      </>
    );
  }

  return (
    <PageLayout title="Usuários">
      {body}
    </PageLayout>
  );
};

export default UsuariosPage;
