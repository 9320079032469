type Endereco = {
  id?: number,
  logradouro?: string,
  cep?: string,
  numero?: number,
  complemento?: string,
  bairro?: string,
  cidade?: string,
  uf?: string,
};

export const enderecoFormToModel = (form: Endereco): Endereco | undefined => {
  const formFiltered = Object.assign({}, form);
  for (const key in formFiltered) {
    const k = key as keyof Endereco;
    if (!formFiltered[k] || formFiltered[k]?.toString().length === 0) {
      delete formFiltered[k];
    }
  }

  if (Object.entries(formFiltered).length > 0) {
    return {
      ...formFiltered,
      cep: form.cep && form.cep.length > 0
        ? form.cep.replace(/[.-]/g, '')
        : undefined,
      numero: form.numero ? Number(form.numero) : undefined,
    };
  } else {
    return undefined;
  }
}

export default Endereco;
