import React, { useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Icon, IconButton, Message, Placeholder } from 'rsuite';
import EdicaoDrawer from '../../../../components/EdicaoDrawer';
import PageLayout from '../../../../components/PageLayout';
import { AppContext } from '../../../../context';
import { ComposicaoLaje } from '../../../../types/laje';
import useReadModel from '../../../../utils/useReadModel';
import AdicaoComposicaoButton from './AdicaoComposicaoButton';
import ComposicaoForm from './ComposicaoForm';

const ComposicoesPage = () => {
  const idLaje = Number(useParams<{ id: string }>().id);

  const [medidasEdicao, setMedidasEdicao] = useState<[string, string] | undefined>(undefined);

  const context = useContext(AppContext);
  const repository = context.repositoryFactory.getLajeRepository(context);

  const { model: service, refresh } = useReadModel(repository.buscarComposicoes, idLaje);
  const { model: lajeService } = useReadModel(repository.read, idLaje);

  let body;
  if (lajeService.status === 'loading') {
    body = <Placeholder.Paragraph rows={20} active />;
  } else if (lajeService.status === 'error') {
    body = (
      <Message
        showIcon
        type="error"
        title="Erro"
        description={lajeService.error.message}
      />
    );
  } else if (lajeService.status === 'loaded') {
    const laje = lajeService.payload;

    body = (
      <>
        <p><b>Nome da Laje:</b> {laje.nome}</p>
        <p><b>Sobrecarga:</b> {laje.sobrecarga} kg/m²</p>
        <p><b>Nomenclatura:</b> {laje.nomenclatura}</p>
        <br/>
        <AdicaoComposicaoButton idLaje={idLaje} refresh={refresh} />
        <div className="composicoes-laje">
          {service.status === 'loaded' && service.payload.map(composicao => (
            <div className="composicao-laje">
              <span><b>{composicao.medidaInicial} m</b> a <b>{composicao.medidaFinal} m</b></span>
              <IconButton
                appearance="default"
                onClick={() => { setMedidasEdicao([composicao.medidaInicial, composicao.medidaFinal]); }}
                icon={<Icon icon="edit2" />}
              />
            </div>
          ))}
        </div>
        <EdicaoDrawer<ComposicaoLaje>
          show={!!medidasEdicao}
          id={medidasEdicao}
          onClose={(doRefresh: boolean = false) => {
            setMedidasEdicao(undefined);
            if (doRefresh) {
              refresh();
            }
          }}
          update={repository.atualizarComposicao}
          read={([medidaInicial, medidaFinal]) => repository.buscarComposicao(idLaje, medidaInicial, medidaFinal)}
          delete={([[medidaInicial, medidaFinal]]) => repository.removerComposição(idLaje, medidaInicial, medidaFinal)}
          formToModel={c => c}
          modelToForm={c => c}
          formElement={ComposicaoForm}
        />
      </>
    );
  } else {
    body = <span></span>;
  }

  return (
    <PageLayout title={`Composições da Laje #${idLaje}`}>
      {body}
    </PageLayout>
  );
};

export default ComposicoesPage;
