import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { ControlLabel, DatePicker, FormControl, FormGroup, Input, InputGroup } from 'rsuite';
import UncontrolledFormInput from '../../../components/UncontrolledFormInput';
import { FinalizacaoOrcamentoForm } from '../../../types/orcamento';

const FinalizacaoForm = () => {
  const form = useFormContext<FinalizacaoOrcamentoForm>();

  return (
    <>
      <FormGroup>
        <ControlLabel>Desconto</ControlLabel>
        <InputGroup style={{ width: 130 }}>
          <InputGroup.Addon>R$</InputGroup.Addon>
          <UncontrolledFormInput errorMessage={form.errors.desconto?.message}>
            <Input
              autoFocus
              name="desconto"
              placeholder="ex: 372.37"
              inputRef={form.register({
                validate: value => !isNaN(value) || 'Deve ser um número',
              })}
            />
          </UncontrolledFormInput>
        </InputGroup>
      </FormGroup>
      <FormGroup>
        <ControlLabel>Frete</ControlLabel>
        <InputGroup style={{ width: 130 }}>
          <InputGroup.Addon>R$</InputGroup.Addon>
          <UncontrolledFormInput errorMessage={form.errors.frete?.message}>
            <Input
              name="frete"
              placeholder="ex: 120.45"
              defaultValue={'0'}
              inputRef={form.register({
                validate: value => !isNaN(value) || 'Deve ser um número',
              })}
            />
          </UncontrolledFormInput>
        </InputGroup>
      </FormGroup>
      <FormGroup>
        <ControlLabel>Número de parcelas</ControlLabel>
        <UncontrolledFormInput errorMessage={form.errors.numeroParcelas?.message}>
          <Input
            name="numeroParcelas"
            placeholder="ex: 3"
            inputRef={form.register({
              required: 'Este campo é necessário',
              pattern: {
                value: /^[0-9]{1,4}$/,
                message: 'O valor deve ser um número inteiro de 1 a 9999',
              }
            })}
            style={{ width: 130 }}
          />
        </UncontrolledFormInput>
      </FormGroup>
      <FormGroup>
        <ControlLabel>Juros da parcela</ControlLabel>
        <InputGroup style={{ width: 130 }}>
          <UncontrolledFormInput errorMessage={form.errors.jurosParcela?.message}>
            <Input
              name="jurosParcela"
              placeholder="ex: 7%"
              inputRef={form.register({
                validate: value => !isNaN(value) || 'Deve ser um número',
              })}
            />
          </UncontrolledFormInput>
          <InputGroup.Addon>%</InputGroup.Addon>
        </InputGroup>
      </FormGroup>
      <FormGroup>
        <ControlLabel>Data da primeira parcela</ControlLabel>
        <Controller
          name="data"
          control={form.control}
          defaultValue={null}
          rules={{ required: 'Este campo é necessário' }}
          render={({ name, onChange, value }) => (
            <FormControl
              name={name}
              accepter={DatePicker}
              oneTap
              format="DD/MM/YYYY"
              placeholder="dd/mm/aaaa"
              onChange={onChange}
              value={value}
              errorMessage={form.errors.data?.message}
            />
          )}
        />
      </FormGroup>
    </>
  );
};

export default FinalizacaoForm;
