import React, { useContext, useState } from 'react';
import { Icon } from 'rsuite';
import AdicaoModal from '../../../components/AdicaoModal';
import { AppContext } from '../../../context';
import { ProdutoOrcamento, produtoOrcamentoFormToModel } from '../../../types/produto';
import AdicaoProdutoForm from './AdicaoProdutoForm';

type Props = {
  idOrcamento: number,
  refresh(): void,
}

const AdicaoProdutoButton = ({ idOrcamento, refresh }: Props) => {
  const [showModal, setShowModal] = useState(false);
  const context = useContext(AppContext);
  const repository = context.repositoryFactory.getOrcamentoRepository(context);

  return (
    <>
      <button onClick={() => setShowModal(true)} className="botao-item-orcamento">
        <Icon icon="plus" size="lg" />
      </button>
      <AdicaoModal
        stringNovo="Inserir"
        nome="produto"
        show={showModal}
        onClose={(doRefresh = false) => { setShowModal(false); if (doRefresh) refresh() }}
        create={(produto: ProdutoOrcamento) => repository.inserirProdutoNoOrcamento(idOrcamento, produto)}
        formToModel={produtoOrcamentoFormToModel}
        element={AdicaoProdutoForm}
      />
    </>
  );
};

export default AdicaoProdutoButton;
