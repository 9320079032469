const abrirPDF = (blobPart: BlobPart, targetWindow?: Window) => {
  const url = window.URL.createObjectURL(new Blob([blobPart], { type: 'application/pdf' }));
  if (targetWindow) {
    targetWindow.location.href = url;
  } else {
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('target', `__blank`);
    document.body.appendChild(link);
    link.click();
    link.remove();
  }
}

export default abrirPDF;
