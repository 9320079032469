import React from 'react';
import { useFormContext } from 'react-hook-form';
import { ControlLabel, FormControl, FormGroup, Input, InputGroup } from 'rsuite';
import UncontrolledFormInput from '../../../components/UncontrolledFormInput';
import Produto from '../../../types/produto';

const InsercaoProdutoForm = () => {
  const form = useFormContext<Produto>();

  return (
    <>
      <FormGroup>
        <ControlLabel>Nome</ControlLabel>
        <FormControl
          name="nome"
          placeholder="ex: Caixa de luz"
          inputRef={form.register({ required: 'Este campo é necessário' })}
          errorMessage={form.errors.nome && form.errors.nome.message}
        />
      </FormGroup>
      <FormGroup>
        <ControlLabel>Descrição</ControlLabel>
        <UncontrolledFormInput errorMessage={form.errors.descricao?.message}>
          <Input
            name="descricao"
            componentClass="textarea"
            rows={3}
            style={{ minWidth: '260px' }}
            placeholder="Descrição do produto"
            inputRef={form.register({ required: 'Este campo é necessário' })}
          />
        </UncontrolledFormInput>
      </FormGroup>
      <FormGroup>
        <ControlLabel>Valor</ControlLabel>
        <InputGroup style={{ width: '120px' }}>
          <InputGroup.Addon>R$</InputGroup.Addon>
          <FormControl
            name="valor"
            placeholder="ex: 12.75"
            inputRef={form.register({ required: 'Este campo é necessário' })}
            errorMessage={form.errors.valor && form.errors.valor.message}
          />
        </InputGroup>
      </FormGroup>
    </>
  );
};

export default InsercaoProdutoForm;
