import React, { useContext, useRef, useState } from 'react';
import { Table } from 'rsuite';
import AdicaoModal from '../../../components/AdicaoModal';
import BaseTabela from '../../../components/BaseTabela';
import { AdicaoModalFunction, BaseTabelaProps, RemocaoModalFunction } from '../../../components/BaseTabela/BaseTabela';
import EdicaoDrawer from '../../../components/EdicaoDrawer';
import PageLayout from '../../../components/PageLayout';
import RemocaoModal from '../../../components/RemocaoModal';
import { AppContext, AppContextProps } from '../../../context';
import { Pagina } from '../../../types/pagina';
import RequisicaoPagina from '../../../types/requisicao-pagina';
import { AgregadoExibicao } from '../../../types/agregado';
import { AgregadoRepository } from '../../../repositories/interfaces/agregado-repository';
import EdicaoAgregadoForm from './EdicaoAgregadoForm';
import InsercaoAgregadoForm from './InsercaoAgregadoForm';
import MoneyCell from '../../../components/MoneyCell';

class TabelaAgregados extends BaseTabela<AgregadoExibicao> {
  private agregadoRepository: AgregadoRepository;

  constructor(props: BaseTabelaProps, context: AppContextProps) {
    super(props);
    this.agregadoRepository = context.repositoryFactory.getAgregadoRepository(context);
  }

  buscarParaExibicao(requisicao: RequisicaoPagina) : Promise<Pagina<AgregadoExibicao>> {
    return this.agregadoRepository.buscarParaExibicao(requisicao);
  }

  exibirAdicaoModal: AdicaoModalFunction = (show, onClose) => (
    <AdicaoModal
      nome="agregado"
      show={show}
      onClose={onClose}
      element={InsercaoAgregadoForm}
      create={this.agregadoRepository.create}
      formToModel={f => f}
    />
  );

  exibirRemocaoModal: RemocaoModalFunction = (show, selecionados, onClose) => (
    <RemocaoModal
      show={show}
      selecionados={selecionados}
      onClose={onClose}
      deleteMany={this.agregadoRepository.deleteMany}
      nome={["agregado", "agregados"]}
    />
  );
}

const AgregadosPage = () => {
  const [idEdicao, setIdEdicao] = useState<number | undefined>(undefined);
  const [editando, setEditando] = useState(false);

  const context = useContext(AppContext);
  const repository = context.repositoryFactory.getAgregadoRepository(context);

  const ref = useRef<TabelaAgregados>(null);

  return (
    <PageLayout title="Materiais - Agregados">
      <TabelaAgregados
        ref={ref}
        ordenarPorPadrao="nome"
        tipoOrdenacaoPadrao="asc"
        handleEdit={id => { setIdEdicao(id); setEditando(true); }}
      >
        <Table.Column sortable>
          <Table.HeaderCell>Nome</Table.HeaderCell>
          <Table.Cell dataKey="nome" />
        </Table.Column>
        <Table.Column sortable>
          <Table.HeaderCell>Valor</Table.HeaderCell>
          <MoneyCell dataKey="valor" />
        </Table.Column>
      </TabelaAgregados>

      <EdicaoDrawer
        show={editando}
        id={idEdicao}
        onClose={(refresh: boolean = false) => {
          setEditando(false);
          setIdEdicao(undefined);
          if (refresh) {
            ref.current?.refresh();
          }
        }}
        update={repository.update}
        read={repository.read}
        delete={repository.deleteMany}
        formToModel={a => a}
        modelToForm={a => a}
        formElement={EdicaoAgregadoForm}
      />
    </PageLayout>
  );
}

export default AgregadosPage;
