import Axios from "axios";
import Usuario from "../../types/usuario";
import { AuthRepository } from "../interfaces/auth-repository";
import { setContext } from "@sentry/react";

class ApiAuthRepository implements AuthRepository {
  async isAuthenticated(): Promise<Usuario | false> {
    const accessToken = sessionStorage.getItem('accessToken');
    const usuario = sessionStorage.getItem('usuario');

    if (accessToken && usuario) {
      const usuarioParsed = JSON.parse(usuario);

      this.setupSentryContext(usuarioParsed);
      this.setupInterceptors();

      return usuarioParsed;
    } else {
      const refreshToken = localStorage.getItem('refreshToken');
      if (refreshToken) {
        const resposta = await Axios.post('/auth/token', { refreshToken });

        if (resposta.status === 401) {
          localStorage.removeItem('refreshToken');
          return false;
        }

        sessionStorage.setItem('accessToken', resposta.data.accessToken);
        sessionStorage.setItem('usuario', JSON.stringify(resposta.data.usuario));

        this.setupSentryContext(resposta.data.usuario);
        this.setupInterceptors();

        return resposta.data.usuario;
      } else {
        return false;
      }
    }
  }
  
  async authenticate(username: string, senha: string): Promise<Usuario | false> {
    const resposta = await Axios.post('/auth/login', { username, senha });

    if (resposta.status === 200) {
      sessionStorage.setItem('accessToken', resposta.data.accessToken);
      sessionStorage.setItem('usuario', JSON.stringify(resposta.data.usuario));

      localStorage.setItem('refreshToken', resposta.data.refreshToken);

      this.setupSentryContext(resposta.data.usuario);
      this.setupInterceptors();

      return resposta.data.usuario;
    } else if (resposta.status === 401) {
      return false;
    } else {
      throw new Error('Erro inesperado!');
    }
  }

  async logout(): Promise<void> {
    const refreshToken = localStorage.getItem('refreshToken');

    if (refreshToken) {
      localStorage.removeItem('refreshToken');
      sessionStorage.removeItem('accessToken');
      sessionStorage.removeItem('usuario');

      await Axios.post('/auth/logout', { refreshToken });
    }

    return Promise.resolve();
  }

  async updatePassword(password: string, username?: string): Promise<true | string> {
    const resposta = await Axios.put(`/auth/senha`, { username, senha: password });

    if (resposta.status === 200) {
      return true;
    } else {
      return 'Um erro ocorreu';
    }
  }

  setupSentryContext(user: Usuario): void {
    setContext("user", user);
  }

  setupInterceptors(): void {
    Axios.interceptors.request.use(request => {
      const accessToken = sessionStorage.getItem('accessToken');

      if (accessToken) {
        request.headers['Authorization'] = `Bearer ${accessToken}`;
      }

      return request;
    });

    Axios.interceptors.response.use(async response => {
      if (response.config.url === '/auth/token') {
        return response;
      }
      
      if (response.status === 401) {
        const refreshToken = localStorage.getItem('refreshToken');
        if (refreshToken) {
          const resposta = await Axios.post('/auth/token', { refreshToken });

          if (resposta.status === 401) {
            return response;
          }

          sessionStorage.setItem('accessToken', resposta.data.accessToken);
          sessionStorage.setItem('usuario', JSON.stringify(resposta.data.usuario));

          response.config.headers['Authorization'] = `Bearer ${resposta.data.accessToken}`;
          return Axios.request(response.config);
        }
      }

      return response;
    });
  }
}

export default ApiAuthRepository;
