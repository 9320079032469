import React, { useCallback, useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Alert, Button, ControlLabel, Form, FormGroup, Input, InputGroup, Modal } from 'rsuite';
import UncontrolledFormInput from '../../../components/UncontrolledFormInput';
import { AppContext } from '../../../context';
import abrirPDF from '../../../utils/abrirPDF';

type Form = {
  vendedor: string,
  desconto: string,
  frete: string,
};

type Model = {
  vendedor?: string,
  desconto?: number,
  frete?: number,
}

const GerarPDFButton = ({ idOrcamento, pedido }: { idOrcamento: number, pedido: boolean }) => {
  const [showModal, setShowModal] = useState(false);
  const [gerando, setGerando] = useState(false);
  const context = useContext(AppContext);
  const repository = context.repositoryFactory.getOrcamentoRepository(context);

  const gerar = useCallback(async ({ vendedor, desconto, frete }: Model) => {
    const targetWindow = window.open();

    setGerando(true);

    try {
      const resposta = await repository.gerarPDF(idOrcamento, vendedor, desconto, frete);
      abrirPDF(resposta, targetWindow ? targetWindow : undefined);
    } catch (e) {
      targetWindow?.close();
      Alert.error(e.message);
    }

    setShowModal(false);
    setGerando(false);
  }, [repository, idOrcamento]);

  const onSubmit = async (values: Form) => {
    const desconto = values.desconto && values.desconto.length > 0 ? Number(values.desconto) : undefined;
    const frete = values.frete && values.frete.length > 0 ? Number(values.frete) : undefined;

    await gerar({
      vendedor: values.vendedor.length > 0 ? values.vendedor : undefined,
      desconto, frete
    });
  }

  const { register, errors, handleSubmit } = useForm<Form>();

  const handleHide = () => setShowModal(false);

  return (
    <>
      <Button
        appearance={pedido ? "primary" : "subtle"}
        loading={gerando}
        onClick={() => setShowModal(true)}
      >Gerar PDF</Button>
      <Modal overflow={false} size="xs" show={showModal} backdrop={true} onHide={handleHide} className="modal-fluid-xs">
        <Form onSubmit={(_c, v) => handleSubmit(onSubmit)(v)}>
          <Modal.Header>
            <Modal.Title>Gerar PDF do {pedido ? 'pedido' : 'orçamento'}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <FormGroup>
              <ControlLabel>Vendedor</ControlLabel>
              <UncontrolledFormInput errorMessage={errors.vendedor?.message}>
                <Input
                  autoFocus
                  name="vendedor"
                  placeholder="ex: Vanderlei"
                  inputRef={register}
                />
              </UncontrolledFormInput>
            </FormGroup>
            {!pedido && (
              <>
                <FormGroup>
                  <ControlLabel>Desconto</ControlLabel>
                  <InputGroup style={{ width: 130 }}>
                    <InputGroup.Addon>R$</InputGroup.Addon>
                    <UncontrolledFormInput errorMessage={errors.desconto?.message}>
                      <Input
                        name="desconto"
                        placeholder="ex: 372.37"
                        defaultValue={'0'}
                        inputRef={register({
                          validate: value => !isNaN(value) || 'Deve ser um número',
                        })}
                      />
                    </UncontrolledFormInput>
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <ControlLabel>Frete</ControlLabel>
                  <InputGroup style={{ width: 130 }}>
                    <InputGroup.Addon>R$</InputGroup.Addon>
                    <UncontrolledFormInput errorMessage={errors.frete?.message}>
                      <Input
                        name="frete"
                        placeholder="ex: 120.45"
                        defaultValue={'0'}
                        inputRef={register({
                          validate: value => !isNaN(value) || 'Deve ser um número',
                        })}
                      />
                    </UncontrolledFormInput>
                  </InputGroup>
                </FormGroup>
              </>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button appearance="primary" type="submit" loading={gerando}>Confirmar</Button>
            <Button appearance="subtle" onClick={handleHide}>Cancelar</Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
}

export default GerarPDFButton;
