import Axios from "axios";
import { Pagina } from "../../types/pagina";
import Produto from "../../types/produto";
import RequisicaoPagina from "../../types/requisicao-pagina";
import { ProdutoRepository } from "../interfaces/produto-repository";

class ApiProdutoRepository implements ProdutoRepository {
  findAll(): Promise<Produto[]> {
    throw new Error("Method not implemented.");
  }
  
  async buscarParaExibicao(requisicao: RequisicaoPagina): Promise<Pagina<Produto>> {
    const resposta = await Axios.get(
      '/produtos/exibicao',
      { params: requisicao },
    );

    if (resposta.status === 200) {
      return resposta.data;
    } else if (resposta.status === 204) {
      return {
        total: 0,
        pagina: [],
      }
    }

    throw new Error(`Falha ao buscar! O servidor respondeu com ${resposta.status}: ${resposta.data.message}`);
  }

  async buscarParaSelecao(busca: string, pagina: number): Promise<Pagina<Produto>> {
    const resposta = await Axios.get('/produtos/selecao', { params: { busca, pagina} });

    if (resposta.status === 200) {
      return resposta.data;
    } else if (resposta.status === 204) {
      return { total: 0, pagina: [] };
    } else {
      throw new Error(`Falha ao buscar! O servidor respondeu com ${resposta.status}: ${resposta.data.message}`);
    }
  }

  async create(produto: Produto): Promise<string | true | object> {
    const resposta = await Axios.post('/produtos', produto);

    if (resposta.status === 200) {
      return true;
    } else if (resposta.status === 500) {
      return resposta.data.message;
    } else if (resposta.status === 400) {
      return resposta.data;
    }

    return 'Um erro inseperado ocorreu!';
  }

  async read(id: number): Promise<Produto | null> {
    const resposta = await Axios.get<Produto>(`/produtos/${id}`);

    if (resposta.status === 200) {
      return resposta.data;
    }

    return null;
  }

  async update(produto: Produto): Promise<string | true | object> {
    const resposta = await Axios.put('/produtos', produto);

    if (resposta.status === 200) {
      return true;
    } else if (resposta.status === 500) {
      return resposta.data.message;
    } else if (resposta.status === 400) {
      return resposta.data;
    }

    return 'Um erro inseperado ocorreu!';
  }

  async delete(id: number): Promise<boolean> {
    const resposta = await Axios.delete('/produtos', {
      params: { ids: id }
    });

    return resposta.status === 200;
  }

  async deleteMany(ids: number[]): Promise<boolean> {
    const resposta = await Axios.delete('/produtos', {
      params: { ids: ids.join(',') }
    });

    return resposta.status === 200;
  } 
}

export default ApiProdutoRepository;
