import { RouteComponentProps } from "react-router-dom";
import { IconNames } from "rsuite/lib/Icon";
import { ClientesRouter } from "./pages/clientes";
import { FinanceiroRouter } from "./pages/financeiro";
import { HomePage } from "./pages/HomePage";
import { MateriaisRouter } from "./pages/materiais";
import { OrcamentosRouter } from "./pages/orcamentos";
import { ProdutosRouter } from "./pages/produtos";
import { ProjetosRouter } from "./pages/projetos";
import UsuariosPage from "./pages/usuarios/UsuariosPage";

interface SubRouteDefinition {
  name: string,
  path: string,
  exact: boolean,
}

interface RouteDefinition {
  name: string,
  path: string,
  exact: boolean,
  show?: boolean,
  component: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>,
  icon: IconNames,
  subroutes?: Array<SubRouteDefinition>,
  onlyToRoles?: Array<string>,
}

const routes: Array<RouteDefinition> = [
  {
    name: 'Início',
    path: '/',
    exact: true,
    component: HomePage,
    icon: 'dashboard',
  },
  {
    name: 'Clientes',
    path: '/clientes',
    exact: false,
    component: ClientesRouter,
    icon: 'profile',
  },
  {
    name: 'Materiais',
    path: '/materiais',
    exact: false,
    component: MateriaisRouter,
    icon: 'cubes',
    subroutes: [
      {
        name: 'Ferro',
        path: '/materiais/ferro',
        exact: false,
      },
      {
        name: 'Agregados',
        path: '/materiais/agregados',
        exact: false,
      },
      {
        name: 'Lajotas',
        path: '/materiais/lajotas',
        exact: false,
      },
      {
        name: 'Armações',
        path: '/materiais/armacoes',
        exact: false,
      }
    ]
  },
  {
    name: 'Produtos',
    path: '/produtos',
    exact: false,
    component: ProdutosRouter,
    icon: 'cube',
    subroutes: [
      {
        name: 'Lajes',
        path: '/produtos/lajes',
        exact: false,
      },
      {
        name: 'Geral',
        path: '/produtos/geral',
        exact: false,
      }
    ]
  },
  {
    name: 'Projetos',
    path: '/projetos',
    exact: false,
    component: ProjetosRouter,
    icon: 'order-form',
  },
  {
    name: 'Orçamentos',
    path: '/orcamentos',
    exact: false,
    component: OrcamentosRouter,
    icon: 'usd',
  },
  {
    name: 'Financeiro',
    path: '/financeiro',
    exact: false,
    component: FinanceiroRouter,
    icon: 'line-chart',
    subroutes: [
      {
        name: 'Despesas',
        path: '/financeiro/despesas',
        exact: false,
      },
      {
        name: 'Produção',
        path: '/financeiro/producao',
        exact: true,
      }
    ]
  },
  {
    name: 'Usuários',
    path: '/usuarios',
    exact: true,
    component: UsuariosPage,
    icon: 'user',
    onlyToRoles: ['A', 'G'],
  },
];

export default routes;
