import { useCallback, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Alert } from 'rsuite';
import { CallbackAndFormOptions } from '../types/callback-and-form-options';
import { UpdateFunction } from '../types/crud-functions';
import { FormToModel } from '../types/form-model-conversions';
import setFormErrors from './setFormErrors';

const useEdicaoForm = <T, U>(props: CallbackAndFormOptions<U> & { update: UpdateFunction<T>, formToModel: FormToModel<T, U> }) => {
  const { formOptions, callback, formToModel, update } = props;
  
  const methods = useForm<U>(formOptions);
  const [submitting, setSubmitting] = useState(false);

  const { setError } = methods;

  const onSubmit: SubmitHandler<U> = useCallback(async (values) => {
    let submitResult = false;
    let model = formToModel(values as U);
    setSubmitting(true);

    if (model) {
      const resposta = await update(model);
      
      if (resposta === true) {
        Alert.success('Atualizado com sucesso!');
        submitResult = true;
      } else {
        if (typeof resposta === 'string') {
          Alert.error(`Falha ao atualizar: ${resposta}`);
        } else if (typeof resposta === 'object') {
          setFormErrors(setError, resposta, "");
        }
      } 
    }

    setSubmitting(false);

    if (callback) {
      callback(submitResult);
    }
  }, [callback, setError, update, formToModel]);

  return { ...methods, onSubmit: methods.handleSubmit(onSubmit), submitting };
};

export default useEdicaoForm;
