import React from 'react';
import { useFormContext } from 'react-hook-form';
import { ControlLabel, FormControl, FormGroup, InputGroup } from 'rsuite';
import Armacao from '../../../types/armacao';

const InsercaoArmacaoForm = () => {
  const form = useFormContext<Armacao>();

  return (
    <>
      <FormGroup>
        <ControlLabel>Nome</ControlLabel>
        <FormControl
          autoFocus
          name="nome"
          placeholder="ex: TR 12645"
          inputRef={form.register({ required: 'Este campo é necessário' })}
          errorMessage={form.errors.nome && form.errors.nome.message}
        />
      </FormGroup>
      <FormGroup>
        <ControlLabel>Superior</ControlLabel>
        <FormControl
          name="superior"
          placeholder="ex: 7"
          inputRef={form.register({ required: 'Este campo é necessário' })}
          errorMessage={form.errors.superior && form.errors.superior.message}
        />
      </FormGroup>
      <FormGroup>
        <ControlLabel>Sinusoide</ControlLabel>
        <FormControl
          name="sinusoide"
          placeholder="ex: 4.2"
          inputRef={form.register({ required: 'Este campo é necessário' })}
          errorMessage={form.errors.sinusoide && form.errors.sinusoide.message}
        />
      </FormGroup>
      <FormGroup>
        <ControlLabel>Inferior</ControlLabel>
        <FormControl
          name="inferior"
          placeholder="ex: 5"
          inputRef={form.register({ required: 'Este campo é necessário' })}
          errorMessage={form.errors.inferior && form.errors.inferior.message}
        />
      </FormGroup>
      <FormGroup>
        <ControlLabel>Valor por metro</ControlLabel>
        <InputGroup style={{ width: '120px' }}>
          <InputGroup.Addon>R$</InputGroup.Addon>
          <FormControl
            name="precoPorMetro"
            placeholder="ex: 5.02"
            inputRef={form.register({ required: 'Este campo é necessário' })}
            errorMessage={form.errors.precoPorMetro && form.errors.precoPorMetro.message}
          />
        </InputGroup>
      </FormGroup>
    </>
  );
};

export default InsercaoArmacaoForm;
