import Axios from "axios";
import { Pagina } from "../../types/pagina";
import Projeto, { ProjetoConsulta, ProjetoExibicao } from "../../types/projeto";
import RequisicaoPagina from "../../types/requisicao-pagina";
import { ProjetoRepository } from "../interfaces/projeto-repository";

class ApiProjetoRepository implements ProjetoRepository {
  findAll(): Promise<Projeto[]> {
    throw new Error("Method not implemented.");
  }
  async buscarParaExibicao(requisicao: RequisicaoPagina): Promise<Pagina<ProjetoExibicao>> {
    const resposta = await Axios.get(
      '/projetos/exibicao',
      { params: requisicao },
    );

    if (resposta.status === 200) {
      return resposta.data;
    } else if (resposta.status === 204) {
      return {
        total: 0,
        pagina: [],
      }
    }

    throw new Error(`Falha ao buscar! O servidor respondeu com ${resposta.status}: ${resposta.data.message}`);
  }

  async consultar(id: number): Promise<ProjetoConsulta> {
    const resposta = await Axios.get(`/projetos/consulta/${id}`);

    if (resposta.status === 200) {
      return resposta.data;
    } else {
      throw new Error(`Um erro inesperado ocorreu: ${resposta.data.message}`);
    }
  }

  async gerarPDF(id: number, tipo: "P" | "R", data?: string, informacoes?: string): Promise<BlobPart> {
    const resposta = await Axios.post(`/projetos/${id}/pdf`, { data, tipo, informacoes }, { responseType: 'blob' });
    
    if (resposta.status === 200) {
      return resposta.data;
    } else if (resposta.status === 400 || resposta.status === 500) {
      const text = await resposta.data.text();
      const json = JSON.parse(text);
      throw new Error(json.message);
    }

    throw new Error('Um erro inesperado ocorreu!');
  }

  async create(projeto: Projeto): Promise<number | string | object> {
    const resposta = await Axios.post('/projetos', projeto);

    if (resposta.status === 200) {
      return resposta.data.idProjeto;
    } else if (resposta.status === 500) {
      return resposta.data.message;
    } else if (resposta.status === 400) {
      return resposta.data;
    }

    return 'Erro inesperado!';
  }

  async read(id: number): Promise<Projeto | null> {
    const resposta = await Axios.get(`/projetos/${id}`);

    if (resposta.status === 200) {
      return resposta.data;
    } else {
      return null;
    }
  }

  async update(projeto: Projeto): Promise<true | string | object> {
    const resposta = await Axios.put('/projetos', projeto);

    if (resposta.status === 200) {
      return true;
    } else if (resposta.status === 500) {
      return resposta.data.message;
    } else if (resposta.status === 400) {
      return resposta.data;
    }

    return 'Erro inesperado!';
  }

  delete(id: number): Promise<boolean> {
    throw new Error("Method not implemented.");
  }

  async deleteMany(ids: number[]): Promise<boolean> {
    const resposta = await Axios.delete('/projetos', {
      params: { ids: ids.join(',') }
    });

    return resposta.status === 200;
  }
}

export default ApiProjetoRepository;
