import React from 'react';
import MaskedInput from 'react-text-mask';

const InputMask = ({ onChange, ...rest }: any) => (
  <MaskedInput
    {...rest}
    className="rs-input"
    onChange={event => onChange(event.target.value)}
  />
);

export default InputMask;