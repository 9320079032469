import React, { useContext } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Alert, Icon, IconButton, Message, Placeholder } from 'rsuite';
import PageLayout from '../../../components/PageLayout';
import { AppContext } from '../../../context';
import { formatCep, formatPhone } from '../../../utils/formatters';
import useReadModel from '../../../utils/useReadModel';
import AdicaoProdutoButton from './AdicaoProdutoButton';
import EdicaoOrcamentoButton from './EdicaoOrcamentoButton';
import FinalizacaoButton from './FinalizacaoButton';
import GerarPDFButton from './GerarPDFButton';
import Parcelas from './Parcelas';
import RemocaoOrcamentoButton from './RemocaoOrcamentoButton';
import SimulacaoParcelas from './SimulacaoParcelas';

const OrcamentoPage = () => {
  const id = Number(useParams<{ id: string }>().id);

  const context = useContext(AppContext);
  const repository = context.repositoryFactory.getOrcamentoRepository(context);

  const { model: orcamentoService, refresh } = useReadModel(repository.read, id);

  let body;
  if (orcamentoService.status === 'loading') {
    body = <Placeholder.Paragraph rows={20} active />;
  } else if (orcamentoService.status === 'error') {
    body = (
      <Message
        showIcon
        type="error"
        title="Erro"
        description={orcamentoService.error.message}
      />
    );
  } else if (orcamentoService.status === 'loaded') {
    const orcamento = orcamentoService.payload;

    const data = new Date(orcamento.dataCadastro || Date.now());
    const dataFormatada = data.toLocaleString('pt-BR');
    const { logradouro, numero, complemento, bairro, cidade, uf, cep } = orcamento.endereco;
    const cepFormatado = cep ? `, ${formatCep(cep)}` : '';

    body = (
      <>
        <div className="header-orcamento">
          <aside>
            <p><b>Cliente:</b> {orcamento.nomeCliente} {orcamento.idProjeto && <>- <Link to={`/projetos/${orcamento.idProjeto}`}>Projeto #{orcamento.idProjeto}</Link></>}</p>
            {orcamento.telefoneCliente && (
              <p><b>Telefone:</b> {formatPhone(orcamento.telefoneCliente)}</p>
            )}
            <p><b>Data:</b> {dataFormatada}</p>
            {orcamento.art && <p><b>ART:</b> {orcamento.art}</p>}
          </aside>
          <aside>
            <p>
              <b>Endereço de entrega</b><br/>
              {logradouro}{logradouro && numero ? `, ${numero}` : ''}<br/>
              {complemento && <>{complemento}<br/></>}
              {bairro}{bairro && cidade ? ', ' : ''}{cidade}{cidade && uf ? ` - ${uf}` : ''}{cepFormatado}
            </p>
          </aside>
          <aside className="header-orcamento-botoes">
            <RemocaoOrcamentoButton idOrcamento={id} idProjeto={orcamento.idProjeto} />
            <GerarPDFButton idOrcamento={id} pedido={orcamento.venda === "S"} />
            {orcamento.venda === 'N' && (
              <>
                <EdicaoOrcamentoButton orcamento={orcamento} refresh={refresh} />
                <FinalizacaoButton idOrcamento={id} refresh={refresh} />
              </>
            )}
          </aside>
        </div>

        <div className="orcamento">
          <aside className="itens-orcamento">
            {orcamento.produtos?.map(item => (
              <div key={item.id} className="item-orcamento">
                <aside className="item-orcamento-esquerda">
                  <span className="item-orcamento-nome">
                    {orcamento.venda === "N" && item.tipo === "convencional" && (
                      <IconButton
                        style={{ marginRight: '5px' }}
                        icon={<Icon icon="close" />}
                        appearance="subtle"
                        onClick={async () => {
                          const resposta = await repository
                              .removerProdutoDoOrcamento(orcamento.id || 0, item.id);
                          if (resposta === true) {
                            refresh();
                          } else {
                            Alert.error(resposta);
                          }
                        }}
                      />
                    )}
                    {item.nome}
                  </span>
                  <div className="item-orcamento-valores">
                    <div className="item-orcamento-valor">
                      <span>{item.tipo === 'laje' ? 'Valor/m²' : 'Preço unitário'}</span>
                      <span className="item-orcamento-valor-destacado">
                        R$ {(+item.valor).toFixed(2)}
                      </span>
                    </div>
                    <div className="item-orcamento-valor">
                      <span>{item.tipo === 'laje' ? 'Área (m²)' : 'Quantidade'}</span>
                      <span className="item-orcamento-valor-destacado">
                        <Icon icon="clone" style={{ marginRight: '6px' }} /> {item.tipo === 'laje' ? item.quantidade.toFixed(2) : item.quantidade}
                      </span>
                    </div>
                  </div>
                </aside>
                <aside className="item-orcamento-direita">
                  <span>Valor total</span>
                  <span className="item-orcamento-valor-total">
                    R$ {(+item.valorTotal).toFixed(2)}
                  </span>
                </aside>
              </div>
            ))}
            {orcamento.venda === "N" && (
              <AdicaoProdutoButton idOrcamento={orcamento.id || 0} refresh={refresh} />
            )}
          </aside>

          <aside className="valores-orcamento">
            Valor total<br/>
            <h4 style={{ marginBottom: '16px' }}>R$ {orcamento.valorFinal?.toFixed(2)}</h4>
            Custo de produção<br/>
            <h5 style={{ marginBottom: '20px' }}>R$ {orcamento.valorProducao?.toFixed(2)}</h5>

            <h5 style={{ marginBottom: '10px' }}>Pagamento</h5>
            {orcamento.venda === 'S' ? (
              <>
                <p>Desconto: R$ {(orcamento.desconto || 0).toFixed(2)}</p>
                <p>Frete: R$ {(orcamento.frete || 0).toFixed(2)}</p>
                <p>Taxa de juros: {((orcamento.jurosParcela || 0) * 100).toFixed(2)}%</p>
                <p>Total: R$ {orcamento.valorPagamento.toFixed(2)}</p>
                <Parcelas
                  parcelas={orcamento.parcelas}
                  onChange={async (parcela) => {
                    const resposta = await repository.atualizarParcela(id, parcela);
                    if (!resposta) {
                      Alert.error('Um erro inesperado ocorreu!');
                    } else {
                      refresh();
                    }
                  }}
                />
              </>
            ) : (
              <SimulacaoParcelas valorFinal={orcamento.valorFinal} />
            )}
          </aside>
        </div>
      </>
    )
  } else {
    body = <span></span>;
  }

  return (
    <PageLayout title="Orçamento">
      {body}
    </PageLayout>
  );
}

export default OrcamentoPage;
