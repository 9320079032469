import React from 'react';
import { useFormContext } from 'react-hook-form';
import { ControlLabel, FormControl, FormGroup, Input, InputGroup } from 'rsuite';
import UncontrolledFormInput from '../../../components/UncontrolledFormInput';
import Agregado from '../../../types/agregado';

const InsercaoAgregadoForm = () => {
  const form = useFormContext<Agregado>();

  return (
    <>
      <FormGroup>
        <ControlLabel>Nome</ControlLabel>
        <FormControl
          autoFocus
          name="nome"
          placeholder="ex: Cimento"
          inputRef={form.register({ required: 'Este campo é necessário' })}
          errorMessage={form.errors.nome?.message}
        />
      </FormGroup>
      <FormGroup>
        <ControlLabel>Unidade de Medida</ControlLabel>
        <FormControl
          name="unidadeMedida"
          placeholder="ex: SC"
          inputRef={form.register({ required: 'Este campo é necessário' })}
          errorMessage={form.errors.unidadeMedida?.message}
        />
      </FormGroup>
      <FormGroup>
        <ControlLabel>Valor</ControlLabel>
        <InputGroup style={{ width: '120px' }}>
          <InputGroup.Addon>R$</InputGroup.Addon>
          <UncontrolledFormInput errorMessage={form.errors.valor?.message}>
            <Input
              name="valor"
              placeholder="ex: 1.30"
              inputRef={form.register({
                required: 'Este campo é necessário',
                validate: value => !isNaN(value) || 'Deve ser um número',
              })}
            />
          </UncontrolledFormInput>
        </InputGroup>
      </FormGroup>
      <FormGroup>
        <ControlLabel>Quantidade por Metro</ControlLabel>
        <FormControl
          name="qtdPorMetroLinear"
          placeholder="ex: 0.0035"
          inputRef={form.register({ required: 'Este campo é necessário' })}
          errorMessage={form.errors.qtdPorMetroLinear?.message}
        />
      </FormGroup>
    </>
  );
};

export default InsercaoAgregadoForm;
