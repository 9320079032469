import Axios from "axios";
import Laje, { LajeSelecao, LajeExibicao, ComposicaoLaje } from "../../types/laje";
import { Pagina } from "../../types/pagina";
import RequisicaoPagina from "../../types/requisicao-pagina";
import { LajeRepository } from "../interfaces/laje-repository";

class ApiLajeRepository implements LajeRepository {
  findAll(): Promise<Laje[]> {
    throw new Error("Method not implemented.");
  }

  async buscarParaExibicao(requisicao: RequisicaoPagina): Promise<Pagina<LajeExibicao>> {
    const resposta = await Axios.get(
      '/lajes/exibicao',
      { params: requisicao },
    );

    if (resposta.status === 200) {
      return resposta.data;
    } else if (resposta.status === 204) {
      return {
        total: 0,
        pagina: [],
      }
    }

    throw new Error(`Falha ao buscar! O servidor respondeu com ${resposta.status}: ${resposta.data.message}`);
  }

  async buscarParaSelecao(busca: string, pagina: number): Promise<Pagina<LajeSelecao>> {
    const resposta = await Axios.get('/lajes/selecao', { params: { busca, pagina} });

    if (resposta.status === 200) {
      return resposta.data;
    } else if (resposta.status === 204) {
      return { total: 0, pagina: [] };
    } else {
      throw new Error(`Falha ao buscar! O servidor respondeu com ${resposta.status}: ${resposta.data.message}`);
    }
  }

  async create(laje: Laje): Promise<true | object | string> {
    const resposta = await Axios.post('/lajes', laje);

    if (resposta.status === 200) {
      return true;
    } else if (resposta.status === 500) {
      return resposta.data.message;
    } else if (resposta.status === 400) {
      return resposta.data;
    }

    return 'Um erro inseperado ocorreu!';
  }

  async read(id: number): Promise<Laje | null> {
    const resposta = await Axios.get<Laje>(`/lajes/${id}`);

    if (resposta.status === 200) {
      return resposta.data;
    }

    return null;
  }

  async update(laje: Laje): Promise<true | string | object> {
    const resposta = await Axios.put('/lajes', laje);

    if (resposta.status === 200) {
      return true;
    } else if (resposta.status === 500) {
      return resposta.data.message;
    } else if (resposta.status === 400) {
      return resposta.data;
    }

    return 'Um erro inseperado ocorreu!';
  }

  delete(id: number): Promise<boolean> {
    throw new Error("Method not implemented.");
  }
  
  async deleteMany(ids: number[]): Promise<boolean> {
    const resposta = await Axios.delete('/lajes', {
      params: { ids: ids.join(',') }
    });

    return resposta.status === 200;
  }

  async buscarComposicoes(idLaje: number): Promise<ComposicaoLaje[]> {
    const resposta = await Axios.get(`/lajes/${idLaje}/composicoes`);

    if (resposta.status === 200) {
      return resposta.data;
    } else {
      return [];
    }
  }

  async buscarComposicao(idLaje: number, medidaInicial: string, medidaFinal: string): Promise<ComposicaoLaje | null> {
    const resposta = await Axios.get(`/lajes/${idLaje}/composicao`, {
      params: { medidaInicial, medidaFinal }
    });

    if (resposta.status === 200) {
      return resposta.data;
    }

    return null;
  }

  async removerComposição(idLaje: number, medidaInicial: string, medidaFinal: string): Promise<boolean> {
    const resposta = await Axios.delete(`/lajes/${idLaje}/composicao`, {
      params: { medidaInicial, medidaFinal }
    });

    return resposta.status === 200;
  }

  async inserirComposicao(composicao: ComposicaoLaje): Promise<true | string | object> {
    const resposta = await Axios.post(`/lajes/composicoes`, composicao);

    if (resposta.status === 200) {
      return true;
    } else if (resposta.status === 400) {
      return resposta.data;
    } else if (resposta.status === 500) {
      return resposta.data.message;
    }

    return 'Um erro inesperado ocorreu!';
  }

  async atualizarComposicao(composicao: ComposicaoLaje): Promise<true | string | object> {
    const resposta = await Axios.put(`/lajes/composicoes`, composicao);

    if (resposta.status === 200) {
      return true;
    } else if (resposta.status === 400) {
      return resposta.data;
    } else if (resposta.status === 500) {
      return resposta.data.message;
    }

    return 'Um erro inesperado ocorreu!';
  }
}

export default ApiLajeRepository;
