import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { AppContextProvider } from './context';
import { ApiRepositoryFactory } from './repositories/api/repository-factory';
import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: "https://062ca6c0911543dd99c36feb215a1390@o389883.ingest.sentry.io/5479374",
  tracesSampleRate: 0,
});

ReactDOM.render(
  <React.StrictMode>
    <AppContextProvider repositoryFactory={new ApiRepositoryFactory()}>
      <App />
    </AppContextProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
