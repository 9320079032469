import React from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { ControlLabel, FormGroup, Radio, RadioGroup } from 'rsuite';
import { ClienteForm } from '../../types/cliente';
import InsercaoEmpresaForm from './InsercaoEmpresaForm';
import InsercaoPessoaForm from './InsercaoPessoaForm';

const InsercaoForm = ({tipo}: {tipo: string}) => {
  if (tipo === "pessoa") {
    return <InsercaoPessoaForm />;
  } else if (tipo === "empresa") {
    return <InsercaoEmpresaForm />;
  } else {
    return <span></span>;
  }
}

const InsercaoClienteForm = () => {
  const { control } = useFormContext<ClienteForm>();
  const watchTipo = useWatch({ control: control, name: "tipo", defaultValue: "none" });

  return (
    <>
      <FormGroup>
        <ControlLabel>Tipo</ControlLabel>
        <Controller
          defaultValue="none"
          control={control}
          name="tipo"
          render={({ onChange, value, name }) => (
            <RadioGroup onChange={onChange} value={value} name={name} inline>
              <Radio value="pessoa">Pessoa Física</Radio>
              <Radio value="empresa">Pessoa Jurídica</Radio>
            </RadioGroup>
          )}
        />
      </FormGroup>
      <InsercaoForm tipo={watchTipo} />
    </>
  );
}

export default InsercaoClienteForm;
