import React, { useContext } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { Icon, IconButton, Message, Placeholder } from 'rsuite';
import PageLayout from '../../../../components/PageLayout';
import { AppContext } from '../../../../context';
import useReadModel from '../../../../utils/useReadModel';

const OrcamentosPage = () => {
  const idProjeto = Number(useParams<{ idProjeto: string }>().idProjeto);
  const history = useHistory();
  const context = useContext(AppContext);
  const repository = context.repositoryFactory.getOrcamentoRepository(context);
  const { model: service } = useReadModel(repository.buscarOrcamentosDoProjeto, idProjeto);

  let body;
  if (service.status === 'loading') {
    body = <Placeholder.Paragraph rows={20} active />;
  } else if (service.status === 'error') {
    body = (
      <Message
        showIcon
        type="error"
        title="Erro"
        description={service.error.message}
      />
    );
  } else if (service.status === 'loaded') {
    const orcamentos = service.payload;

    body = (
      <div className="orcamentos-projeto">
        {orcamentos.map(orcamento => (
          <Link to={`/orcamentos/${orcamento.id}`} className="orcamento-projeto" key={orcamento.id}>
            <span className="titulo-orcamento-projeto">Orçamento #{orcamento.id}</span>
            <span className="campo-orcamento-projeto">R$ {orcamento.valorFinal.toFixed(2)}</span>
            <span className="campo-orcamento-projeto">Venda: {orcamento.venda === 'S' ? 'Sim' : 'Não'}</span>
            <span className="data-orcamento-projeto">{new Date(orcamento.dataCadastro).toLocaleString('pt-BR')}</span>
          </Link>
        ))}
      </div>
    );
  } else {
    body = <span></span>;
  }

  return (
    <PageLayout title={`Orçamentos do projeto #${idProjeto}`}>
      <IconButton
        icon={<Icon icon="left" />}
        onClick={() => history.push(`/projetos/${idProjeto}`)}
        style={{ marginBottom: '16px' }}
      >Voltar ao projeto</IconButton>
      {body}
    </PageLayout>
  );
}

export default OrcamentosPage;
