import React, { useState } from 'react';
import { Icon, IconButton } from 'rsuite';

type OnCloseCallback = (doRefresh: boolean) => void;

type Props = {
  selecionados: Array<number>,
  onDelete(): void,
  children(show: boolean, selecionados: Array<number>, onClose: OnCloseCallback): React.ReactNode,
};

const RemocaoButton = ({ selecionados, onDelete, children }: Props) => {
  const [show, setShow] = useState(false);

  const handleClose = (refresh: boolean) => {
    setShow(false);
    if (refresh) onDelete();
  }

  const desabilitado = selecionados.length === 0;

  return (
    <>
      <IconButton
        disabled={desabilitado}
        className="remocao-button"
        appearance="primary"
        icon={<Icon icon="trash"/>}
        color="red"
        onClick={() => setShow(true)}
        style={{ opacity: selecionados.length > 0 ? 1 : 0 }}
      >Remover ({selecionados.length})</IconButton>

      {children(show, selecionados, handleClose)}
    </>
  );
};

export default RemocaoButton;
