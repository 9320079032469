import React, { useContext, useRef, useState } from 'react';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { Table } from 'rsuite';
import AdicaoModal, { AdicaoModalProps } from '../../components/AdicaoModal';
import BaseTabela from '../../components/BaseTabela';
import { AdicaoModalFunction, BaseTabelaProps, RemocaoModalFunction } from '../../components/BaseTabela/BaseTabela';
import NameCell from '../../components/NameCell';
import PageLayout from '../../components/PageLayout';
import { AppContext, AppContextProps } from '../../context';
import { Pagina } from '../../types/pagina';
import { ProjetoExibicao } from '../../types/projeto';
import RequisicaoPagina from '../../types/requisicao-pagina';
import { ProjetoRepository } from '../../repositories/interfaces/projeto-repository';
import InsercaoProjetoForm from './InsercaoProjetoForm';
import EdicaoDrawer from '../../components/EdicaoDrawer';
import EdicaoProjetoForm from './EdicaoProjetoForm';
import RemocaoModal from '../../components/RemocaoModal';
import ActionCell from '../../components/ActionCell';
import DateCell from '../../components/DateCell';


function ProjetoAdicaoModal<T, U = T>(props: AdicaoModalProps<T, U>) {
  const history = useHistory();

  return (
    <AdicaoModal
      {...props}
      onClose={(response: number | boolean) => {
        if (typeof response === 'number') {
          history.push(`/projetos/${response}`);
        } else {
          props.onClose(response);
        }
      }}
    />
  );
}

class TabelaProjetos extends BaseTabela<ProjetoExibicao> {
  private projetoRepository: ProjetoRepository;

  constructor(props: BaseTabelaProps & RouteComponentProps, context: AppContextProps) {
    super(props);
    this.projetoRepository = context.repositoryFactory.getProjetoRepository(context);
  }

  buscarParaExibicao(requisicao: RequisicaoPagina) : Promise<Pagina<ProjetoExibicao>> {
    return this.projetoRepository.buscarParaExibicao(requisicao);
  }

  exibirAdicaoModal: AdicaoModalFunction = (show, onClose) => (
    <ProjetoAdicaoModal
      nome="projeto"
      show={show}
      onClose={onClose}
      element={InsercaoProjetoForm}
      create={this.projetoRepository.create}
      formToModel={p => p}
    />
  );

  exibirRemocaoModal: RemocaoModalFunction = (show, selecionados, onClose) => (
    <RemocaoModal
      show={show}
      selecionados={selecionados}
      onClose={onClose}
      deleteMany={this.projetoRepository.deleteMany}
      nome={["projeto", "projetos"]}
    />
  );
}

const ProjetosPage = () => {
  const history = useHistory();

  const [idEdicao, setIdEdicao] = useState<number | undefined>(undefined);
  const [editando, setEditando] = useState(false);

  const context = useContext(AppContext);
  const repository = context.repositoryFactory.getProjetoRepository(context);

  const ref = useRef<TabelaProjetos>(null);

  return (
    <PageLayout title="Projetos">
      <TabelaProjetos
        ref={ref}
        ordenarPorPadrao="data"
        tipoOrdenacaoPadrao="desc"
        opcoesFiltro={[{ value: 'nome', label: 'Nome' }, { value: 'nomeCliente', label: 'Cliente' }]}
        handleEdit={id => { setIdEdicao(id); setEditando(true); }}
      >
        <Table.Column width={60}>
          <Table.HeaderCell>#</Table.HeaderCell>
          <Table.Cell dataKey="id" />
        </Table.Column>
        <Table.Column flexGrow={1} minWidth={200}>
          <Table.HeaderCell>Nome</Table.HeaderCell>
          <NameCell dataKey="nome" />
        </Table.Column>
        <Table.Column flexGrow={1} minWidth={160} sortable>
          <Table.HeaderCell>Cliente</Table.HeaderCell>
          <Table.Cell dataKey="nomeCliente" />
        </Table.Column>
        <Table.Column>
          <Table.HeaderCell>Cômodos</Table.HeaderCell>
          <Table.Cell dataKey="numComodos" />
        </Table.Column>
        <Table.Column sortable>
          <Table.HeaderCell>Data</Table.HeaderCell>
          <DateCell dataKey="data" />
        </Table.Column>
        <Table.Column width={60}>
          <Table.HeaderCell>Ver</Table.HeaderCell>
          <ActionCell icon="eye" dataKey="id" onClick={(id: number) => history.push(`/projetos/${id}`)} />
        </Table.Column>
      </TabelaProjetos>

      <EdicaoDrawer
        show={editando}
        id={idEdicao}
        onClose={(refresh: boolean = false) => {
          setEditando(false);
          setIdEdicao(undefined);
          if (refresh) {
            ref.current?.refresh();
          }
        }}
        update={repository.update}
        read={repository.read}
        delete={repository.deleteMany}
        formToModel={p => p}
        modelToForm={v => v}
        formElement={EdicaoProjetoForm}
      />
    </PageLayout>
  );
}

export default ProjetosPage;
