import React, { useContext, useEffect } from 'react';
import { Controller, FormProvider } from 'react-hook-form';
import { Drawer, Form, Icon, IconButton } from 'rsuite';
import { AppContext } from '../../../context';
import Orcamento, { orcamentoFormToModel } from '../../../types/orcamento';
import useEdicaoForm from '../../../utils/useEdicaoForm';
import GeracaoOrcamentoForm from '../../projetos/single/GeracaoOrcamentoForm';

type Props = {
  show: boolean,
  onClose: (doRefresh?: boolean) => void,
  orcamento: Orcamento,
};

const EdicaoOrcamentoDrawer = ({ show, onClose, orcamento }: Props) => {
  const context = useContext(AppContext);
  const repository = context.repositoryFactory.getOrcamentoRepository(context);

  const { onSubmit, submitting, ...methods } = useEdicaoForm({
    formToModel: orcamentoFormToModel,
    update: repository.update,
    callback: (result) => { if (result === true) onClose(true) },
  });

  const { reset } = methods;

  useEffect(() => {
    reset(orcamento);
  }, [reset, orcamento]);

  return (
    <Drawer className="drawer-fluid-xs" size="xs" show={show} onHide={() => onClose()}>
      <Drawer.Header>
        <Drawer.Title>Edição</Drawer.Title>
      </Drawer.Header>
      <FormProvider {...methods}>
        <Form onSubmit={(_c, e) => onSubmit(e)} fluid>
          <Drawer.Body>
            <div style={{ width: '95%' }}>
              <Controller name="id" control={methods.control} render={() => <></>} />
              <Controller name="endereco.id" control={methods.control} render={() => <></>} />
              <GeracaoOrcamentoForm mostrarEnderecoDireto />
              <IconButton
                loading={submitting}
                icon={<Icon icon="check" />}
                appearance="primary"
                type="submit"
                style={{ marginTop: '16px' }}
              >Confirmar</IconButton>
            </div>
          </Drawer.Body>
        </Form>
      </FormProvider>
    </Drawer>
  );
}

export default EdicaoOrcamentoDrawer;
