import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { ControlLabel, Divider, FormControl, FormGroup, HelpBlock} from 'rsuite';
import EnderecoForm from '../../components/EnderecoForm';
import InputMask from '../../components/InputMask';
import InputTelefone from '../../components/InputTelefone';
import { ClienteForm } from '../../types/cliente';

const InsercaoEmpresaForm = () => {
  const form = useFormContext<ClienteForm>();
  return (
    <>
      <FormGroup>
        <ControlLabel>Nome</ControlLabel>
        <FormControl
          autoFocus
          name="empresa.nome"
          placeholder="ex: Artlajes"
          inputRef={form.register({ required: 'Este campo é necessário' })}
          errorMessage={form.errors.empresa?.nome && form.errors.empresa?.nome?.message}
        />
        <HelpBlock>Campo obrigatório</HelpBlock>
      </FormGroup>
      <FormGroup>
        <ControlLabel>CNPJ</ControlLabel>
        <Controller
          name="empresa.cnpj"
          control={form.control}
          defaultValue=""
          render={({ name, value, onChange, onBlur }) => (
            <FormControl
              name={name}
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              accepter={InputMask}
              mask={[
                /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/
              ]}
              placeholder="ex: 00.000.000/0001-00"
              errorMessage={form.errors?.empresa?.cnpj && form.errors?.empresa?.cnpj.message}
            />
          )}
        />
      </FormGroup>
      <FormGroup>
        <ControlLabel>ICMS</ControlLabel>
        <FormControl
          name="empresa.icms"
          placeholder="ex: 0000000000"
          inputRef={form.register}
          errorMessage={form.errors?.empresa?.icms && form.errors?.empresa?.icms.message}
        />
      </FormGroup>
      <FormGroup>
        <ControlLabel>Número de telefone da empresa</ControlLabel>
        <Controller
          name="empresa.telefone"
          control={form.control}
          defaultValue=""
          render={({ name, value, onChange, onBlur }) => (
            <InputTelefone
              name={name}
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              placeholder="ex: (99) 99999-9999"
            />
          )}
        />
      </FormGroup>
      <Divider>Pessoa para contato</Divider>
      <FormGroup>
        <ControlLabel>Nome</ControlLabel>
        <FormControl
          name="empresa.contato.nome"
          placeholder="ex: João Mendes"
          inputRef={form.register({ required: 'Este campo é necessário' })}
          errorMessage={form.errors.empresa?.contato?.nome && form.errors.empresa.contato.nome.message}
        />
      </FormGroup>
      <FormGroup>
        <ControlLabel>Número de Telefone</ControlLabel>
        <Controller
          name="empresa.contato.telefone"
          control={form.control}
          defaultValue=""
          render={({ name, value, onChange, onBlur }) => (
            <InputTelefone
              name={name}
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              placeholder="ex: (99) 99999-9999"
            />
          )}
        />
      </FormGroup>
      <FormGroup>
        <ControlLabel>E-mail</ControlLabel>
        <FormControl
          name="empresa.contato.email"
          placeholder="ex: joao.mendes@email.com"
          inputRef={form.register}
        />
      </FormGroup>
      <Divider>Endereço</Divider>
      <EnderecoForm form={form} obrigatorio={false} prefixo="empresa.endereco." errors={form.errors.empresa?.endereco} />
    </>
  );
};

export default InsercaoEmpresaForm;
