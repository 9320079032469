import { useCallback, useState } from "react";
import { SubmitHandler, useForm, UseFormMethods } from "react-hook-form";
import { Alert } from "rsuite";
import { CallbackAndFormOptions } from "../types/callback-and-form-options";
import { CreateFunction } from "../types/crud-functions";
import { FormToModel } from "../types/form-model-conversions";
import setFormErrors from "./setFormErrors";

export type UseInsercaoFormProps<T, U> = CallbackAndFormOptions<U> & {
  create: CreateFunction<T>,
  formToModel: FormToModel<T, U>,
  mensagemSucesso?: string,
}

export type UseInsercaoFormMethods<U> = {
  onSubmit: (e?: React.BaseSyntheticEvent<object, any, any>) => void,
  submitting: boolean,
} & UseFormMethods<U>;

const useInsercaoForm = <T, U = T>(props: UseInsercaoFormProps<T, U>) => {
  const { callback, formOptions, create, formToModel } = props;

  const methods = useForm<U>(formOptions);
  const [submitting, setSubmitting] = useState(false);

  const { setError } = methods;

  const onSubmit: SubmitHandler<U> = useCallback(async (values) => {
    let submitResult: boolean | number = false;
    let model = formToModel(values as U);

    setSubmitting(true);

    if (model) {
      const resposta = await create(model as T);
      
      if (resposta === true || typeof resposta === 'number') {
        Alert.success(props.mensagemSucesso || 'Inserido com sucesso!');
        if (typeof resposta === 'number') {
          submitResult = resposta;
        } else {
          submitResult = true;
        }
      } else {
        if (typeof resposta === 'string') {
          Alert.error(`Falha ao inserir: ${resposta}`);
        } else if (typeof resposta === 'object') {
          setFormErrors(setError, resposta, "");
        }
      }
    }

    setSubmitting(false);

    if (callback) {
      callback(submitResult);
    }
  }, [create, callback, setError, formToModel, props.mensagemSucesso]);

  return { ...methods, onSubmit: methods.handleSubmit(onSubmit), submitting };
};

export default useInsercaoForm;

