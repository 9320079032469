import React, { useState } from 'react';
import { Icon, IconButton } from 'rsuite';

type OnCloseCallback = (doRefresh: boolean) => void;

type Props = {
  refresh(): void,
  children(show: boolean, onClose: OnCloseCallback): React.ReactNode,
};

const AdicaoButton = ({ refresh, children }: Props) => {
  const [show, setShow] = useState(false);

  const handleClose = (doRefresh: boolean = false) => {
    setShow(false);
    if (doRefresh) refresh();
  }
  const handleOpen = () => setShow(true);

  return (
    <>
      <IconButton onClick={handleOpen} icon={<Icon icon="plus" />} appearance="primary">Adicionar</IconButton>
      {children(show, handleClose)}
    </>
  );
};

export default AdicaoButton;
