import React, { useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { Button, Drawer, Form, Icon, IconButton, Modal, Placeholder } from 'rsuite';
import { DeleteManyFunction, ReadFunction, UpdateFunction } from '../types/crud-functions';
import { FormToModel, ModelToForm } from '../types/form-model-conversions';
import useDeleteMany from '../utils/useDeleteMany';
import useEdicaoForm from '../utils/useEdicaoForm';
import useReadModel from '../utils/useReadModel';

type Props<T, U> = {
  show: boolean,
  onClose: (doRefresh?: boolean) => void,
  id?: any,
  update: UpdateFunction<T>,
  delete: DeleteManyFunction,
  formToModel: FormToModel<T, U>,
  modelToForm: ModelToForm<T, U>,
  formElement: React.ElementType,
};

function EdicaoDrawer<T, U = T>(props: Props<T, U> & { read: ReadFunction<T> }) {
  const { model } = useReadModel(props.read, props.id);

  let body;
  if (model.status === 'loading') {
    body = <Drawer.Body><Placeholder.Paragraph active rows={10} /></Drawer.Body>;
  } else if (model.status === 'loaded') {
    body = <EdicaoDrawerInternal {...props} values={props.modelToForm(model.payload)} />;
  } else if (model.status === 'error') {
    body = <Drawer.Body>{model.error.message}</Drawer.Body>;
  }

  return (
    <Drawer className="drawer-fluid-xs" size="xs" show={props.show} onHide={() => props.onClose()}>
      <Drawer.Header>
        <Drawer.Title>Edição </Drawer.Title>
      </Drawer.Header>
      {body}
    </Drawer>
  );
}

function EdicaoDrawerInternal<T, U>(props: Props<T, U> & { values: U }) {
  const [showRemoval, setShowRemoval] = useState(false);

  const { onSubmit, submitting, ...methods } = useEdicaoForm<T, U>({
    formToModel: props.formToModel,
    update: props.update,
    formOptions: { defaultValues: props.values },
    callback: (result) => { if (result === true) props.onClose(true) },
  });
  const { deleteMany, deleting } = useDeleteMany(props.delete, props.onClose);

  const { formElement: Component } = props;

  return (
    <>
      <FormProvider {...methods}>
        <Form onSubmit={(_c, e) => onSubmit(e)} fluid>
          <Drawer.Body>
            <div style={{ width: '95%' }}>
              <Component />
              <div style={{ margin: '16px 0', display: 'flex', justifyContent: 'space-between' }}>
                <IconButton
                  loading={submitting}
                  disabled={deleting}
                  icon={<Icon icon="check" />}
                  appearance="primary"
                  type="submit"
                >Confirmar</IconButton>
                <IconButton
                  loading={deleting}
                  disabled={submitting}
                  icon={<Icon icon="trash" />}
                  appearance="primary"
                  color="red"
                  onClick={() => setShowRemoval(true)}
                >Remover</IconButton>
              </div>
            </div>
          </Drawer.Body>
        </Form>
      </FormProvider>
      <Modal size="xs" className="modal-fluid-xs" backdrop={true} show={showRemoval} onHide={() => setShowRemoval(false)}>
        <Modal.Header>
          <Modal.Title>Confirmar remoção</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Tem certeza que deseja remover?
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => {
              setShowRemoval(false);
              if (props.id) {
                deleteMany([props.id]);
              }
            }}
            appearance="primary"
          >Sim</Button>
          <Button onClick={() => setShowRemoval(false)} appearance="subtle">
            Não
          </Button>
          </Modal.Footer>
      </Modal>
    </>
  );
};

export default EdicaoDrawer;
