import React, { useContext, useEffect, useState } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { Button, ButtonToolbar, ControlLabel, Divider, FormGroup, Input, InputGroup } from 'rsuite';
import FormFerro from '../../../../components/FormFerro';
import UncontrolledFormInput from '../../../../components/UncontrolledFormInput';
import Ferro from '../../../../types/ferro';
import { ComposicaoLaje } from '../../../../types/laje';

export const ComposicaoFormContext = React.createContext({ edicao: true });

const ComposicaoForm = () => {
  const [ferrosCriados, setFerrosCriados] = useState<number[]>([]);
  const formContext = useContext(ComposicaoFormContext);

  const append = () => {
    setFerrosCriados([...ferrosCriados, ferrosCriados.length + 1]);
  }

  const remove = (index: number) => {
    setFerrosCriados([...ferrosCriados.slice(0, index), ...ferrosCriados.slice(index + 1)]);
  }

  const form = useFormContext<ComposicaoLaje>();

  const ferros = useWatch<Ferro[]>({ name: 'ferros', control: form.control });
  useEffect(() => {
    if (ferros) {
      setFerrosCriados(ferros.map((f, index) => index));
    }
  }, [ferros]);
  
  return (
    <>
      <Controller name="idLaje" control={form.control} render={() => <></>} />
      <FormGroup>
        <ControlLabel>Medida Inicial</ControlLabel>
        <InputGroup>
          <UncontrolledFormInput errorMessage={form.errors?.medidaInicial?.message}>
            <Input
              autoFocus={!formContext.edicao}
              name="medidaInicial"
              placeholder="ex: 3.01"
              readOnly={formContext.edicao}
              inputRef={form.register({
                required: 'Este campo é necessário',
                validate: number => (!isNaN(number) && Number(number) >= 0) || 'Deve ser um número positivo',
              })}
            />
          </UncontrolledFormInput>
          <InputGroup.Addon>m</InputGroup.Addon>
        </InputGroup>
      </FormGroup>

      <FormGroup>
        <ControlLabel>Medida Final</ControlLabel>
        <InputGroup>
          <UncontrolledFormInput errorMessage={form.errors?.medidaFinal?.message}>
            <Input
              name="medidaFinal"
              placeholder="ex: 3.75"
              readOnly={formContext.edicao}
              inputRef={form.register({
                required: 'Este campo é necessário',
                validate: number => (!isNaN(number) && Number(number) >= 0) || 'Deve ser um número positivo',
              })}
            />
          </UncontrolledFormInput>
          <InputGroup.Addon>m</InputGroup.Addon>
        </InputGroup>
      </FormGroup>

      <Divider>Ferro auxiliar</Divider>

      <FormFerro
        prefix={`ferroAuxiliar.`}
        errors={form.errors.ferroAuxiliar}
        form={form}
      />
      
      {ferrosCriados.map((item, index) => (
        <div key={index}>
          <Divider>Ferro principal #{index + 1}</Divider>
          <FormFerro
            prefix={`ferros.${index}.`}
            defaultValue={(ferros && ferros[index]?.id) || null}
            errors={form.errors.ferros && form.errors.ferros[index]}
            form={form}
          />
        </div>
      ))}
      <Divider />
      <div style={{ marginTop: '15px' }}>
        <ButtonToolbar>
          <Button appearance="ghost" onClick={() => append()}>Adicionar Ferro Principal</Button>
          { ferrosCriados.length > 0 && (
            <Button appearance="ghost" color="red" onClick={() => remove(ferrosCriados.length - 1)}>Remover Ferro</Button>
          )}
        </ButtonToolbar>
      </div>
    </>
  );
};

export default ComposicaoForm;
