import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import Produto from '../../../types/produto';
import InsercaoProdutoForm from './InsercaoProdutoForm';

const EdicaoProdutoForm = () => {
  const { control } = useFormContext<Produto>();

  return (
    <>
      <Controller name="id" control={control} render={() => <></>} />
      <InsercaoProdutoForm />
    </>
  );
};

export default EdicaoProdutoForm;
