import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import Lajota from '../../../types/lajota';
import InsercaoLajotaForm from './InsercaoLajotaForm';

const EdicaoLajotaForm = () => {
  const { control } = useFormContext<Lajota>();

  return (
    <>
      <Controller name="id" control={control} render={() => <></>} />
      <InsercaoLajotaForm />
    </>
  );
};

export default EdicaoLajotaForm;
