import React, { useContext, useRef, useState } from 'react';
import { Table } from 'rsuite';
import AdicaoModal from '../../../components/AdicaoModal';
import BaseTabela from '../../../components/BaseTabela';
import { AdicaoModalFunction, BaseTabelaProps, RemocaoModalFunction } from '../../../components/BaseTabela/BaseTabela';
import EdicaoDrawer from '../../../components/EdicaoDrawer';
import MoneyCell from '../../../components/MoneyCell';
import NameCell from '../../../components/NameCell';
import PageLayout from '../../../components/PageLayout';
import RemocaoModal from '../../../components/RemocaoModal';
import { AppContext, AppContextProps } from '../../../context';
import { ProdutoRepository } from '../../../repositories/interfaces/produto-repository';
import { Pagina } from '../../../types/pagina';
import Produto from '../../../types/produto';
import RequisicaoPagina from '../../../types/requisicao-pagina';
import EdicaoProdutoForm from './EdicaoProdutoForm';
import InsercaoProdutoForm from './InsercaoProdutoForm';

class TabelaProdutos extends BaseTabela<Produto> {
  private produtoRepository: ProdutoRepository;

  constructor(props: BaseTabelaProps, context: AppContextProps) {
    super(props);
    this.produtoRepository = context.repositoryFactory.getProdutoRepository(context);
  }

  buscarParaExibicao(requisicao: RequisicaoPagina) : Promise<Pagina<Produto>> {
    return this.produtoRepository.buscarParaExibicao(requisicao);
  }

  exibirAdicaoModal: AdicaoModalFunction = (show, onClose) => (
    <AdicaoModal
      nome="produto"
      show={show}
      onClose={onClose}
      element={InsercaoProdutoForm}
      create={this.produtoRepository.create}
      formToModel={f => f}
    />
  );

  exibirRemocaoModal: RemocaoModalFunction = (show, selecionados, onClose) => (
    <RemocaoModal
      show={show}
      selecionados={selecionados}
      onClose={onClose}
      deleteMany={this.produtoRepository.deleteMany}
      nome={["produto", "produtos"]}
    />
  );
}

const ProdutosPage = () => {
  const [idEdicao, setIdEdicao] = useState<number | undefined>(undefined);
  const [editando, setEditando] = useState(false);

  const context = useContext(AppContext);
  const repository = context.repositoryFactory.getProdutoRepository(context);

  const ref = useRef<TabelaProdutos>(null);

  return (
    <PageLayout title="Produtos">
      <TabelaProdutos
        ref={ref}
        ordenarPorPadrao="nome"
        tipoOrdenacaoPadrao="asc"
        opcoesFiltro={[{ value: 'nome', label: 'Nome' }]}
        handleEdit={id => { setIdEdicao(id); setEditando(true); }}
      >
        <Table.Column sortable width={150}>
          <Table.HeaderCell>Nome</Table.HeaderCell>
          <Table.Cell dataKey="nome" />
        </Table.Column>
        <Table.Column width={150}>
          <Table.HeaderCell>Descrição</Table.HeaderCell>
          <NameCell dataKey="descricao" />
        </Table.Column>
        <Table.Column sortable>
          <Table.HeaderCell>Valor</Table.HeaderCell>
          <MoneyCell dataKey="valor" />
        </Table.Column>
      </TabelaProdutos>

      <EdicaoDrawer
        show={editando}
        id={idEdicao}
        onClose={(refresh: boolean = false) => {
          setEditando(false);
          setIdEdicao(undefined);
          if (refresh) {
            ref.current?.refresh();
          }
        }}
        update={repository.update}
        read={repository.read}
        delete={repository.deleteMany}
        formToModel={a => a}
        modelToForm={a => a}
        formElement={EdicaoProdutoForm}
      />
    </PageLayout>
  );
}

export default ProdutosPage;
