import React, { useCallback } from 'react';
import { FormProvider, UseFormOptions } from 'react-hook-form';
import { Button, Form, Modal } from 'rsuite';
import { CreateFunction } from '../types/crud-functions';
import { FormToModel } from '../types/form-model-conversions';
import useInsercaoForm from '../utils/useInsercaoForm';

export type AdicaoModalProps<T, U = T> = {
  nome: string,
  stringNovo?: string,
  mensagemSucesso?: string,
  show: boolean,
  onClose(response?: boolean | number): void,
  create: CreateFunction<T>,
  formToModel: FormToModel<T, U>
  element: React.ElementType,
  formOptions?: UseFormOptions<U>,
};


function AdicaoModal<T, U = T>({ mensagemSucesso, stringNovo = 'Novo', nome, show, onClose, create, element, formOptions, formToModel }: AdicaoModalProps<T, U>) {
  const { element: Component } = { element };

  const submitCallback = useCallback(
    (submitResult: boolean | number) => {
      if (typeof submitResult === 'number' || submitResult === true) {
        onClose(submitResult);
      }
    },
  [onClose]);

  const methods = useInsercaoForm({ mensagemSucesso, callback: submitCallback, formOptions, create, formToModel });

  const { onSubmit, submitting } = methods;

  const handleSubmit = useCallback((_c: any, e: any) => onSubmit(e), [onSubmit]);
  const handleHide = useCallback(() => onClose(false), [onClose]);

  return (
    <FormProvider { ...methods } >
      <Modal overflow={false} size="xs" show={show} backdrop={true} onHide={handleHide} className="modal-fluid-xs">
        <Form onSubmit={handleSubmit} fluid>
          <Modal.Header>
            <Modal.Title>{stringNovo} {nome}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Component />
          </Modal.Body>
          <Modal.Footer>
            <Button appearance="primary" type="submit" loading={submitting}>Confirmar</Button>
            <Button appearance="subtle" onClick={handleHide}>Cancelar</Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </FormProvider>
  );
}

export default AdicaoModal;
