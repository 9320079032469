import React, { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Redirect } from 'react-router-dom';
import { Alert, Button, ButtonToolbar, Col, Container, Content, ControlLabel, FlexboxGrid, Footer, Form, FormControl, FormGroup, Icon, InputGroup, Panel } from 'rsuite';
import { AppContext } from '../context';

const LoginPage = () => {
  const context = useContext(AppContext);
  const { register, handleSubmit, errors } = useForm();
  const [ loading, setLoading ] = useState(false);

  if (context.authState.authenticated) {
    return <Redirect to="/" />;
  }

  const authRepository = context.repositoryFactory.getAuthRepository();
  
  const onSubmit = async (data: any) => {
    setLoading(true);
    try {
      const result = await authRepository.authenticate(data.username, data.password);

      if (result === false ) {
        Alert.error('Nome de usuário ou senha incorreta!');
        setLoading(false);
      } else {
        context.setAuthState({
          usuario: result,
          authenticated: true,
          loading: false,
        });
      }

    } catch (e) {
      Alert.error(`${e.message}`);
      setLoading(false);
    }

  }

  return (
    <Container style={{ height: '100%', backgroundColor: '#f2f2f2' }}>
      <Content style={{ padding: '20px' }}>
        <FlexboxGrid justify="center">
          <FlexboxGrid.Item  componentClass={Col} colspan={24} sm={16} md={12} lg={8}>
            <Panel header={<h3>Login</h3>} bordered style={{ backgroundColor: '#fff' }}>
              <Form fluid onSubmit={(_c, e) => handleSubmit(onSubmit)(e)}>
                <FormGroup>
                  <ControlLabel>Nome de usuário</ControlLabel>
                  <InputGroup style={{ width: '100%' }}>
                    <InputGroup.Addon>
                      <Icon icon="user" />
                    </InputGroup.Addon>
                    <FormControl
                      autoFocus
                      disabled={loading}
                      name="username"
                      inputRef={register({required: 'Informe o nome de usuário!'})}
                      errorMessage={errors.username && errors.username.message}
                    />
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <ControlLabel>Senha</ControlLabel>
                  <InputGroup style={{ width: '100%' }}>
                    <InputGroup.Addon>
                      <Icon icon="lock" />
                    </InputGroup.Addon>
                    <FormControl
                      disabled={loading}
                      name="password"
                      type="password"
                      inputRef={register({required: 'Informe a senha!'})}
                      errorMessage={errors.password && errors.password.message}
                    />
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <ButtonToolbar>
                    <Button loading={loading} appearance="primary" type="submit">Entrar</Button>
                  </ButtonToolbar>
                </FormGroup>
              </Form>
            </Panel>
          </FlexboxGrid.Item>
        </FlexboxGrid>
      </Content>
      <Footer style={{ textAlign: 'center', padding: '20px' }}>
        Feito por <a target="_blank" rel="noopener noreferrer" href="http://aegis.dev.br">Aegis</a>
      </Footer>
    </Container>
  );
}

export default LoginPage;
