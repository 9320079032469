import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Message, Placeholder } from 'rsuite';
import PageLayout from '../../../components/PageLayout';
import { AppContext } from '../../../context';
import Indices from '../../../types/indices';
import ProducaoForm from './ProducaoForm';

const ProducaoPage = () => {
  const [indices, setIndices] = useState<Indices | null>(null);
  const [carregando, setCarregando] = useState(true);
  const [erro, setErro] = useState<string | null>(null);

  const context = useContext(AppContext);
  const repository = context.repositoryFactory.getProducaoRepository(context);

  const refresh = useCallback(() => {
    repository.buscar().then(indices => {
      setCarregando(false);
      if (indices) {
        setIndices(indices);
      } else {
        setErro('Um erro inesperado ocorreu!');
      }
    });
  }, [repository]);

  useEffect(() => {
    refresh();
  }, [refresh]);

  let body;
  if (carregando) {
    body = <Placeholder.Paragraph rows={2} active />;
  } else if (erro) {
    body = (
      <Message
        showIcon
        type="error"
        title="Erro"
        description={erro}
      />
    );
  } else if (indices) {
    body = (
      <ProducaoForm indices={indices} refresh={refresh} />
    );
  } else {
    body = <span></span>;
  }

  return (
    <PageLayout title="Produção">
      {body}
    </PageLayout>
  );
};

export default ProducaoPage;
