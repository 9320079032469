import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Alert, Button, Modal } from 'rsuite';
import InputLaje from '../../../components/InputLaje';
import { AppContext } from '../../../context';
import Laje from '../../../types/laje';

type Props = {
  idProjeto: number,
  comodosSelecionados: Set<number>,
  refresh(): void,
}

const AlteracaoLajesButton = ({ idProjeto, comodosSelecionados, refresh }: Props) => {
  const [show, setShow] = useState(false);
  const [alterando, setAlterando] = useState(false);
  const [idLaje, setIdLaje] = useState<number | null>(null);
  const [laje, setLaje] = useState<Laje | null>(null);

  const context = useContext(AppContext);
  const repository = context.repositoryFactory.getComodoProjetoRepository(context);
  const lajeRepository = context.repositoryFactory.getLajeRepository(context);

  useEffect(() => {
    if (idLaje) {
      lajeRepository.read(idLaje).then(laje => {
        setLaje(laje);
      });
    }
  }, [idLaje, lajeRepository]);

  const onSubmit = useCallback(async () => {
    if (idLaje) {
      setAlterando(true);

      const resposta = await repository.alterarLajes(idProjeto, Array.from(comodosSelecionados), idLaje);

      if (resposta === true) {
        Alert.success('Lajes dos cômodos foram alteradas com sucesso!');
        setShow(false);
        refresh();
      } else {
        Alert.error(resposta);
      }

      setAlterando(false);
    }
  }, [idLaje, repository, idProjeto, comodosSelecionados, refresh]);

  return (
    <>
      <Button disabled={comodosSelecionados.size === 0} appearance="ghost" onClick={() => setShow(true)}>Alterar Lajes</Button>
      <Modal size="xs" show={show} backdrop={true} onHide={() => setShow(false)} className="modal-fluid-xs">
        <Modal.Header>
          <Modal.Title>Alterar lajes dos cômodos</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <InputLaje laje={laje} value={idLaje} onChange={setIdLaje} />
        </Modal.Body>
        <Modal.Footer>
          <Button appearance="primary" disabled={!idLaje} loading={alterando} onClick={onSubmit}>Confirmar</Button>
          <Button appearance="subtle" onClick={() => setShow(false)}>Cancelar</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AlteracaoLajesButton;
