import React, { useContext } from 'react';
import { Controller, FieldError, useFormContext, UseFormMethods, useWatch } from 'react-hook-form';
import { ControlLabel, FormControl, FormGroup, HelpBlock, Input, InputPicker } from 'rsuite';
import UncontrolledFormInput from '../../components/UncontrolledFormInput';
import { AppContext } from '../../context';
import { UsuarioForm } from '../../types/usuario';

type Props = {
  required: boolean,
  form: UseFormMethods<any>,
  passwordError?: FieldError,
  confirmPasswordError?: FieldError,
};

export const FormSenha = ({ required, form, passwordError, confirmPasswordError }: Props) => {
  const watchPassword = useWatch<string>({ control: form.control, name: 'senha', defaultValue: '' });

  return (
    <>
      <FormGroup>
        <ControlLabel>Senha</ControlLabel>
        <UncontrolledFormInput errorMessage={passwordError?.message}>
          <Input
            name="senha"
            type="password"
            inputRef={form.register({
              required: required ? 'Este campo é necessário': undefined,
              minLength: { value: 8, message: 'Deve ter ao menos 8 caracteres' }
            })}
          />
        </UncontrolledFormInput>
        {!required && <HelpBlock>Digite apenas se quiser alterar</HelpBlock>}
      </FormGroup>

      <FormGroup>
        <ControlLabel>Confirmar Senha</ControlLabel>
        <UncontrolledFormInput errorMessage={confirmPasswordError?.message}>
          <Input
            name="confirmarSenha"
            type="password"
            inputRef={form.register({
              required: required ? 'Este campo é necessário' : undefined,
              validate: value => (watchPassword?.length === 0 && !required) || value === watchPassword || 'As senhas não coincidem!',
            })}
          />
        </UncontrolledFormInput>
      </FormGroup>
    </>
  );
};

export const FormUsuarioContext = React.createContext({ edicao: true });

const defaultData = [{ value: 'G', label: 'Gerente' }, { value: 'U', label: 'Usuário' }];

const FormUsuario = () => {
  const form = useFormContext<UsuarioForm>();
  const formContext = useContext(FormUsuarioContext);
  const appContext = useContext(AppContext);
  
  let data = appContext.authState.usuario?.role === 'A'
    ? [{ value: 'A', label: 'Administrador' }, ...defaultData]
    : defaultData;

  return (
    <>
      <FormGroup>
        <ControlLabel>Nome de usuário</ControlLabel>
        <UncontrolledFormInput errorMessage={form.errors.username?.message}>
          <Input
            autoFocus={!formContext.edicao}
            readOnly={formContext.edicao}
            name="username"
            placeholder="ex: mbastos"
            inputRef={form.register({
              required: 'Este campo é necessário'
            })}
          />
        </UncontrolledFormInput>
        <HelpBlock>Usado no login</HelpBlock>
      </FormGroup>
      <FormGroup>
        <ControlLabel>Nome</ControlLabel>
        <UncontrolledFormInput errorMessage={form.errors.nome?.message}>
          <Input
            autoFocus={formContext.edicao}
            name="nome"
            placeholder="ex: Matheus B"
            inputRef={form.register({
              required: 'Este campo é necessário'
            })}
          />
        </UncontrolledFormInput>
      </FormGroup>
      <FormGroup>
        <ControlLabel>Função</ControlLabel>
        <Controller
          name="role"
          control={form.control}
          defaultValue={null}
          rules={{ required: 'Este campo é necessário' }}
          render={({ name, value, onChange, onBlur }) => (
            <FormControl
              name={name}
              accepter={InputPicker}
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              data={data}
              placeholder="ex: Gerente"
              errorMessage={form.errors.role?.message}
            />
          )}
        />
      </FormGroup>

      <FormSenha required={!formContext.edicao} form={form} passwordError={form.errors.senha} confirmPasswordError={form.errors.confirmarSenha} />
    </>
  );
};

export default FormUsuario;

