import React from 'react';
import { Container, FlexboxGrid, Icon } from 'rsuite';

const NotFoundPage = () => (
  <Container style={{ minWidth: '264px' }}>
    <FlexboxGrid justify="center" align="middle" style={{ height: '100vh' }}>
      <FlexboxGrid.Item colspan={24} style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', textAlign: 'center' }}>
        <Icon icon="warning" size="5x" />
        <h3 style={{ margin: '10px 10px' }}>Página não encontrada</h3>
        <span style={{ margin: '0 10px', color: '#575757' }}>Use a navegação ao lado para mudar de página.</span>
      </FlexboxGrid.Item>
    </FlexboxGrid>
  </Container>
);

export default NotFoundPage;
