import React, { useContext, useEffect, useState } from 'react';
import { Input, Table } from 'rsuite';
import { AppContext } from '../context';
import Laje from '../types/laje';
import NameCell from './NameCell';
import SelecaoModal from './SelecaoModal';

type Props = {
  laje: Laje | null,
  value: number | null,
  onChange: (id: number) => void,
};

const InputLaje = ({ laje, value, onChange }: Props) => {
  const [showModal, setShowModal] = useState(false);
  const [nome, setNome] = useState('Selecionar laje...');
  const context = useContext(AppContext);
  const lajeRepository = context.repositoryFactory.getLajeRepository(context);

  useEffect(() => {
    if (laje) {
      setNome(laje.nome);
    } else {
      setNome('Selecionar laje...');
    }
  }, [laje, lajeRepository]);

  return (
    <>  
      <Input onClick={() => setShowModal(true)} readOnly value={nome} style={{ cursor: 'pointer' }} />
      <SelecaoModal
        show={showModal}
        buscarParaSelecao={lajeRepository.buscarParaSelecao}
        onClose={selected => {
          if (selected) {
            onChange(selected.id);
          }
          setShowModal(false);
        }}
      >
        <Table.Column width={140}>
          <Table.HeaderCell>Nome</Table.HeaderCell>
          <NameCell dataKey="nome" />
        </Table.Column>
        <Table.Column>
          <Table.HeaderCell>Sobrecarga (kg/m²)</Table.HeaderCell>
          <Table.Cell dataKey="sobrecarga" />
        </Table.Column>
        <Table.Column>
          <Table.HeaderCell>Lajota</Table.HeaderCell>
          <NameCell dataKey="nomeLajota" />
        </Table.Column>
        <Table.Column>
          <Table.HeaderCell>Armação</Table.HeaderCell>
          <Table.Cell dataKey="nomeArmacao" />
        </Table.Column>
      </SelecaoModal>
    </>
  );
};

export default InputLaje;
