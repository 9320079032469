import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import Armacao from '../../../types/armacao';
import InsercaoArmacaoForm from './InsercaoArmacaoForm';

const EdicaoArmacaoForm = () => {
  const { control } = useFormContext<Armacao>();

  return (
    <>
      <Controller name="id" control={control} render={() => <></>} />
      <InsercaoArmacaoForm />
    </>
  );
};

export default EdicaoArmacaoForm;
