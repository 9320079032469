import Axios from "axios";
import Ferro, { FerroExibicao } from "../../types/ferro";
import { Pagina } from "../../types/pagina";
import RequisicaoPagina from "../../types/requisicao-pagina";
import { FerroRepository } from "../interfaces/ferro-repository";

class ApiFerroRepository implements FerroRepository {
  async findAll(): Promise<Ferro[]> {
    const resposta = await Axios.get('/materiais/ferros');

    if (resposta.status === 200) {
      return resposta.data;
    } else {
      return [];
    }
  }

  async buscarParaExibicao(requisicao: RequisicaoPagina): Promise<Pagina<FerroExibicao>> {
    const resposta = await Axios.get(
      '/materiais/ferros/exibicao',
      { params: requisicao },
    );

    if (resposta.status === 200) {
      return resposta.data;
    } else if (resposta.status === 204) {
      return {
        total: 0,
        pagina: [],
      }
    }

    throw new Error(`Falha ao buscar! O servidor respondeu com ${resposta.status}: ${resposta.data.message}`);
  }

  async buscarParaSelecao(busca: string, pagina: number): Promise<Pagina<Ferro>> {
    const resposta = await Axios.get('/materiais/ferros/selecao', { params: { busca, pagina} });

    if (resposta.status === 200) {
      return resposta.data;
    } else if (resposta.status === 204) {
      return { total: 0, pagina: [] };
    } else {
      throw new Error(`Falha ao buscar! O servidor respondeu com ${resposta.status}: ${resposta.data.message}`);
    }
  }

  async create(ferro: Ferro): Promise<any> {
    const resposta = await Axios.post('/materiais/ferros', ferro);

    if (resposta.status === 200) {
      return true;
    } else if (resposta.status === 500) {
      return resposta.data.message;
    } else if (resposta.status === 400) {
      return resposta.data;
    }

    return 'Erro inesperado!';
  }

  async read(id: number): Promise<Ferro | null> {
    const resposta = await Axios.get<Ferro>(`/materiais/ferros/${id}`);

    if (resposta.status === 200) {
      return resposta.data;
    }

    return null;
  }

  async update(ferro: Ferro): Promise<true | object | string> {
    const resposta = await Axios.put('/materiais/ferros', ferro);

    if (resposta.status === 200) {
      return true;
    } else if (resposta.status === 500) {
      return resposta.data.message;
    } else if (resposta.status === 400) {
      return resposta.data;
    }

    return 'Um erro inesperado ocorreu!';
  }

  delete(id: number): Promise<boolean> {
    throw new Error("Method not implemented.");
  }

  async deleteMany(ids: number[]): Promise<boolean> {
    const resposta = await Axios.delete('/materiais/ferros', {
      params: { ids: ids.join(',') }
    });

    return resposta.status === 200;
  }
}

export default ApiFerroRepository;
