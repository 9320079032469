import React, { FunctionComponent } from 'react';
import { Container, Content, Header } from 'rsuite';

interface Props {
  title?: string;
}

const PageLayout: FunctionComponent<Props> = (props) => (
  <Container className="container">
    {props.title && (
      <Header className="container-header">
        <h2>{props.title}</h2>
      </Header>
    )}
    <Content className="container-content">
      {props.children}
    </Content>
  </Container>
);

export default PageLayout;