import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import NotFoundPage from '../NotFoundPage';
import { AgregadosPage } from './agregados';
import ArmacoesPage from './armacoes/ArmacoesPage';
import { FerrosPage } from './ferro';
import LajotasPage from './lajotas/LajotasPage';

export const MateriaisRouter = (_props: any) => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route path={`${path}/ferro`} component={FerrosPage} />
      <Route path={`${path}/agregados`} component={AgregadosPage} />
      <Route path={`${path}/lajotas`} component={LajotasPage} />
      <Route path={`${path}/armacoes`} component={ArmacoesPage} />
      <Route path="*" component={NotFoundPage}/>
    </Switch>
  );
}
