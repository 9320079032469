import { useCallback, useEffect, useState } from "react";
import { Service } from "../types/service";

const useReadModel = <T, U extends number | number[]>(read: (id: U) => Promise<T | null>, id?: U) => {
  const [resultado, setResultado] = useState<Service<T>>({ status: 'loading' });

  const refresh = useCallback(() => {
    if (id) {
      read(id).then((resposta) => {
        if (resposta) {
          setResultado({ status: 'loaded', payload: resposta  });
        } else {
          setResultado({ status: 'error', error: new Error('Dados não encontrados') });
        }
      });
    } else {
      setResultado({ status: 'loading' });
    }
  }, [id, read]);

  useEffect(() => {
    refresh();
  }, [refresh, read, id]);

  return { model: resultado, refresh };
};

export default useReadModel;
