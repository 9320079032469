import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { ControlLabel, FormControl, FormGroup } from 'rsuite';
import InputCliente from '../../components/InputCliente';
import UncontrolledFormInput from '../../components/UncontrolledFormInput';
import Projeto from '../../types/projeto';

const InsercaoProjetoForm = () => {
  const form = useFormContext<Projeto>();

  return (
    <>
      <FormGroup>
        <ControlLabel>Nome</ControlLabel>
        <FormControl
          autoFocus
          name="nome"
          placeholder="ex: Casa do João"
          inputRef={form.register({ required: 'Este campo é necessário' })}
          errorMessage={form.errors.nome && form.errors.nome.message}
        />
      </FormGroup>
      <FormGroup>
        <ControlLabel>Cliente</ControlLabel>
        <Controller
          name="idCliente"
          defaultValue={null}
          render={({ value, onChange }) => (
            <UncontrolledFormInput errorMessage={form.errors.idCliente?.message}>
              <InputCliente value={value} onChange={onChange} />
            </UncontrolledFormInput>
          )}
        />
      </FormGroup>
    </>
  );
};

export default InsercaoProjetoForm;
