import React, { useContext, useState } from 'react';
import { Button } from 'rsuite';
import AdicaoModal from '../../components/AdicaoModal';
import { AppContext } from '../../context';
import { usuarioFormToModel } from '../../types/usuario';
import FormUsuario, { FormUsuarioContext } from './FormUsuario';

type Props = {
  refresh(): void,
}

const AdicaoUsuarioButton = ({ refresh }: Props) => {
  const [show, setShow] = useState(false);
  const context = useContext(AppContext);
  const repository = context.repositoryFactory.getUsuarioRepository(context);

  const handleClose = (doRefresh: boolean = false) => {
    setShow(false);
    if (doRefresh) {
      refresh();
    }
  }

  return (
    <>
      <Button appearance="primary" onClick={() => setShow(true)}>Criar novo</Button>
      
      <FormUsuarioContext.Provider value={{ edicao: false }}>
        <AdicaoModal
          stringNovo="Novo"
          nome="usuário"
          show={show}
          onClose={handleClose}
          element={FormUsuario}
          create={repository.create}
          formToModel={usuarioFormToModel}
        />
      </FormUsuarioContext.Provider>
    </>
  );
}

export default AdicaoUsuarioButton;
