import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import Projeto from '../../types/projeto';
import InsercaoProjetoForm from './InsercaoProjetoForm';

const EdicaoProjetoForm = () => {
  const { control } = useFormContext<Projeto>();

  return (
    <>
      <Controller name="id" control={control} render={() => <></>} />
      <InsercaoProjetoForm />
    </>
  );
};

export default EdicaoProjetoForm;
