import { useCallback, useState } from "react";
import { Alert } from "rsuite";
import { DeleteManyFunction } from "../types/crud-functions";

const useDeleteMany = (deleteMany: DeleteManyFunction, callback?: (submitResult: boolean) => void) => {
  const [deleting, setDeleting] = useState(false);

  const deleteFunction = useCallback(async (ids: number[]) => {
    let deleteResult = false;
    setDeleting(true);

    const resposta = await deleteMany(ids);

    if (resposta) {
      Alert.success('Remoção realizada!');
      deleteResult = true;
    } else {
      Alert.error('Falha ao remover!');
      deleteResult = false;
    }

    setDeleting(false);

    if (callback) {
      callback(deleteResult);
    }
  }, [callback, deleteMany]);

  return { deleteMany: deleteFunction, deleting };
};

export default useDeleteMany;
