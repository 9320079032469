import React, { useContext, useEffect, useState } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { Button, ButtonToolbar, ControlLabel, Divider, FormControl, FormGroup, Input, InputGroup } from 'rsuite';
import FormFerro from '../../../components/FormFerro';
import InputLaje from '../../../components/InputLaje';
import UncontrolledFormInput from '../../../components/UncontrolledFormInput';
import { AppContext } from '../../../context';
import ComodoProjeto from '../../../types/comodo-projeto';
import Ferro from '../../../types/ferro';
import Laje from '../../../types/laje';
import { LajePadraoContext } from './AdicaoComodoButton';

const InsercaoComodoForm = () => {
  const form = useFormContext<ComodoProjeto>();
  const [ferrosCriados, setFerrosCriados] = useState<number[]>([]);
  const context = useContext(AppContext);
  const idLajePadrao = useContext(LajePadraoContext);
  const lajeRepository = context.repositoryFactory.getLajeRepository(context);
  
  const append = () => {
    setFerrosCriados([...ferrosCriados, ferrosCriados.length + 1]);
  }

  const remove = (index: number) => {
    setFerrosCriados([...ferrosCriados.slice(0, index), ...ferrosCriados.slice(index + 1)]);
  }

  const ferros = useWatch<Ferro[]>({ name: 'ferros', control: form.control });
  useEffect(() => {
    if (ferros) {
      setFerrosCriados(ferros.map((f, index) => index));
    }
  }, [ferros]);

  const [laje, setLaje] = useState<Laje | null>(null);
  const idLaje = useWatch<number | null | undefined>({ control: form.control, name: 'idLaje', defaultValue: idLajePadrao || undefined });
  
  useEffect(() => {
    if (idLaje) {
      lajeRepository.read(idLaje).then(laje => {
        setLaje(laje);
      });
    }
  }, [idLaje, lajeRepository]);
  
  return (
    <>
      <Controller name="idProjeto" control={form.control} render={() => <></>} />
      <FormGroup>
        <ControlLabel>Nome</ControlLabel>
        <FormControl
          autoFocus
          name="nome"
          placeholder="ex: Banheiro"
          inputRef={form.register({ required: 'Este campo é necessário' })}
          errorMessage={form.errors.nome && form.errors.nome.message}
        />
      </FormGroup>
      <FormGroup>
        <ControlLabel>Laje</ControlLabel>
        <Controller
          name="idLaje"
          defaultValue={form.getValues().idLaje || idLajePadrao}
          rules={{ required: 'Este campo é necessário' }}
          render={({ value, onChange }) => (
            <UncontrolledFormInput errorMessage={form.errors.idLaje?.message}>
              <InputLaje laje={laje} value={value} onChange={onChange} />
            </UncontrolledFormInput>
          )}
        />
      </FormGroup>
      <FormGroup>
        <ControlLabel>Vão</ControlLabel>
        <InputGroup>
          <UncontrolledFormInput errorMessage={form.errors.vao?.message}>
            <Input
              name="vao"
              placeholder="ex: 1.25"
              inputRef={form.register({
                required: 'Este campo é necessário',
                validate: number => (!isNaN(number) && Number(number) >= 0) || 'Deve ser um número positivo',
              })}
            />
          </UncontrolledFormInput>
          <InputGroup.Addon>m</InputGroup.Addon>
        </InputGroup>
      </FormGroup>
      <FormGroup>
        <ControlLabel>Comprimento</ControlLabel>
        <InputGroup>
          <UncontrolledFormInput errorMessage={form.errors.comprimento?.message}>
            <Input
              name="comprimento"
              placeholder="ex: 0.75"
              inputRef={form.register({
                required: 'Este campo é necessário',
                validate: number => (!isNaN(number) && Number(number) >= 0) || 'Deve ser um número positivo',
              })}
            />
          </UncontrolledFormInput>
          <InputGroup.Addon>m</InputGroup.Addon>
        </InputGroup>
      </FormGroup>
      {laje && laje.tipo === 'T' && (
        <>
          <FormGroup>
            <ControlLabel>Apoio</ControlLabel>
            <InputGroup>
              <UncontrolledFormInput errorMessage={form.errors.apoio?.message}>
                <Input
                  name="apoio"
                  placeholder="ex: 0.15"
                  defaultValue="0.15"
                  inputRef={form.register({
                    required: 'Este campo é necessário',
                    validate: number => (!isNaN(number) && Number(number) >= 0) || 'Deve ser um número positivo',
                  })}
                />
              </UncontrolledFormInput>
              <InputGroup.Addon>m</InputGroup.Addon>
            </InputGroup>
          </FormGroup>
          {ferrosCriados.map((item, index) => (
            <div key={index}>
              <Divider>Ferro #{index + 1}</Divider>
              <FormFerro
                prefix={`ferros.${index}.`}
                defaultValue={(ferros && ferros[index]?.id) || null}
                errors={form.errors.ferros && form.errors.ferros[index]}
                form={form}
                mostrarComprimento
              />
            </div>
          ))}
          <Divider />
          <div style={{ marginTop: '15px' }}>
            <ButtonToolbar>
              <Button appearance="ghost" onClick={() => append()}>Adicionar Ferro</Button>
              { ferrosCriados.length > 0 && (
                <Button appearance="ghost" color="red" onClick={() => remove(ferrosCriados.length - 1)}>Remover Ferro</Button>
              )}
            </ButtonToolbar>
          </div>
        </>
      )}
    </>
  );
};

export default InsercaoComodoForm;
