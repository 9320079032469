import React, { useContext, useEffect, useState } from 'react';
import { Input, Table } from 'rsuite';
import { AppContext } from '../context';
import SelecaoModal from './SelecaoModal';

type Props = {
  value: number | null,
  onChange: (id: number) => void,
  tipo: string,
};

const InputLajota = ({ value, onChange, tipo }: Props) => {
  const [showModal, setShowModal] = useState(false);
  const [nome, setNome] = useState('Selecionar lajota...');
  const context = useContext(AppContext);
  const lajotaRepository = context.repositoryFactory.getLajotaRepository(context);

  useEffect(() => {
    if (value) {
      setNome('Carregando...');
      lajotaRepository.read(value).then(lajota => {
        if (lajota) {
          setNome(lajota.nome);
        }
      });
    } else {
      setNome('Selecionar lajota...');
    }
  }, [value, lajotaRepository]);

  return (
    <>  
      <Input onClick={() => setShowModal(true)} readOnly value={nome} style={{ cursor: 'pointer' }} />
      <SelecaoModal
        show={showModal}
        buscarParaSelecao={lajotaRepository.buscarParaSelecao}
        param={tipo}
        onClose={selected => {
          if (selected) {
            onChange(selected.id);
          }
          setShowModal(false);
        }}
      >
        <Table.Column width={50}>
          <Table.HeaderCell>#</Table.HeaderCell>
          <Table.Cell dataKey="id" />
        </Table.Column>
        <Table.Column width={180}>
          <Table.HeaderCell>Nome</Table.HeaderCell>
          <Table.Cell dataKey="nome" />
        </Table.Column>
        <Table.Column>
          <Table.HeaderCell>Tipo</Table.HeaderCell>
          <Table.Cell dataKey="tipo" />
        </Table.Column>
      </SelecaoModal>
    </>
  );
};

export default InputLajota;
