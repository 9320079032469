import React, { useContext, useEffect, useState } from 'react';
import { Input, Table } from 'rsuite';
import { AppContext } from '../context';
import SelecaoModal from './SelecaoModal';

type Props = {
  value: number | null,
  onChange: (id: number) => void,
};

const InputArmacao = ({ value, onChange }: Props) => {
  const [showModal, setShowModal] = useState(false);
  const [nome, setNome] = useState('Selecionar armação...');
  const context = useContext(AppContext);
  const armacaoRepository = context.repositoryFactory.getArmacaoRepository(context);

  useEffect(() => {
    if (value) {
      setNome('Carregando...');
      armacaoRepository.read(value).then(armacao => {
        if (armacao) {
          setNome(armacao.nome);
        }
      });
    } else {
      setNome('Selecionar armação...');
    }
  }, [value, armacaoRepository]);

  return (
    <>  
      <Input onClick={() => setShowModal(true)} readOnly value={nome} style={{ cursor: 'pointer' }} />
      <SelecaoModal
        show={showModal}
        buscarParaSelecao={armacaoRepository.buscarParaSelecao}
        onClose={selected => {
          if (selected) {
            onChange(selected.id);
          }
          setShowModal(false);
        }}
      >
        <Table.Column width={50}>
          <Table.HeaderCell>#</Table.HeaderCell>
          <Table.Cell dataKey="id" />
        </Table.Column>
        <Table.Column width={180}>
          <Table.HeaderCell>Nome</Table.HeaderCell>
          <Table.Cell dataKey="nome" />
        </Table.Column>
      </SelecaoModal>
    </>
  );
};

export default InputArmacao;
