import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import Agregado from '../../../types/agregado';
import InsercaoAgregadoForm from './InsercaoAgregadoForm';

const EdicaoAgregadoForm = () => {
  const { control } = useFormContext<Agregado>();

  return (
    <>
      <Controller name="id" control={control} render={() => <></>} />
      <InsercaoAgregadoForm />
    </>
  );
};

export default EdicaoAgregadoForm;
