import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import NotFoundPage from '../NotFoundPage';
import OrcamentosPage from './OrcamentosPage';
import OrcamentoPage from './single/OrcamentoPage';

export const OrcamentosRouter = (_props: any) => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={`${path}/:id`} component={OrcamentoPage} />
      <Route exact path={path} component={OrcamentosPage} />
      <Route path="*" component={NotFoundPage}/>
    </Switch>
  );
}
