import React from 'react';
import { Table } from 'rsuite';

const DateCell = ({ precision = 2, rowData, dataKey, ...props }: any) => (
  <Table.Cell {...props}>
    {rowData[dataKey] && new Date(rowData[dataKey]).toLocaleDateString('pt-BR')}
  </Table.Cell>
);

export default DateCell;
