import React from 'react';
import { useFormContext } from 'react-hook-form';
import { ControlLabel, FormControl, FormGroup, Input, InputGroup } from 'rsuite';
import UncontrolledFormInput from '../../../components/UncontrolledFormInput';
import Despesa from '../../../types/despesa';

const DespesaForm = () => {
  const form = useFormContext<Despesa>();

  return (
    <>
      <FormGroup>
        <ControlLabel>Descrição</ControlLabel>
        <UncontrolledFormInput errorMessage={form.errors.descricao?.message}>
          <Input
            autoFocus
            name="descricao"
            placeholder="ex: Contador"
            inputRef={form.register({ required: 'Este campo é necessário' })}
          />
        </UncontrolledFormInput>
      </FormGroup>
      <FormGroup>
        <ControlLabel>Valor</ControlLabel>
        <InputGroup style={{ width: '120px' }}>
          <InputGroup.Addon>R$</InputGroup.Addon>
          <FormControl
            name="valor"
            placeholder="ex: 700.00"
            inputRef={form.register({
              required: 'Este campo é necessário',
              validate: value => !isNaN(value) || 'Deve ser um número',
            })}
            errorMessage={form.errors.valor && form.errors.valor.message}
          />
        </InputGroup>
      </FormGroup>
    </>
  );
};

export default DespesaForm;
