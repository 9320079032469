import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import Laje from '../../../types/laje';
import InsercaoLajeForm from './InsercaoLajeForm';

const EdicaoLajeForm = () => {
  const { control } = useFormContext<Laje>();

  return (
    <>
      <Controller name="id" control={control} render={() => <></>} />
      <InsercaoLajeForm />
    </>
  );
};

export default EdicaoLajeForm;
