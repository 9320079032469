import Axios from "axios";
import Indices from "../../types/indices";
import { ProducaoRepository } from "../interfaces/producao-repository";

class ApiProducaoRepository implements ProducaoRepository {
  async buscar(): Promise<Indices | null> {
    const resposta = await Axios.get('/financeiro/indice');
    if (resposta.status === 200) {
      return resposta.data;
    } else {
      return null;
    }
  }

  async atualizar(indices: Indices): Promise<string | true | object> {
    const resposta = await Axios.put('/financeiro/indice', indices);

    if (resposta.status === 200) {
      return true;
    } else if (resposta.status === 400) {
      return resposta.data;
    } else if (resposta.status === 500) {
      return resposta.data.message;
    }

    return 'Erro inesperado!';
  }

}

export default ApiProducaoRepository;
