import React from 'react';
import { ErrorMessage } from 'rsuite';

type Props = {
  errorMessage?: string,
};

const UncontrolledFormInput = ({ children, errorMessage }: React.PropsWithChildren<Props>) => {
  return (
    <div className="rs-form-control-wrapper">
      {children}
      <ErrorMessage
        className="rs-error-message-wrapper rs-form-control-message-wrapper"
        show={!!errorMessage}
        placement="bottomStart"
      >{errorMessage}</ErrorMessage>
    </div>
  );
}

export default UncontrolledFormInput;
