import Axios from "axios";
import InformacoesHome from "../../types/informacoes-home";
import { HomeRepository } from "../interfaces/home-repository";

class ApiHomeRepository implements HomeRepository {
  async buscarInformacoes(): Promise<InformacoesHome | null> {
    const resposta = await Axios.get('/home');

    if (resposta.status === 200) {
      return resposta.data;
    }

    return null;
  }
}

export default ApiHomeRepository;
