import React from 'react';
import { Controller, DeepMap, FieldError, UseFormMethods } from 'react-hook-form';
import { Col, ControlLabel, FormGroup, Grid, Input, Row } from 'rsuite';
import { FerroComposicao } from '../types/ferro';
import InputFerro from './InputFerro';
import UncontrolledFormInput from './UncontrolledFormInput';

type Props = {
  prefix?: string,
  defaultValue?: number | null,
  errors?: DeepMap<FerroComposicao, FieldError>,
  form: UseFormMethods<any>,
  mostrarComprimento?: boolean,
}

const FormFerro = ({ prefix, defaultValue = null, errors, form, mostrarComprimento = false }: Props) => {
  const { vao, apoio } = form.getValues();
  
  let comprimentoPadrao: number | undefined = undefined;
  if (vao && !isNaN(Number(vao)) && apoio && !isNaN(Number(apoio))) {
    const apoioNumber = Number(apoio);
    const dobra = apoioNumber === 0.15 ? 0.30
                : apoioNumber === 0.12 ? 0.22
                : apoioNumber === 0.10 ? 0.20
                : undefined;
    comprimentoPadrao = dobra ? Number(vao) + dobra : undefined;
  }

  return (
    <>
      <FormGroup>
        <ControlLabel>Ferro</ControlLabel>
        <Controller
          name={`${prefix}id`}
          control={form.control}
          defaultValue={defaultValue}
          rules={{ required: 'Este campo é necessário' }}
          render={({ value, onChange }) => (
            <UncontrolledFormInput errorMessage={errors?.id?.message}>
              <InputFerro value={value} onChange={onChange} />
            </UncontrolledFormInput>
          )}
        />
      </FormGroup>
      <Grid fluid style={{ padding: '0', marginBottom: '15px' }}>
        <Row>
          <Col xs={12}>
            <ControlLabel>Quantidade</ControlLabel>
            <UncontrolledFormInput errorMessage={errors?.quantidade?.message}>
              <Input
                name={`${prefix}quantidade`}
                style={{ maxWidth: '100%' }}
                placeholder="ex: 3"
                inputRef={form.register({
                  required: 'Este campo é necessário',
                  pattern: {
                    value: /^\+?(0|[1-9]\d*)$/,
                    message: 'Deve ser um número inteiro',
                  }
                })}
              />
            </UncontrolledFormInput>
          </Col>
          {mostrarComprimento && (
            <Col xs={12}>
              <ControlLabel>Comprimento</ControlLabel>
              <UncontrolledFormInput errorMessage={errors?.comprimento?.message}>
                <Input
                  name={`${prefix}comprimento`}
                  defaultValue={comprimentoPadrao?.toFixed(2)}
                  style={{ maxWidth: '100%' }}
                  placeholder="ex: 2.75"
                  inputRef={form.register({
                    required: 'Este campo é necessário',
                    validate: number => !isNaN(number) || 'Deve ser um número',
                  })}
                />
              </UncontrolledFormInput>
            </Col>
          )}
        </Row>
      </Grid>
    </>
  );
}

export default FormFerro;
