import React from 'react';
import { Table } from 'rsuite';

const MoneyCell = ({ rowData, dataKey, ...props }: any) => (
  <Table.Cell {...props}>
    {rowData[dataKey] && `R$ ${(+rowData[dataKey]).toFixed(2)}`}
  </Table.Cell>
);

export default MoneyCell;
