import React from 'react';
import { IconButton, Table, TableCellProps } from 'rsuite';
import Icon, { IconNames } from 'rsuite/lib/Icon';

type ActionCellProps = TableCellProps & {
  icon: IconNames,
  onClick: (data: any) => void,
};

const ActionCell = ({ rowData, onClick, icon, dataKey, ...props }: ActionCellProps) => {
  return (
    <Table.Cell {...props} style={{ padding: 0, lineHeight: '46px' }}>
      <IconButton
        appearance="default"
        onClick={() => onClick((rowData as any)[dataKey as string])}
        icon={<Icon icon={icon} />}
      />
    </Table.Cell>
  );
}

export default ActionCell;
