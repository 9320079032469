import React from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { ClienteForm } from '../../types/cliente';
import InsercaoEmpresaForm from './InsercaoEmpresaForm';
import InsercaoPessoaForm from './InsercaoPessoaForm';

const EdicaoClienteForm = () => {
  const form = useFormContext<ClienteForm>();
  const watchTipo = useWatch({  name: 'tipo', control: form.control });

  let body;
  if (watchTipo === "empresa") {
    body = <InsercaoEmpresaForm />
  } else if (watchTipo === "pessoa") {
    body = <InsercaoPessoaForm />;
  } else {
    body = <span/>;
  }

  return (
    <>
      <input type="hidden" name="tipo" ref={form.register} />
      <Controller name="id" control={form.control} render={() => <></>} />
      <Controller name={`${watchTipo}.id`} control={form.control} render={() => <></>} />
      <Controller name={`${watchTipo}.endereco.id`} control={form.control} render={() => <></>} />
      {body}
    </>
  )
};

export default EdicaoClienteForm;
