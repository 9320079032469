import React, { useContext, useRef, useState } from 'react';
import { Table } from 'rsuite';
import AdicaoModal from '../../../components/AdicaoModal';
import BaseTabela from '../../../components/BaseTabela';
import { AdicaoModalFunction, BaseTabelaProps, RemocaoModalFunction } from '../../../components/BaseTabela/BaseTabela';
import EdicaoDrawer from '../../../components/EdicaoDrawer';
import PageLayout from '../../../components/PageLayout';
import RemocaoModal from '../../../components/RemocaoModal';
import { AppContext, AppContextProps } from '../../../context';
import { FerroExibicao, ferroFormToModel } from '../../../types/ferro';
import { Pagina } from '../../../types/pagina';
import RequisicaoPagina from '../../../types/requisicao-pagina';
import { FerroRepository } from '../../../repositories/interfaces/ferro-repository';
import EdicaoFerroForm from './EdicaoFerroForm';
import InsercaoFerroForm from './InsercaoFerroForm';
import NumberCell from '../../../components/NumberCell';
import MoneyCell from '../../../components/MoneyCell';


class TabelaFerros extends BaseTabela<FerroExibicao> {
  private ferroRepository: FerroRepository;

  constructor(props: BaseTabelaProps, context: AppContextProps) {
    super(props);
    this.ferroRepository = context.repositoryFactory.getFerroRepository(context);
  }

  buscarParaExibicao(requisicao: RequisicaoPagina) : Promise<Pagina<FerroExibicao>> {
    return this.ferroRepository.buscarParaExibicao(requisicao);
  }

  exibirAdicaoModal: AdicaoModalFunction = (show, onClose) => (
    <AdicaoModal
      nome="ferro"
      show={show}
      onClose={onClose}
      element={InsercaoFerroForm}
      create={this.ferroRepository.create}
      formToModel={f => f}
    />
  );

  exibirRemocaoModal: RemocaoModalFunction = (show, selecionados, onClose) => (
    <RemocaoModal
      show={show}
      selecionados={selecionados}
      onClose={onClose}
      deleteMany={this.ferroRepository.deleteMany}
      nome={["ferro", "ferros"]}
    />
  );
}

const FerrosPage = () => {
  const [idEdicao, setIdEdicao] = useState<number | undefined>(undefined);
  const [editando, setEditando] = useState(false);

  const context = useContext(AppContext);
  const repository = context.repositoryFactory.getFerroRepository(context);

  const ref = useRef<TabelaFerros>(null);

  return (
    <PageLayout title="Materiais - Ferro">
      <TabelaFerros
        ref={ref}
        ordenarPorPadrao="bitola"
        tipoOrdenacaoPadrao="asc"
        handleEdit={id => { setIdEdicao(id); setEditando(true); }}
      >
        <Table.Column sortable>
          <Table.HeaderCell>Bitola</Table.HeaderCell>
          <NumberCell dataKey="bitola" />
        </Table.Column>
        <Table.Column>
          <Table.HeaderCell>kg/m</Table.HeaderCell>
          <NumberCell dataKey="kgPorMetro" />
        </Table.Column>
        <Table.Column width={120} sortable>
          <Table.HeaderCell>Preço unitário</Table.HeaderCell>
          <MoneyCell dataKey="precoUnitario" />
        </Table.Column>
      </TabelaFerros>

      <EdicaoDrawer
        show={editando}
        id={idEdicao}
        onClose={(refresh: boolean = false) => {
          setEditando(false);
          setIdEdicao(undefined);
          if (refresh) {
            ref.current?.refresh();
          }
        }}
        update={repository.update}
        read={repository.read}
        delete={repository.deleteMany}
        formToModel={ferroFormToModel}
        modelToForm={v => v}
        formElement={EdicaoFerroForm}
      />
    </PageLayout>
  );
}

export default FerrosPage;
