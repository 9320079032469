import Empresa from "./empresa";
import Endereco, { enderecoFormToModel } from "./endereco";
import Pessoa from "./pessoa";

export type TipoCliente = "empresa" | "pessoa";

type ClienteEmpresa = {
  id?: number,
  tipo: "empresa",
  empresa: Empresa,
};

type ClientePessoa = {
  id?: number,
  tipo: "pessoa",
  pessoa: Pessoa,
};

type Cliente = ClientePessoa | ClienteEmpresa;

export type ClienteExibicao = {
  id: number,
  tipo: TipoCliente,
  nome: string,
  cidade?: string,
  numProjetos: number,
  telefone?: string,
  cpfCnpj?: string,
};

export type ClienteForm = {
  id?: number,
  tipo: string,
  pessoa?: {
    id?: number,
    nome: string,
    dtNascimento: Date | null,
    rg?: string,
    cpf?: string,
    telefone?: string,
    email?: string,
    endereco?: Endereco,
  },
  empresa?: {
    id?: number,
    cnpj?: string,
    icms?: string,
    nome: string,
    telefone?: string,
    contato?: {
      nome?: string,
      telefone?: string,
      email?: string,
    }
    endereco?: Endereco,
  },
};

export type ClienteSelecao = {
  id: number,
  nome: string,
  cpfCnpj: string,
};

export const clienteModelToForm = (cliente: Cliente): ClienteForm => {
  if (cliente.tipo === "pessoa") {
    let data = cliente.pessoa.dtNascimento?.split('/')?.reverse()?.join('-');
    
    return {
      id: cliente.id,
      tipo: cliente.tipo,
      pessoa: {
        ...cliente.pessoa,
        dtNascimento: data ? new Date(data) : null,
      }
    }
  } else {
    return {
      id: cliente.id,
      tipo: cliente.tipo,
      empresa: cliente.empresa,
    };
  }
}

export const clienteFormToModel = (values: ClienteForm): Cliente | null => {
  if (values.tipo === "pessoa" && values.pessoa) {
    const pessoa = values.pessoa;
    const cpf = pessoa.cpf?.replace(/\D/g, '');
    return {
      id: values.id,
      tipo: values.tipo,
      pessoa: {
        ...pessoa,
        dtNascimento: pessoa.dtNascimento?.toLocaleDateString('pt-BR'),
        cpf: cpf === '' ? undefined : cpf,
        rg: pessoa.rg === '' ? undefined : pessoa.rg,
        telefone: pessoa.telefone?.replace(/\D/g, ''),
        endereco: pessoa.endereco ? enderecoFormToModel(pessoa.endereco) : undefined,
      },
    };
  } else if (values.tipo === "empresa" && values.empresa) {
    const empresa = values.empresa;
    const cnpj = empresa.cnpj?.replace(/\D/g, '');
    return {
      id: values.id,
      tipo: values.tipo,
      empresa: {
        ...empresa,
        cnpj: cnpj === '' ? undefined : cnpj,
        icms: empresa.icms === '' ? undefined : empresa.icms,
        telefone: empresa.telefone?.replace(/\D/g, ''),
        contato: {
          ...empresa.contato,
          telefone: empresa.contato?.telefone?.replace(/\D/g, ''),
        },
        endereco: empresa.endereco ? enderecoFormToModel(empresa.endereco) : undefined,
      },
    };
  }

  return null;
}

export default Cliente;
