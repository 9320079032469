import React, { useContext, useEffect, useState } from 'react';
import { Message, Placeholder } from 'rsuite';
import { AppContext } from '../../../context';
import { ProjetoConsulta } from '../../../types/projeto';

type State = {
  projeto: ProjetoConsulta | null,
  carregando: boolean,
  erro: string,
};

const RelatorioProjeto = ({ id }: { id: number} ) => {
  const [projeto, setProjeto] = useState<ProjetoConsulta | null>(null);
  const [carregando, setCarregando] = useState(true);
  const [erro, setErro] = useState<string | null>(null);
  // const [state, setState] = useState<State>({ projeto: null, carregando: true, erro: '' });
  const context = useContext(AppContext);

  useEffect(() => {
    let isMounted = true;
    context.repositoryFactory.getProjetoRepository(context).consultar(id).then(
      projeto => {
        if (isMounted) {
          setProjeto(projeto);
          setCarregando(false);
        }
      }
    ).catch((error: Error) => {
      if (isMounted) {
        setErro(error.message);
        setCarregando(false);
      }
    });
    return () => { isMounted = false };
  }, [id, context]);

  if (carregando) {
    return <Placeholder.Paragraph rows={10} active />;
  } else if (erro) {
    return (
      <Message
        showIcon
        type="error"
        title="Erro"
        description={erro}
      />
    );
  }

  return (
    <div className="relatorio-projeto">
      <div className="comodos-relatorio-projeto">
        <div className="comodos">
          {projeto?.comodos.map(comodo => (
            <div className="comodo">
              <div className="header-comodo">
                <span style={{ padding: '5px 10px' }}>{comodo.nomeComodo}</span>
              </div>
                  
                <div className="corpo-comodo">
                  <div className="topo-corpo-comodo">
                    <span className="laje-comodo">{comodo.nomeLaje}</span>
                    <p className="custos-comodo">
                      <span className="custo">R$ {(+comodo.valorReferencia).toFixed(2)}</span>
                      <span style={{ whiteSpace: 'nowrap' }}>R$ {(+comodo.valorReferenciaMetroQuadrado).toFixed(2)}/m²</span>
                    </p>
                  </div>

                  <div className="divisoes-comodo">
                    <div className="divisao-comodo">
                      <p className="titulo-comodo">Informações da Laje</p>
                      {comodo.tipoLaje === 'T' ? 'Treliçada' : 'Convencional'} - {comodo.forroPisoLaje === 'P' ? 'Piso' : 'Forro'}<br/>
                      {comodo.nomeLajota}<br/>
                      {comodo.sobrecargaLaje} kg/m²<br/>
                      {comodo.tipoLaje === 'T' && (<span>Armação {comodo.nomeArmacao}</span>)}<br/>
                    </div>
                    <div className="divisao-comodo">
                      <p className="titulo-comodo">Total de lajotas</p>
                      <p>{comodo.lajotasInteira} inteiras</p>
                      {comodo.lajotasChanfrada > 0 && (<p>{comodo.lajotasChanfrada} chanfradas</p>)}
                      {comodo.lajotasMetade > 0 && (<p>{comodo.lajotasMetade} meias</p>)}
                    </div>
                  </div>

                  <p className="titulo-comodo">Medidas</p>

                  <div className="tabela-comodo-wrapper">
                    <table className="tabela-comodo">
                      <thead>
                        <tr>
                          <th>Vão</th>
                          <th>Comp.</th>
                          {comodo.apoioComodo && (<th>Apoio</th>)}
                          <th>Vigotas</th>
                          <th>m²</th>
                          {comodo.tipoLaje === 'T' && (
                            <>
                              <th>SV</th>
                              <th>LV</th>
                              <th>SC</th>
                            </>
                          )}
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>{comodo.vaoComodo} m</td>
                          <td>{comodo.comprimentoComodo} m</td>
                          {comodo.apoioComodo && (<td>{comodo.apoioComodo} m</td>)}
                          <td>{comodo.numVigotas}</td>
                          <td>{comodo.metrosQuadrados}</td>
                          {comodo.tipoLaje === 'T' && (
                            <>
                              <td>{comodo.restoVao} m</td>
                              <td>{comodo.lajotasAoLongoDoVao}</td>
                              <td>{comodo.restoComprimento} m</td>
                            </>
                          )}
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <div className="tabelas-comodo">
                    <aside>
                      <p className="titulo-comodo">Ferro principal</p>
                      <table className="tabela-comodo">
                        <thead>
                          <tr>
                            <th>Qtd.</th>
                            <th>Bitola</th>
                            <th>Corte</th>
                          </tr>
                        </thead>
                        <tbody>
                          {comodo.ferrosPrincipais.map(ferro => (
                            <tr key={ferro.id}>
                              <td>{ferro.quantidade}</td>
                              <td>{ferro.bitola} mm</td>
                              <td>{ferro.comprimento} m</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </aside>
                  
                    {comodo.ferrosAuxiliares && comodo.ferrosAuxiliares.length > 0 && (
                      <aside>
                        <p className="titulo-comodo">Ferro auxiliar</p>
                        <table className="tabela-comodo">
                          <thead>
                            <tr>
                              <th>Qtd.</th>
                              <th>Bitola</th>
                              <th>Corte</th>
                            </tr>
                          </thead>
                          <tbody>
                            {comodo.ferrosAuxiliares.map(ferro => (
                              <tr key={ferro.id}>
                                <td>{ferro.quantidade}</td>
                                <td>{ferro.bitola} mm</td>
                                <td>{ferro.comprimento} m</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </aside>
                    )}
                  </div>
                </div>
              </div>
          ))}
        </div>
      </div>
      <div className="resumo-relatorio-projeto">
        <h3>Resumo</h3>
        <div className="cards-resumo">
          <div className="card-resumo" style={{ width: '100%' }}>
            <p>Custo de produção total</p>
            <p>
              <span className="stat-card-resumo">R$ {(projeto?.valorProducaoTotal)?.toFixed(2)}</span><br/>
              R$ {(projeto?.valorReferenciaMetroQuadrado)?.toFixed(2)}/m²
            </p>
          </div>
          <div className="card-resumo">
            <p>Área total</p>
            <p>
              <span className="stat-card-resumo">{(projeto?.metrosQuadrados)?.toFixed(2)} m²</span><br/>
            </p>
          </div>
          <div className="card-resumo">
            <p>Núm. vigotas</p>
            <p>
              <span className="stat-card-resumo">{projeto?.numVigotas}</span><br/>
            </p>
          </div>
        </div>

        <h4>Lajes</h4>
        <table className="tabela-resumo">
          <thead>
            <tr>
              <th>Laje</th>
              <th>Corte</th>
              <th>Total m²</th>
              <th>Referência</th>
            </tr>
          </thead>
          <tbody>
            {projeto?.resumo.map(laje => (
              <tr key={laje.nome}>
                <td>
                  <b>{laje.nome}</b><br/>
                  {laje.forroPiso === 'P' ? 'Piso' : 'Forro'}<br/>
                  {laje.sobrecarga} kg/m²<br/>
                  {laje.armacao}
                </td>
                <td>
                  {laje.comodos.map((comodo, index) => (
                    <>
                      {index > 0 && <hr/>}
                      <b>{comodo.nome}</b><br/>
                      {(+comodo.corte).toFixed(2)} m
                    </>
                  ))}
                </td>
                <td>
                  {(laje.totalMetrosQuadrados.toFixed(2))}
                </td>
                <td>
                  R$ {laje.referencia.toFixed(2)}<br/>
                  R$ {laje.referenciaMetroQuadrado.toFixed(2)}/m²
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        <h4>Lajotas</h4>
        <table className="tabela-resumo">
          <thead>
            <tr>
              <th>Lajota</th>
              <th>Inteira</th>
              <th>Chanfrada</th>
              <th>Meia</th>
            </tr>
          </thead>
          <tbody>
            {(projeto?.lajotas.map(lajota => (
              <tr key={lajota.nome}>
                <td>{lajota.nome}</td>
                <td>{lajota.inteira}</td>
                <td>{lajota.chanfrada}</td>
                <td>{lajota.metade}</td>
              </tr>
            )))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default RelatorioProjeto;
