import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import ComodoProjeto from '../../../types/comodo-projeto';
import InsercaoComodoForm from './InsercaoComodoForm';

const EdicaoComodoForm = () => {
  const { control } = useFormContext<ComodoProjeto>();

  return (
    <>
      <Controller name="id" control={control} render={() => <></>} />
      <InsercaoComodoForm />
    </>
  );
};

export default EdicaoComodoForm;
