import React, { useEffect, useState } from 'react';
import { Input, InputPicker } from 'rsuite';
import useDebounce from '../../utils/useDebounce';

export type Props = {
  definirFiltro(filtro: string, filtrarPor: string): void,
  opcoes: Array<Record<string, any>>,
};

const FiltroForm = ({ definirFiltro, opcoes }: Props) => {
  const [ filtro, setFiltro ] = useState('');
  const [ filtrarPor, setFiltrarPor ] = useState(opcoes[0].value);

  const filtrarPorLabel = opcoes.filter(o => o.value === filtrarPor)[0].label;

  const filtroDebounced = useDebounce(filtro, 500);

  useEffect(() => {
    definirFiltro(filtroDebounced, filtrarPor);
  }, [filtroDebounced, filtrarPor, definirFiltro]);

  return (
    <div className="filtro-form">
      <Input
        className="filtro-form-input"
        value={filtro}
        onChange={setFiltro}
        placeholder={`Filtrar por ${filtrarPorLabel}`}
      />
      <InputPicker
        className="filtro-form-select"
        data={opcoes}
        value={filtrarPor}
        cleanable={false}
        onChange={setFiltrarPor}
      />
    </div>
  );
};

export default FiltroForm;
