import React from 'react';
import { useFormContext, Controller, useWatch } from 'react-hook-form';
import { ControlLabel, FormGroup, Input, RadioGroup, Radio, HelpBlock } from 'rsuite';
import InputArmacao from '../../../components/InputArmacao';
import InputLajota from '../../../components/InputLajota';
import UncontrolledFormInput from '../../../components/UncontrolledFormInput';
import Laje from '../../../types/laje';

const InsercaoLajeForm = () => {
  const form = useFormContext<Laje>();
  const watchTipo = useWatch<string>({ name: 'tipo', control: form.control });

  return (
    <>
      <FormGroup>
        <ControlLabel>Nome</ControlLabel>
        <UncontrolledFormInput errorMessage={form.errors.nome?.message}>
          <Input
            autoFocus
            name="nome"
            placeholder="ex: Laje H7 EPS Tecnocell"
            inputRef={form.register({ required: 'Este campo é necessário' })}
          />
        </UncontrolledFormInput>
      </FormGroup>
      <FormGroup>
        <ControlLabel>Sobrecarga (kg/m²)</ControlLabel>
        <UncontrolledFormInput errorMessage={form.errors.sobrecarga?.message}>
          <Input
            name="sobrecarga"
            placeholder="ex: 500"
            inputRef={form.register({ required: 'Este campo é necessário' })}
          />
        </UncontrolledFormInput>
      </FormGroup>
      <FormGroup>
        <ControlLabel>Tipo</ControlLabel>
        <Controller
          defaultValue={null}
          control={form.control}
          name="tipo"
          rules={{ required: 'Este campo é necessário!' }}
          render={({ onChange, value, name }) => (
            <RadioGroup
              onChange={value => {
                if (watchTipo && watchTipo !== value) {
                  form.setValue('idLajota', null);
                }
                onChange(value);
              }}
              value={value}
              name={name}
              inline
            >
              <Radio value="C">Convencional</Radio>
              <Radio value="T">Treliçada</Radio>
            </RadioGroup>
          )}
        />
        {form.errors.tipo && <HelpBlock style={{ color: 'red' }}>{form.errors.tipo.message}</HelpBlock>}
      </FormGroup>
      <FormGroup>
        <ControlLabel>Forro/Piso</ControlLabel>
        <Controller
          defaultValue={null}
          control={form.control}
          name="forroPiso"
          rules={{ required: 'Este campo é necessário!' }}
          render={({ onChange, value, name }) => (
            <RadioGroup onChange={onChange} value={value} name={name} inline>
              <Radio value="F">Forro</Radio>
              <Radio value="P">Piso</Radio>
            </RadioGroup>
          )}
        />
        {form.errors.forroPiso && <HelpBlock style={{ color: 'red' }}>{form.errors.forroPiso.message}</HelpBlock>}
      </FormGroup>
      <FormGroup>
        <ControlLabel>Nomenclatura</ControlLabel>
        <UncontrolledFormInput errorMessage={form.errors.nomenclatura?.message}>
          <Input
            name="nomenclatura"
            placeholder="ex: B12"
            inputRef={form.register}
          />
        </UncontrolledFormInput>
      </FormGroup>
      {watchTipo && (
        <>
          <FormGroup>
            <ControlLabel>Lajota</ControlLabel>
            <Controller
              name="idLajota"
              defaultValue={null}
              control={form.control}
              rules={{ required: 'Este campo é necessário!' }}
              render={({ value, onChange }) => <InputLajota value={value} onChange={onChange} tipo={watchTipo} />}
            />
            {form.errors.idLajota && <HelpBlock style={{ color: 'red' }}>{form.errors.idLajota.message}</HelpBlock>}
          </FormGroup>
          {watchTipo === "T" && (
            <FormGroup>
              <ControlLabel>Armação</ControlLabel>
              <Controller
                name="idArmacao"
                defaultValue={null}
                control={form.control}
                rules={{ required: 'Este campo é necessário!' }}
                render={({ value, onChange }) => <InputArmacao value={value} onChange={onChange} />}
              />
              {form.errors.idArmacao && <HelpBlock style={{ color: 'red' }}>{form.errors.idArmacao.message}</HelpBlock>}
            </FormGroup>
          )}
        </>
      )}
    </>
  );
};

export default InsercaoLajeForm;
