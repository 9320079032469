import React, { useEffect, useState } from 'react';
import { Col, ControlLabel, Grid, Input, InputGroup, Row } from 'rsuite';

type Props = {
  valorFinal: number,
};

const SimulacaoParcelas = ({ valorFinal }: Props) => {
  const [desconto, setDesconto] = useState('');
  const [taxaJuros, setTaxaJuros] = useState('');
  const [parcelas, setParcelas] = useState<number[]>([]);

  const [valorDescontado, setValorDescontado] = useState(valorFinal);

  useEffect(() => {
    if (isNaN(Number(desconto))) {
      return;
    }

    setValorDescontado(Math.max(valorFinal - Number(desconto), 0));
  }, [valorFinal, desconto]);

  useEffect(() => {
    if (isNaN(Number(taxaJuros))) {
      return;
    }

    const juros = valorDescontado * Number(taxaJuros) / 100;

    const arr = Array.from(Array(12).keys());
    setParcelas([...arr].map(vezes => {
      const valorPagamento = valorDescontado + juros * vezes;
      return Math.max(valorPagamento / (vezes + 1), 0);
    }));

  }, [valorDescontado, taxaJuros]);

  return (
    <>
      <Grid fluid style={{ padding: '0', margin: '8px 0' }}>
        <Row>
          <Col xs={12}>
            <ControlLabel>Desconto</ControlLabel>
            <InputGroup>
              <InputGroup.Addon>R$</InputGroup.Addon>
              <Input
                placeholder="ex: 100"
                value={desconto}
                onChange={setDesconto}
              />
            </InputGroup>
          </Col>
          <Col xs={12}>
            <ControlLabel>Juros</ControlLabel>
            <InputGroup>
              <Input
                placeholder="ex: 1"
                value={taxaJuros}
                onChange={(value) => { if (Number(value) > 100) { setTaxaJuros('100'); } else { setTaxaJuros(value)} } }
              />
              <InputGroup.Addon>%</InputGroup.Addon>
            </InputGroup>
          </Col>
        </Row>
      </Grid>

      <Grid fluid style={{ padding: '0' }}>
        <Row>
          <Col xs={12}>
            <p>à vista</p>
            R$ {(valorDescontado).toFixed(2)}
          </Col>
          <Col xs={12}>
            <p>parcelado</p>
            <table>
              <tbody>
                {parcelas.map((valor, index) => (
                  <tr key={index}>
                    <td>{index + 1}x</td>
                    <td style={{ padding: '0 10px' }}>R$ {(Math.trunc(valor * 100)/100).toFixed(2)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Col>
        </Row>
      </Grid>
    </>
  );
}

export default SimulacaoParcelas;
