import React, { useCallback, useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Alert, Button, Modal } from 'rsuite';
import { AppContext } from '../../../context';

type Props = {
  idProjeto: number,
  idOrcamento: number,
};

const RemocaoOrcamentoButton = ({ idProjeto, idOrcamento }: Props) => {
  const history = useHistory();

  const [showModal, setShowModal] = useState(false);
  const [removendo, setRemovendo] = useState(false);

  const context = useContext(AppContext);
  const repository = context.repositoryFactory.getOrcamentoRepository(context);

  const handleRemover = useCallback(async () => {
    setRemovendo(true);
    const sucesso = await repository.delete(idOrcamento);
    setRemovendo(false);

    if (sucesso) {
      Alert.success('Orçamento removido com sucesso!');
      history.push(`/projetos/${idProjeto}`);
    } else {
      Alert.error('Falha ao remover orçamento!');
    }

  }, [history, repository, idOrcamento, idProjeto]);

  return (
    <>
      <Button
        appearance="subtle"
        color="red"
        loading={removendo}
        onClick={() => setShowModal(true)}
      >Remover</Button>
      <Modal size="xs" className="modal-fluid-xs" backdrop={true} show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header>
          <Modal.Title>Confirmar remoção</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Tem certeza que deseja remover este orçamento?
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={handleRemover}
            loading={removendo}
            appearance="primary"
          >Sim</Button>
          <Button onClick={() => setShowModal(false)} appearance="subtle">
            Não
          </Button>
          </Modal.Footer>
      </Modal>
    </>
  );
};

export default RemocaoOrcamentoButton;
