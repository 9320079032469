import React from 'react'
import { Route, Switch } from 'react-router-dom';
import routes from '../routes';
import NotFoundPage from './NotFoundPage';
import AlteracaoSenhaPage from './senha/AlteracaoSenhaPage';

export const MainRouter = (_props: any) => {
  return (
    <Switch>
      {routes.map(route => (
        <Route key={route.path} path={route.path} exact={route.exact} component={route.component} />
      ))}
      <Route exact path="/senha" component={AlteracaoSenhaPage} />
      <Route key="*" path="*" component={NotFoundPage}/>
    </Switch>
  );
}
