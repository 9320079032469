import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import NotFoundPage from '../NotFoundPage';
import { DespesasPage } from './despesas';
import ProducaoPage from './producao/ProducaoPage';

export const FinanceiroRouter = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={`${path}/despesas`} component={DespesasPage} />
      <Route exact path={`${path}/producao`} component={ProducaoPage} />
      <Route path="*" component={NotFoundPage}/>
    </Switch>
  );
}
