import React, { useContext, useEffect, useState } from 'react';
import { Input, Table } from 'rsuite';
import { AppContext } from '../context';
import MoneyCell from './MoneyCell';
import SelecaoModal from './SelecaoModal';

type Props = {
  value: number | null,
  onChange: (id: number) => void,
};

const InputProduto = ({ value, onChange }: Props) => {
  const [showModal, setShowModal] = useState(false);
  const [nome, setNome] = useState('Selecionar produto...');
  const context = useContext(AppContext);
  const produtoRepository = context.repositoryFactory.getProdutoRepository(context);

  useEffect(() => {
    if (value) {
      setNome('Carregando...');
      produtoRepository.read(value).then(produto => {
        if (produto) {
          setNome(produto.nome);
        }
      });
    } else {
      setNome('Selecionar produto...');
    }
  }, [value, produtoRepository]);

  return (
    <>  
      <Input onClick={() => setShowModal(true)} readOnly value={nome} style={{ cursor: 'pointer' }} />
      <SelecaoModal
        show={showModal}
        buscarParaSelecao={produtoRepository.buscarParaSelecao}
        onClose={selected => {
          if (selected?.id) {
            onChange(selected.id);
          }
          setShowModal(false);
        }}
      >
        <Table.Column>
          <Table.HeaderCell>Nome</Table.HeaderCell>
          <Table.Cell dataKey="nome" />
        </Table.Column>
        <Table.Column>
          <Table.HeaderCell>Valor</Table.HeaderCell>
          <MoneyCell dataKey="valor" />
        </Table.Column>
      </SelecaoModal>
    </>
  );
};

export default InputProduto;
