import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Affix, Button, ButtonToolbar, Icon, Nav, Placeholder } from 'rsuite';
import PageLayout from '../../../components/PageLayout';
import { AppContext } from '../../../context';
import Projeto from '../../../types/projeto';
import { formatPhone } from '../../../utils/formatters';
import AdicaoComodoButton from './AdicaoComodoButton';
import GeracaoOrcamentoButton from './GeracaoOrcamentoButton';
import GerarPDFButton from './GerarPDFButton';
import ListaComodos from './ListaComodos';
import ProjetoToolbar from './ProjetoToolbar';
import RelatorioProjeto from './RelatorioProjeto';

const ProjetoPage = () => {
  const id = Number(useParams<{ id: string }>().id);
  const [carregando, setCarregando] = useState(true);
  const [projeto, setProjeto] = useState<Projeto | null>(null);
  const [nav, setNav] = useState<"comodos" | "relatorio">("comodos");
  const context = useContext(AppContext);
  const [comodosSelecionados, setComodosSelecionados] = useState<Set<number>>(new Set());
  const history = useHistory();

  const refresh = useCallback(() => {
    context.repositoryFactory.getProjetoRepository(context).read(id).then(projeto => {
      if (projeto) {
        setCarregando(false);
        setProjeto(projeto);
      } else {
        setCarregando(false);
        // TODO: seterro
      }
    });
  }, [context, id]);

  useEffect(() => {
    refresh()
  }, [refresh]);

  let inner;
  if (nav === "comodos") {
    inner = (
      <>
        {projeto?.comodos && (
          <>
            <Affix top={30}>
              <ProjetoToolbar
                comodos={projeto.comodos}
                idProjeto={id}
                comodosSelecionados={comodosSelecionados}
                setComodosSelecionados={setComodosSelecionados}
                refresh={refresh}
              />
            </Affix>
            <ListaComodos
              idProjeto={id}
              refresh={refresh}
              comodos={projeto.comodos}
              comodosSelecionados={comodosSelecionados}
              setComodosSelecionados={setComodosSelecionados}
            />
          </>
        )}
      </>
    );
  } else if (nav === "relatorio") {
    inner = <RelatorioProjeto id={id} />;
  }

  let body;
  if (carregando) {
    body = <Placeholder.Paragraph rows={20} active />;
  } else {
    if (projeto) {
      body = (
        <div>
          <div style={{ marginBottom: '15px' }}>
            <p><b>Nome:</b> {projeto.nome}</p>
            <p><b>Cliente:</b> {projeto.nomeCliente}</p>
            {projeto.telefoneCliente && (
              <p><b>Telefone:</b> {formatPhone(projeto.telefoneCliente)}</p>
            )}
          </div>
          <ButtonToolbar style={{ marginBottom: '15px' }}>
            <AdicaoComodoButton idProjeto={id} refresh={refresh} />
            <GeracaoOrcamentoButton idProjeto={id} />
            <Button appearance="subtle" onClick={() => history.push(`/projetos/${id}/orcamentos`)}>
              Ver orçamentos
            </Button>
            <GerarPDFButton idProjeto={id} />
          </ButtonToolbar>
          <Nav appearance="subtle" activeKey={nav} onSelect={setNav}>
            <Nav.Item eventKey="comodos" icon={<Icon icon="web" />}>Cômodos</Nav.Item>
            <Nav.Item eventKey="relatorio" icon={<Icon icon="bar-chart" />}>Relatório</Nav.Item>
          </Nav>
          <div style={{ paddingTop: '20px' }}>
            {inner}
          </div>
        </div>
      );
    } else {
      body = <h5>Um erro inesperado ocorreu!</h5>;
    }
  }

  return (
    <PageLayout title={`Projeto #${id}`}>
      {body}
    </PageLayout>
  );
};

export default ProjetoPage;
