import React, { useContext, useState } from 'react';
import { Button } from 'rsuite';
import AdicaoModal from '../../../../components/AdicaoModal';
import { AppContext } from '../../../../context';
import ComposicaoForm, { ComposicaoFormContext } from './ComposicaoForm';

type Props = {
  idLaje: number,
  refresh(): void,
}

const AdicaoComposicaoButton = ({ idLaje, refresh }: Props) => {
  const [show, setShow] = useState(false);
  const context = useContext(AppContext);
  const repository = context.repositoryFactory.getLajeRepository(context);

  const handleClose = (doRefresh: boolean = false) => {
    setShow(false);
    if (doRefresh) {
      refresh();
    }
  }

  return (
    <>
      <Button appearance="primary" onClick={() => setShow(true)}>Adicionar Composição</Button>
      
      <ComposicaoFormContext.Provider value={{ edicao: false }}>
        <AdicaoModal
          stringNovo="Nova"
          nome="composição"
          show={show}
          onClose={handleClose}
          element={ComposicaoForm}
          create={repository.inserirComposicao}
          formToModel={c => c}
          formOptions={{ defaultValues: { idLaje } }}
        />
      </ComposicaoFormContext.Provider>
    </>
  );
}

export default AdicaoComposicaoButton;
