import { AppContextProps } from "../../context";
import { ClienteRepository } from "../interfaces/cliente-repository";
import { RepositoryFactory } from "../interfaces/repository-factory";
import ApiClienteRepository from "./cliente-repository";
import { AuthRepository } from "../interfaces/auth-repository";
import ApiAuthRepository from "./auth-repository";
import "./axios-config";
import { FerroRepository } from "../interfaces/ferro-repository";
import ApiFerroRepository from "./ferro-repository";
import ApiLajeRepository from "./laje-repository";
import { LajeRepository } from "../interfaces/laje-repository";
import { ProjetoRepository } from "../interfaces/projeto-repository";
import ApiProjetoRepository from "./projeto-repository";
import { ComodoProjetoRepository } from "../interfaces/comodo-projeto-repository";
import ApiComodoProjetoRepository from "./comodo-projeto-repository";
import { AgregadoRepository } from "../interfaces/agregado-repository";
import ApiAgregadoRepository from "./agregado-repository";
import { LajotaRepository } from "../interfaces/lajota-repository";
import ApiLajotaRepository from "./lajota-repository";
import { ArmacaoRepository } from "../interfaces/armacao-repository";
import ApiArmacaoRepository from "./armacao-repository";
import { ProdutoRepository } from "../interfaces/produto-repository";
import ApiProdutoRepository from "./produto-repository";
import { OrcamentoRepository } from "../interfaces/orcamento-repository";
import ApiOrcamentoRepository from "./orcamento-repository";
import { DespesaRepository } from "../interfaces/despesa-repository";
import ApiDespesaRepository from "./despesa-repository";
import { ProducaoRepository } from "../interfaces/producao-repository";
import ApiProducaoRepository from "./producao-repository";
import { UsuarioRepository } from "../interfaces/usuario-repository";
import ApiUsuarioRepository from "./usuario-repository";
import { HomeRepository } from "../interfaces/home-repository";
import ApiHomeRepository from "./home-repository";

export class ApiRepositoryFactory implements RepositoryFactory {
    private authRepository: AuthRepository | null;
    private homeRepository: HomeRepository | null;
    private clienteRepository: ClienteRepository | null;
    private ferroRepository: FerroRepository | null;
    private lajeRepository: LajeRepository | null;
    private projetoRepository: ProjetoRepository | null;
    private comodoProjetoRepository: ComodoProjetoRepository | null;
    private agregadoRepository: AgregadoRepository | null;
    private lajotaRepository: LajotaRepository | null;
    private armacaoRepository: ArmacaoRepository | null;
    private produtoRepository: ProdutoRepository | null;
    private orcamentoRepository: OrcamentoRepository | null;
    private despesaRepository: DespesaRepository | null;
    private producaoRepository: ProducaoRepository | null;
    private usuarioRepository: UsuarioRepository | null;

    constructor() {
        this.authRepository = null;
        this.homeRepository = null;
        this.clienteRepository = null;
        this.ferroRepository = null;
        this.lajeRepository = null;
        this.projetoRepository = null;
        this.comodoProjetoRepository = null;
        this.agregadoRepository = null;
        this.lajotaRepository = null;
        this.armacaoRepository = null;
        this.produtoRepository = null;
        this.orcamentoRepository = null;
        this.despesaRepository = null;
        this.producaoRepository = null;
        this.usuarioRepository = null;
    }

    getAuthRepository(): AuthRepository {
        if (this.authRepository === null) {
            this.authRepository = new ApiAuthRepository();
        }
        return this.authRepository;
    }

    getHomeRepository(_context: AppContextProps): HomeRepository {
        if (this.homeRepository === null) {
            this.homeRepository = new ApiHomeRepository();
        }
        return this.homeRepository;
    }

    getClienteRepository(_context: AppContextProps): ClienteRepository {
        if (this.clienteRepository === null) {
            this.clienteRepository = new ApiClienteRepository();
        }
        return this.clienteRepository;
    }

    getFerroRepository(_context: AppContextProps): FerroRepository {
        if (this.ferroRepository === null) {
            this.ferroRepository = new ApiFerroRepository();
        }
        return this.ferroRepository;
    }

    getLajeRepository(_context: AppContextProps): LajeRepository {
        if (this.lajeRepository === null) {
            this.lajeRepository = new ApiLajeRepository();
        }
        return this.lajeRepository;
    }

    getProjetoRepository(_context: AppContextProps): ProjetoRepository {
        if (this.projetoRepository === null) {
            this.projetoRepository = new ApiProjetoRepository();
        }
        return this.projetoRepository;
    }

    getComodoProjetoRepository(_context: AppContextProps): ComodoProjetoRepository {
        if (this.comodoProjetoRepository === null) {
            this.comodoProjetoRepository = new ApiComodoProjetoRepository();
        }
        return this.comodoProjetoRepository;
    }

    getAgregadoRepository(_context: AppContextProps): AgregadoRepository {
        if (this.agregadoRepository === null) {
            this.agregadoRepository = new ApiAgregadoRepository();
        }
        return this.agregadoRepository;
    }

    getLajotaRepository(_context: AppContextProps): LajotaRepository {
        if (this.lajotaRepository === null) {
            this.lajotaRepository = new ApiLajotaRepository();
        }
        return this.lajotaRepository;
    }

    getArmacaoRepository(_context: AppContextProps): ArmacaoRepository {
        if (this.armacaoRepository === null) {
            this.armacaoRepository = new ApiArmacaoRepository();
        }
        return this.armacaoRepository;
    }

    getProdutoRepository(_context: AppContextProps): ProdutoRepository {
        if (this.produtoRepository === null) {
            this.produtoRepository = new ApiProdutoRepository();
        }
        return this.produtoRepository;
    }

    getOrcamentoRepository(_context: AppContextProps): OrcamentoRepository {
        if (this.orcamentoRepository === null) {
            this.orcamentoRepository = new ApiOrcamentoRepository();
        }
        return this.orcamentoRepository;
    }

    getDespesaRepository(_context: AppContextProps): DespesaRepository {
        if (this.despesaRepository === null) {
            this.despesaRepository = new ApiDespesaRepository();
        }
        return this.despesaRepository;
    }

    getProducaoRepository(_context: AppContextProps): ProducaoRepository {
        if (this.producaoRepository === null) {
            this.producaoRepository = new ApiProducaoRepository();
        }
        return this.producaoRepository;
    }

    getUsuarioRepository(_context: AppContextProps): UsuarioRepository {
        if (this.usuarioRepository === null) {
            this.usuarioRepository = new ApiUsuarioRepository();
        }
        return this.usuarioRepository;
    }
}
