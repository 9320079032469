import React from 'react';
import { FormControl } from 'rsuite';
import InputMask from './InputMask';

const InputTelefone = (props: any) => (
  <FormControl
    {...props}
    accepter={InputMask}
    mask={(input: string) => {
      if (input.replace(/\D/g, '').length <= 10) {
        return [
          '(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/
        ];
      }
      return [
        '(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/
      ];
    }}
  />
);

export default InputTelefone;
