import Axios from "axios";
import Orcamento, { FinalizacaoOrcamento, OrcamentoExibicao, OrcamentoInsercao, OrcamentoProjeto, ParcelaOrcamento } from "../../types/orcamento";
import { Pagina } from "../../types/pagina";
import { ProdutoOrcamento } from "../../types/produto";
import RequisicaoPagina from "../../types/requisicao-pagina";
import { OrcamentoRepository } from "../interfaces/orcamento-repository";

class ApiOrcamentoRepository implements OrcamentoRepository {
  findAll(): Promise<Orcamento[]> {
    throw new Error("Method not implemented.");
  }

  async buscarParaExibicao(requisicao: RequisicaoPagina): Promise<Pagina<OrcamentoExibicao>> {
    const resposta = await Axios.get(
      '/orcamentos/exibicao',
      { params: requisicao },
    );

    if (resposta.status === 200) {
      return resposta.data;
    } else if (resposta.status === 204) {
      return {
        total: 0,
        pagina: [],
      }
    }

    throw new Error(`Falha ao buscar! O servidor respondeu com ${resposta.status}: ${resposta.data.message}`);
  }

  async buscarOrcamentosDoProjeto(idProjeto: number): Promise<OrcamentoProjeto[]> {
    const resposta = await Axios.get('/orcamentos/projetos', { params: { idProjeto } });

    if (resposta.status === 200) {
      return resposta.data;
    } else {
      return [];
    }
  }

  async inserirProdutoNoOrcamento(idOrcamento: number, produto: ProdutoOrcamento): Promise<true | object | string> {
    const resposta = await Axios.post(`/orcamentos/${idOrcamento}/produtos`, produto);

    if (resposta.status === 200) {
      return true;
    } else if (resposta.status === 500) {
      return resposta.data.message;
    } else if (resposta.status === 400) {
      return resposta.data;
    }

    return 'Erro inesperado!';
  }

  async removerProdutoDoOrcamento(idOrcamento: number, idProdutoOrcamento: number): Promise<true | string> {
    const resposta = await Axios.delete(`/orcamentos/${idOrcamento}/produtos/${idProdutoOrcamento}`);

    if (resposta.status === 200) {
      return true;
    } else if (resposta.status === 500) {
      return resposta.data.message;
    } else {
      return 'Erro inesperado!';
    }
  }

  async finalizarOrcamento(idOrcamento: number, finalizacao: FinalizacaoOrcamento): Promise<string | true | object> {
    const resposta = await Axios.post(`/orcamentos/${idOrcamento}/finalizacao`, finalizacao);

    if (resposta.status === 200) {
      return true;
    } else if (resposta.status === 500) {
      return resposta.data.message;
    } else if (resposta.status === 400) {
      return resposta.data;
    }

    return 'Erro inesperado!';
  }

  async atualizarParcela(idOrcamento: number, parcela: ParcelaOrcamento): Promise<boolean> {
    const resposta = await Axios.put(`/orcamentos/${idOrcamento}/parcelas`, parcela);

    return resposta.status === 200;
  }

  async create(orcamento: OrcamentoInsercao): Promise<string | number | object> {
    const resposta = await Axios.post('/orcamentos', orcamento);

    if (resposta.status === 200) {
      return resposta.data.idOrcamento;
    } else if (resposta.status === 500) {
      return resposta.data.message;
    } else if (resposta.status === 400) {
      return resposta.data;
    }

    return 'Erro inesperado!';
  }

  async read(id: number): Promise<Orcamento | null> {
    const resposta = await Axios.get<Orcamento>(`/orcamentos/${id}`);

    if (resposta.status === 200) {
      return resposta.data;
    }

    return null;
  }

  async update(orcamento: OrcamentoInsercao): Promise<string | true | object> {
    const resposta = await Axios.put('/orcamentos', orcamento);

    if (resposta.status === 200) {
      return true;
    } else if (resposta.status === 500) {
      return resposta.data.message;
    } else if (resposta.status === 400) {
      return resposta.data;
    }

    return 'Um erro inesperado ocorreu!';
  }
  
  async delete(id: number): Promise<boolean> {
    const resposta = await Axios.delete('/orcamentos', {
      params: { ids: id }
    });

    return resposta.status === 200;
  }

  async deleteMany(ids: number[]): Promise<boolean> {
    const resposta = await Axios.delete('/orcamentos', {
      params: { ids: ids.join(',') }
    });

    return resposta.status === 200;
  }

  async gerarPDF(id: number, vendedor?: string, desconto?: number, frete?: number): Promise<BlobPart> {
    const resposta = await Axios.post(`/orcamentos/${id}/pdf`,
      { desconto, vendedor, frete }, { responseType: 'blob' });
    
    if (resposta.status === 200) {
      return resposta.data;
    } else if (resposta.status === 400 || resposta.status === 500) {
      const text = await resposta.data.text();
      const json = JSON.parse(text);
      throw new Error(json.message);
    }

    throw new Error('Um erro inesperado ocorreu!');
  }

}

export default ApiOrcamentoRepository;
