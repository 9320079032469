type Usuario = {
  username: string,
  nome: string,
  role: string,
  senha?: string,
};

export type UsuarioForm = Usuario & {
  confirmarSenha?: string,
}

export const usuarioFormToModel = (form: UsuarioForm): Usuario => {
  return {
    username: form.username,
    nome: form.nome,
    role: form.role,
    senha: form.senha && form.senha.length > 0 ? form.senha : undefined,
  }
}

export default Usuario;
