import Cliente, { ClienteExibicao, ClienteSelecao } from "../../types/cliente";
import { Pagina } from "../../types/pagina";
import RequisicaoPagina from "../../types/requisicao-pagina";
import { ClienteRepository } from "../interfaces/cliente-repository";

export class FakeApiClienteRepository implements ClienteRepository {
    async findAll(): Promise<Array<Cliente>> {
        await new Promise(r => setTimeout(r, 500));
        return Promise.resolve([
            {
                id: 123,
                tipo: 'empresa',
                empresa: {
                    nome: 'Aegis Soluções Digitais LTDA Inova Simples',
                    endereco: {
                        cep: '86061280',
                    }
                }
            },
            {
                id: 124,
                tipo: 'pessoa',
                pessoa: {
                    nome: 'Artlajes lajes treliçadas e convencionais',
                    endereco: {
                        cep: '86061280',
                    }
                }
            }
        ]);
    }

    async buscarParaExibicao(requisicao: RequisicaoPagina): Promise<Pagina<ClienteExibicao>> {
        await new Promise(r => setTimeout(r, 500));

        const clientes: Array<ClienteExibicao> = [];

        clientes.push({
            id: 123,
            tipo: 'empresa',
            nome: 'Aegis Soluções Digitais LTDA Inova Simples',
            numProjetos: 7,
            cidade: 'Londrina',
        });

        if (requisicao.qtdItens > 1) {
            clientes.push({
                id: 124,
                tipo: 'pessoa',
                nome: 'Artlajes lajes treliçadas e convencionais',
                numProjetos: 2,
            });
        }

        return Promise.resolve({
            total: 2,
            pagina: clientes,
        });
    }

    buscarParaSelecao(): Promise<Pagina<ClienteSelecao>> {
        throw new Error("Method not implemented.");
    }

    create(_cliente: Cliente): Promise<true> {
        return Promise.resolve(true);
    }

    read(id: number): Promise<Cliente> {
        return Promise.resolve({
            id: id,
            tipo: 'empresa',
            empresa: {
                nome: 'Nome1',
                endereco: { cep: '8' }
            }
        });
    }

    update(_cliente: Cliente): Promise<true> {
        return Promise.resolve(true);
    }

    delete(_id: number): Promise<boolean> {
        return Promise.resolve(true);
    }

    deleteMany(ids: number[]): Promise<boolean> {
        throw new Error("Method not implemented.");
    }
}
