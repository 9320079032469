import { FerroComposicao } from "./ferro";
import Laje from "./laje";

type ComodoProjeto = {
  idProjeto: number,
  id?: number,
  nome: string,
  laje?: Laje,
  idLaje?: number,
  nomeLaje?: string,
  tipoLaje?: string,
  nomeLajota?: string,
  nomeArmacao?: string,
  sobrecargaLaje?: number,
  forroPisoLaje?: string,
  vao: number,
  comprimento: number,
  apoio?: number,
  ferros?: Array<FerroComposicao>,
  ferrosPrincipais?: Array<FerroComposicao>,
};

export const comodoModelToForm = (comodo: ComodoProjeto): ComodoProjeto => {
  return {
    ...comodo,
    idLaje: comodo.laje?.id,
  };
}

export type RelatorioComodoProjeto = {
  nomeComodo: string,
  vaoComodo: number,
  comprimentoComodo: number,
  apoioComodo?: number,

  nomeLaje: string,
  tipoLaje: "T" | "C",
  sobrecargaLaje: number,
  forroPisoLaje: string,

  nomeArmacao?: string,

  nomeLajota: string,

  ferrosPrincipais: FerroComposicao[],
  ferrosAuxiliares?: FerroComposicao[],

  numVigotas: number,
  metrosQuadrados: number,
  valorReferencia: number,
  valorReferenciaMetroQuadrado: number,

  lajotasInteira: number,
  lajotasChanfrada: number,
  lajotasMetade: number,
  restoVao: number,
  lajotasAoLongoDoVao: number,
  restoComprimento: number,

};

export default ComodoProjeto;
