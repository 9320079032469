import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { ControlLabel, DatePicker, Divider, FormControl, FormGroup, HelpBlock} from 'rsuite';
import { ClienteForm } from '../../types/cliente';
import EnderecoForm from '../../components/EnderecoForm';
import InputMask from '../../components/InputMask';

const InsercaoPessoaForm = () => {
  const form = useFormContext<ClienteForm>();
  return (
    <>
      <FormGroup>
        <ControlLabel>Nome</ControlLabel>
        <FormControl
          autoFocus
          name="pessoa.nome"
          placeholder="ex: João da Silva"
          inputRef={form.register({ required: 'Este campo é necessário' })}
          errorMessage={form.errors.pessoa?.nome && form.errors.pessoa?.nome?.message}
          readOnly={form.formState.isSubmitting}
        />
        <HelpBlock>Campo obrigatório</HelpBlock>
      </FormGroup>
      <FormGroup>
        <ControlLabel>Data de Nascimento</ControlLabel>
        <Controller
          name="pessoa.dtNascimento"
          control={form.control}
          defaultValue={null}
          render={({ name, onChange, value }) => (
            <FormControl
              name={name}
              accepter={DatePicker}
              oneTap
              format="DD/MM/YYYY"
              placeholder="dd/mm/aaaa"
              onChange={onChange}
              value={value}
            />
          )}
        />
      </FormGroup>
      <FormGroup>
        <ControlLabel>RG</ControlLabel>
        <FormControl
          name="pessoa.rg"
          placeholder="ex: 00.000.000-0"
          inputRef={form.register({
            maxLength: { value: 9, message: 'Número máximo de dígitos: 9'},
            pattern: { value: /^\d*$/, message: 'Digite apenas números' },
          })}
          errorMessage={form.errors?.pessoa?.rg && form.errors?.pessoa?.rg.message}
        />
      </FormGroup>
      <FormGroup>
        <ControlLabel>CPF</ControlLabel>
        <Controller
          name="pessoa.cpf"
          control={form.control}
          defaultValue=""
          render={({ name, value, onChange, onBlur }) => (
            <FormControl
              name={name}
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              accepter={InputMask}
              mask={[
                /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/
              ]}
              placeholder="ex: 000.000.000-00"
              errorMessage={form.errors.pessoa?.cpf && form.errors.pessoa.cpf.message}
            />
          )}
        />
      </FormGroup>
      <Divider>Informações de contato</Divider>
      <FormGroup>
        <ControlLabel>Número de telefone</ControlLabel>
        <Controller
          name="pessoa.telefone"
          control={form.control}
          defaultValue=""
          render={({ name, value, onChange, onBlur }) => (
            <FormControl
              name={name}
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              accepter={InputMask}
              mask={(input: string) => {
                if (input.replace(/\D/g, '').length <= 10) {
                  return [
                    '(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/
                  ];
                }
                return [
                  '(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/
                ];
              }}
              placeholder="ex: (99) 99999-9999"
            />
          )}
        />
      </FormGroup>
      <FormGroup>
        <ControlLabel>E-mail</ControlLabel>
        <FormControl
          name="pessoa.email"
          placeholder="ex: joao.silva@email.com"
          inputRef={form.register}
        />
      </FormGroup>
      <Divider>Endereço</Divider>
      <EnderecoForm form={form} obrigatorio={false} prefixo="pessoa.endereco." errors={form.errors.pessoa?.endereco} />
    </>
  );
};

export default InsercaoPessoaForm;
