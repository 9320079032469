import React, { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Alert, Button, Form } from 'rsuite';
import PageLayout from '../../components/PageLayout';
import { AppContext } from '../../context';
import { FormSenha } from '../usuarios/FormUsuario';

type Form = {
  senha: string,
  confirmarSenha: string,
};

const AlteracaoSenhaPage = () => {
  const form = useForm<Form>();
  const [submitting, setSubmitting] = useState(false);

  const context = useContext(AppContext);
  const repository = context.repositoryFactory.getAuthRepository();

  const onSubmit = async (values: Form) => {
    setSubmitting(true);
    const resposta = await repository.updatePassword(values.senha);
    if (typeof resposta === 'string') {
      Alert.error(resposta);
    } else if (typeof resposta === 'object') {
      Alert.error('Erro inesperado!');
    } else {
      Alert.success('Senha atualizada!');
    }
    setSubmitting(false);
  };

  return (
    <PageLayout title="Alterar senha">
      <Form onSubmit={(_c, v) => form.handleSubmit(onSubmit)(v)}>
        <FormSenha required form={form} passwordError={form.errors.senha} confirmPasswordError={form.errors.confirmarSenha} />
        <Button loading={submitting} appearance="primary" type="submit">Alterar</Button>
      </Form>
    </PageLayout>
  );
};

export default AlteracaoSenhaPage;
