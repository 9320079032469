import React, { useState } from 'react';
import { Alert, Button, Modal } from 'rsuite';
import { DeleteManyFunction } from '../types/crud-functions';

type DeleteManyWithoutParam = {
  deleteMany: DeleteManyFunction,
  param?: undefined,
}

type DeleteManyWithParam = {
  deleteMany: (ids: number[], param: number) => Promise<boolean>,
  param: number,
};

type DeleteManyOptionalParam =
  DeleteManyWithoutParam
  | DeleteManyWithParam;

type BaseProps = {
  show: boolean,
  selecionados: Array<number>,
  onClose(doRefresh: boolean): void,
  nome: [string, string],
};

type Props = BaseProps & DeleteManyOptionalParam;

function isWithParam(teste: Props): teste is BaseProps & DeleteManyWithParam {
  return (typeof teste.param === 'number');
}

const RemocaoModal = (props: Props) => {
  const { show, selecionados, onClose, nome } = props;
  const [deleting, setDeleting] = useState(false);
  const nomeItens = selecionados.length === 1 ? nome[0] : nome[1];

  const handleDelete = async () => {
    setDeleting(true);

    const resposta = await (isWithParam(props) ? props.deleteMany(selecionados, props.param) : props.deleteMany(selecionados));

    if (resposta) {
      Alert.success('Remoção realizada!');
      onClose(true);
    } else {
      Alert.error('Falha ao remover!');
      onClose(false);
    }

    setDeleting(false);
  };

  return (
    <Modal size="xs" className="modal-fluid-xs" backdrop={true} show={show} onHide={() => onClose(false)}>
      <Modal.Header>
        <Modal.Title>Remoção de {nome[1]} </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Deseja confirmar a exclusão de {selecionados.length} {nomeItens}?
      </Modal.Body>
      <Modal.Footer>
        <Button loading={deleting} onClick={handleDelete} appearance="primary">
          Sim
        </Button>
        <Button onClick={() => onClose(false)} appearance="subtle">
          Não
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default RemocaoModal;
