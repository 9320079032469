import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Message, Placeholder } from 'rsuite';
import PageLayout from '../components/PageLayout';
import { AppContext } from '../context';
import InformacoesHome from '../types/informacoes-home';
import { Service } from '../types/service';


type CardProps = {
  link: string,
  titulo: string,
  id: number,
  nomeCliente: string,
  dataCadastro: string,
};

const Card = ({ link, titulo, id, nomeCliente, dataCadastro }: CardProps) => {
  const data = new Date(dataCadastro);
  const hoje = new Date();
  const ontem = new Date();
  ontem.setDate(hoje.getDate() - 1);
  let dataDisplay;
  if (data.toDateString() === hoje.toDateString()) {
    dataDisplay = 'Hoje';
  } else if (data.toDateString() === ontem.toDateString()) {
    dataDisplay = 'Ontem';
  } else {
    const diffTime = Math.abs(hoje as any - (data as any));
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))
    dataDisplay = `${diffDays} dias atrás`;
  }

  return (
    <Link to={`/${link}/${id}`} className="orcamento-projeto">
      <span className="titulo-orcamento-projeto">{titulo} #{id}</span>
      <span className="campo-orcamento-projeto">{nomeCliente}</span>
      <span className="data-orcamento-projeto">{dataDisplay}</span>
    </Link>
  );
}

export const HomePage = () => {
  const context = useContext(AppContext);
  const repository = context.repositoryFactory.getHomeRepository(context);
  const [service, setService] = useState<Service<InformacoesHome>>({ status: 'loading' });

  useEffect(() => {
    repository.buscarInformacoes().then((resposta) => {
      if (resposta) {
        setService({ status: 'loaded', payload: resposta  });
      } else {
        setService({ status: 'error', error: new Error('Erro inesperado') });
      }
    });
  }, [repository]);

  let body;
  if (service.status === 'loading') {
    body = <Placeholder.Paragraph rows={20} active />;
  } else if (service.status === 'error') {
    body = (
      <Message
        showIcon
        type="error"
        title="Erro"
        description={service.error.message}
      />
    );
  } else if (service.status === 'loaded') {
    const informacoes = service.payload;

    body = (
      <>
        <div className="home-estatisticas">
          <div className="home-estatisticas-parte">
            <p className="home-estatisticas-titulo">
              Vendas
            </p>
            <div className="home-estatisticas-valores">
              <aside className="home-estatistica">
                <p className="home-estatistica-valor">
                  R$ {informacoes.dadosVendas.valorUltimaSemana.toFixed(2)}
                </p>
                <p className="home-estatistica-legenda">
                  na última semana
                </p>
              </aside>
              <aside className="home-estatistica">
                <p className="home-estatistica-valor">
                  R$ {informacoes.dadosVendas.valorUltimoMes.toFixed(2)}
                </p>
                <p className="home-estatistica-legenda">
                  no último mês
                </p>
              </aside>
            </div>
          </div>

          <div className="home-estatisticas-parte">
            <p className="home-estatisticas-titulo">
              Projetos
            </p>
            <div className="home-estatisticas-valores">
              <aside className="home-estatistica">
                <p className="home-estatistica-valor">
                  {informacoes.dadosProjetos.projetosUltimaSemana}
                </p>
                <p className="home-estatistica-legenda">
                  na última semana
                </p>
              </aside>
              <aside className="home-estatistica">
                <p className="home-estatistica-valor">
                  {informacoes.dadosProjetos.projetosUltimoMes}
                </p>
                <p className="home-estatistica-legenda">
                  no último mês
                </p>
              </aside>
            </div>
          </div>

          <div className="home-estatisticas-parte">
            <p className="home-estatisticas-titulo">
              Orçamentos
            </p>
            <div className="home-estatisticas-valores">
              <aside className="home-estatistica">
                <p className="home-estatistica-valor">
                  {informacoes.dadosOrcamentos.orcamentosUltimaSemana}
                </p>
                <p className="home-estatistica-legenda">
                  na última semana
                </p>
              </aside>
              <aside className="home-estatistica">
                <p className="home-estatistica-valor">
                  {informacoes.dadosOrcamentos.orcamentosUltimoMes}
                </p>
                <p className="home-estatistica-legenda">
                  no último mês
                </p>
              </aside>
            </div>
          </div>
        </div>

        <p>Últimos projetos</p>
        <div className="orcamentos-projeto" style={{ marginTop: '8px' }}>
          {informacoes.projetos.map(projeto => (
            <Card key={projeto.id} titulo="Projeto" link="projetos" {...projeto} />
          ))}
        </div>

        <br/>

        <p>Últimos orçamentos</p>
        <div className="orcamentos-projeto" style={{ marginTop: '8px' }}>
          {informacoes.orcamentos.map(orcamento => (
            <Card key={orcamento.id} titulo="Orçamento" link="orcamentos" {...orcamento} />
          ))}
        </div>
      </>
    );
  }

  return (
    <PageLayout title="Dashboard">
      {body}  
    </PageLayout>
  );
}
