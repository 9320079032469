import React, { useCallback, useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Dropdown, Icon, Nav, Navbar, Sidebar, Sidenav } from 'rsuite';
import { AppContext } from '../context';
import routes from '../routes';

function getPathByUrl(pathname: string): [string, string | null] {
  for (const route of routes) {
    if ((route.exact && route.path === pathname) || (!route.exact && pathname.startsWith(route.path))) {
      if (route.subroutes) {
        for (const subroute of route.subroutes) {
          if ((subroute.exact && subroute.path === pathname) || (!subroute.exact && pathname.startsWith(subroute.path))) {
            return [route.path, subroute.path];
          }
        }
      }
      return [route.path, null];
    }
  }
  return [pathname, null];
}

const AppSidebar = ({ closed, setSidebarClosed }: { closed: boolean, setSidebarClosed: (closed: boolean) => void }) => {
  const context = useContext(AppContext);
  const location = useLocation();
  
  const [activeRoute, activeSubroute] = getPathByUrl(location.pathname);

  const logout = useCallback(() => {
    context.repositoryFactory.getAuthRepository().logout();
    context.setAuthState({
      loading: false,
      authenticated: false,
      usuario: null,
    });
  }, [context]);

  return (
    <>
      <div
        className={`app-sidebar-backdrop ${closed ? 'app-sidebar-backdrop--closed' : ''}`}
        onClick={() => setSidebarClosed(true)}
      />
      <Sidebar 
        className={`app-sidebar ${closed ? 'app-sidebar--closed' : ''}`}
      >
        <Sidenav.Header>
          <div
            className="app-sidebar-header"
          >
            <aside>
              <Icon icon="buysellads" size="lg" style={{ verticalAlign: 0 }} />
              <span style={{ marginLeft: 16 }}> ArtLajes</span>
            </aside>
            <aside>
              <Icon
                icon="close"
                size="lg"
                className="app-sidebar-close-button"
                onClick={() => setSidebarClosed(true)}
              />            
            </aside>
          </div>
        </Sidenav.Header>
        <Sidenav
          defaultOpenKeys={[activeRoute]}
          appearance="subtle"
          style={{ flex: '1 1 auto', overflowY: 'auto' }}
          onSelect={() => { if (window.innerWidth < 768) { setSidebarClosed(true); } }}
        >
          <Sidenav.Body>
            <Nav>
              {routes.map(route => {
                if (!context.authState.usuario || (route.onlyToRoles && !route.onlyToRoles.includes(context.authState.usuario.role))) {
                  return null;
                }

                if (!route.subroutes) {
                  return (
                    <Nav.Item
                      key={route.path}
                      eventKey={route.path}
                      componentClass={Link}
                      to={route.path}
                      active={activeRoute === route.path}
                      icon={<Icon icon={route.icon} />}
                    >
                      {route.name}
                    </Nav.Item>
                  );
                } else {
                  return (
                    <Dropdown
                      key={route.path}
                      eventKey={route.path}
                      title={route.name}
                      icon={<Icon icon={route.icon} />}
                      placement="rightStart"
                    >
                      {route.subroutes.map(subroute => (
                        <Dropdown.Item
                          key={subroute.path}
                          eventKey={subroute.path}
                          componentClass={Link}
                          to={subroute.path}
                          active={activeSubroute === subroute.path}
                        >
                          {subroute.name}
                        </Dropdown.Item>
                      ))}
                    </Dropdown>
                  );
                }
              })}
            </Nav>
          </Sidenav.Body>
        </Sidenav>
        <NavMenu
          nomeUsuario={context.authState.usuario?.nome}
          logout={logout}
          setSidebarClosed={setSidebarClosed}
        />
      </Sidebar>
    </>
  );
}

type NavMenuProps = {
  nomeUsuario?: string,
  logout: () => void,
  setSidebarClosed: (closed: boolean) => void,
};
  
const NavMenu = ({ nomeUsuario, logout, setSidebarClosed }: NavMenuProps) => (
  <Navbar appearance="subtle" style={{ borderTop: '1px solid #e5e5ea' }}>
    <Navbar.Body style={{ display: 'flex', justifyContent: 'space-between' }}>
      <span style={{ padding: '16px' }}>{nomeUsuario}</span>
      <Nav>
        <Dropdown
          placement="topStart"
          trigger="click"
          renderTitle={() => {
            return <Icon style={{ width: 56, height: 56, lineHeight: '56px', textAlign: 'center' }} icon="cog" />;
          }}
        >
          <Dropdown.Item onSelect={() => { if (window.innerWidth < 768) { setSidebarClosed(true); } }} componentClass={Link} to="/senha">Alterar senha</Dropdown.Item>
          <Dropdown.Item onSelect={logout}>Sair</Dropdown.Item>
        </Dropdown>
      </Nav>
    </Navbar.Body>
  </Navbar>
);


export default AppSidebar;
