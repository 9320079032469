type Produto = {
  id: number,
  nome: string,
  descricao: string,
  valor: string,
};

export type ProdutoOrcamentoForm = {
  idProduto: number,
  quantidade: string,
};

export type ProdutoOrcamento = {
  idProduto: number,
  quantidade: number,
};

export const produtoOrcamentoFormToModel = (form: ProdutoOrcamentoForm): ProdutoOrcamento => {
  return {
    ...form,
    quantidade: Number(form.quantidade),
  };
}

export default Produto;
