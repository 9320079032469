import React, { useEffect, useState } from 'react';
import { Alert, Button, Input, Modal, Table } from 'rsuite';
import { Pagina } from '../types/pagina';
import useDebounce from '../utils/useDebounce';

type Props<T, U> = {
  show: boolean,
  onClose: (selected?: T) => void,
  buscarParaSelecao: (busca: string, pagina: number, param?: U) => Promise<Pagina<T>>,
  param?: U,
};

function SelecaoModal<T, U>(props: React.PropsWithChildren<Props<T, U>>) {
  const { children, show, onClose, buscarParaSelecao, param } = props;
  const [busca, setBusca] = useState('');
  const [total, setTotal] = useState(0);
  const [dados, setDados] = useState<Array<T>>([]);
  const [carregando, setCarregando] = useState(false);
  const [pagina, setPagina] = useState(1);
  const buscaDebounced = useDebounce(busca, 500);

  useEffect(() => {
    setPagina(1);
  }, [buscaDebounced, param]);

  useEffect(() => {
    setCarregando(true);
    buscarParaSelecao(buscaDebounced, pagina, param).then(resposta => {
      setTotal(resposta.total);
      setDados(resposta.pagina);
      setCarregando(false);
    }).catch((e) => {
      Alert.error(e.message);
      setCarregando(false);
    });
  }, [buscaDebounced, buscarParaSelecao, pagina, setDados, setCarregando, param]);

  return (
    <Modal overflow={false} size="sm" show={show} backdrop={true} onHide={() => onClose()} className="modal-fluid-sm">
      <Modal.Header>
        <Modal.Title>Seleção</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Input autoFocus placeholder="Busca" value={busca} onChange={setBusca} />
        <Table
          data={dados}
          loading={carregando}
          height={300}
          onRowClick={onClose}
          className="linha-clicavel"
          virtualized={true}
        >
          {children}
        </Table>
        <Table.Pagination
          activePage={pagina}
          displayLength={15}
          showLengthMenu={false}
          onChangePage={setPagina}
          total={total}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button appearance="subtle" onClick={() => onClose()}>Cancelar</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default SelecaoModal;
