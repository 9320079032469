import React, { useContext, useState } from 'react';
import { Button } from 'rsuite';
import AdicaoModal from '../../../components/AdicaoModal';
import { AppContext } from '../../../context';
import { FinalizacaoOrcamento, finalizacaoOrcamentoFormToModel } from '../../../types/orcamento';
import FinalizacaoForm from './FinalizacaoForm';

type Props = {
  idOrcamento: number,
  refresh(): void,
}

const FinalizacaoButton = ({ idOrcamento, refresh }: Props) => {
  const [showModal, setShowModal] = useState(false);
  const context = useContext(AppContext);
  const repository = context.repositoryFactory.getOrcamentoRepository(context);

  return (
    <>
      <Button onClick={() => setShowModal(true)} appearance="primary">Realizar venda</Button>
      <AdicaoModal
        stringNovo="Finalizar"
        nome="orçamento"
        mensagemSucesso="Finalizado com sucesso!"
        show={showModal}
        onClose={(doRefresh = false) => { setShowModal(false); if (doRefresh) refresh() }}
        create={(finalizacao: FinalizacaoOrcamento) => repository.finalizarOrcamento(idOrcamento, finalizacao)}
        formToModel={finalizacaoOrcamentoFormToModel}
        element={FinalizacaoForm}
      />
    </>
  );
};

export default FinalizacaoButton;
