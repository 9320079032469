import Usuario from "../../types/usuario";
import { AuthRepository } from "../interfaces/auth-repository";

export class FakeApiAuthRepository implements AuthRepository {
  updatePassword(password: string, username?: string): Promise<string | true> {
    throw new Error("Method not implemented.");
  }
  async isAuthenticated(): Promise<Usuario | false> {
    await new Promise(r => setTimeout(r, 300));
    return {
      username: 'vanderlei',
      nome: 'Vanderlei',
      role: 'A',
    };
  }

  async authenticate(username: string, _password: string): Promise<Usuario | false> {
    await new Promise(r => setTimeout(r, 300));
    return {
      username: 'vanderlei',
      nome: 'Vanderlei',
      role: 'A',
    };
  }

  logout(): Promise<void> {
    return Promise.resolve();
  }
};
