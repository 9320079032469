import React, { ReactNode, useContext } from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { AppContext } from '../context';

type ProtectedRouteProps = {
  children?: ReactNode
} & RouteProps;

const ProtectedRoute = ({ children, ...props }: ProtectedRouteProps) => {
  const context = useContext(AppContext);
  
  if (context.authState.authenticated) {
    return (
      <Route {...props}>{children}</Route>
    );
  } else {
    return (<Redirect to="/login" />);
  }
}

export default ProtectedRoute;
