import React, { useContext, useRef, useState } from 'react';
import { Table } from 'rsuite';
import AdicaoModal from '../../../components/AdicaoModal';
import BaseTabela from '../../../components/BaseTabela';
import { AdicaoModalFunction, BaseTabelaProps, RemocaoModalFunction } from '../../../components/BaseTabela/BaseTabela';
import EdicaoDrawer from '../../../components/EdicaoDrawer';
import MoneyCell from '../../../components/MoneyCell';
import NumberCell from '../../../components/NumberCell';
import PageLayout from '../../../components/PageLayout';
import RemocaoModal from '../../../components/RemocaoModal';
import { AppContext, AppContextProps } from '../../../context';
import { LajotaRepository } from '../../../repositories/interfaces/lajota-repository';
import { LajotaExibicao } from '../../../types/lajota';
import { Pagina } from '../../../types/pagina';
import RequisicaoPagina from '../../../types/requisicao-pagina';
import EdicaoLajotaForm from './EdicaoLajotaForm';
import InsercaoLajotaForm from './InsercaoLajotaForm';

class TabelaLajotas extends BaseTabela<LajotaExibicao> {
  private lajotaRepository: LajotaRepository;

  constructor(props: BaseTabelaProps, context: AppContextProps) {
    super(props);
    this.lajotaRepository = context.repositoryFactory.getLajotaRepository(context);
  }

  buscarParaExibicao(requisicao: RequisicaoPagina) : Promise<Pagina<LajotaExibicao>> {
    return this.lajotaRepository.buscarParaExibicao(requisicao);
  }

  exibirAdicaoModal: AdicaoModalFunction = (show, onClose) => (
    <AdicaoModal
      stringNovo="Nova"
      nome="lajota"
      show={show}
      onClose={onClose}
      element={InsercaoLajotaForm}
      create={this.lajotaRepository.create}
      formToModel={f => f}
    />
  );

  exibirRemocaoModal: RemocaoModalFunction = (show, selecionados, onClose) => (
    <RemocaoModal
      show={show}
      selecionados={selecionados}
      onClose={onClose}
      deleteMany={this.lajotaRepository.deleteMany}
      nome={["lajota", "lajotas"]}
    />
  );
}

const LajotasPage = () => {
  const [idEdicao, setIdEdicao] = useState<number | undefined>(undefined);
  const [editando, setEditando] = useState(false);

  const context = useContext(AppContext);
  const repository = context.repositoryFactory.getLajotaRepository(context);

  const ref = useRef<TabelaLajotas>(null);

  return (
    <PageLayout title="Materiais - Lajotas">
      <TabelaLajotas
        ref={ref}
        ordenarPorPadrao="nome"
        tipoOrdenacaoPadrao="asc"
        opcoesFiltro={[{ value: 'nome', label: 'Nome' }, { value: 'tipo', label: 'Tipo' }]}
        handleEdit={id => { setIdEdicao(id); setEditando(true); }}
      >
        <Table.Column flexGrow={1} minWidth={150} sortable>
          <Table.HeaderCell>Nome</Table.HeaderCell>
          <Table.Cell dataKey="nome" />
        </Table.Column>
        <Table.Column>
          <Table.HeaderCell>Tipo</Table.HeaderCell>
          <Table.Cell dataKey="tipo" />
        </Table.Column>
        <Table.Column>
          <Table.HeaderCell>Tamanho</Table.HeaderCell>
          <NumberCell dataKey="tamanho" />
        </Table.Column>
        <Table.Column>
          <Table.HeaderCell>Altura</Table.HeaderCell>
          <NumberCell dataKey="altura" />
        </Table.Column>
        <Table.Column sortable>
          <Table.HeaderCell>Intereixo</Table.HeaderCell>
          <NumberCell dataKey="intereixo" />
        </Table.Column>
        <Table.Column sortable>
          <Table.HeaderCell>Valor</Table.HeaderCell>
          <MoneyCell dataKey="valor" />
        </Table.Column>
      </TabelaLajotas>

      <EdicaoDrawer
        show={editando}
        id={idEdicao}
        onClose={(refresh: boolean = false) => {
          setEditando(false);
          setIdEdicao(undefined);
          if (refresh) {
            ref.current?.refresh();
          }
        }}
        update={repository.update}
        read={repository.read}
        delete={repository.deleteMany}
        formToModel={a => a}
        modelToForm={a => a}
        formElement={EdicaoLajotaForm}
      />
    </PageLayout>
  );
}

export default LajotasPage;
